import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';

import EnTranslation from './en.json';

const resources = {
  en: {
    translation: EnTranslation
  }
};

const i18nCommonUI = createInstance({
  fallbackLng: 'en',
  debug: true,

  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  resources
});

i18nCommonUI.use(initReactI18next).init();

export default i18nCommonUI;
