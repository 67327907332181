import { Box } from '@mui/material';
import React from 'react';
import { LocationsEntity } from '@oh-vcp/components-common';
import SearchResultLocationItem from '../searchResultLocationItem/SearchResultLocationItem';

interface SearchResultLocationListProps {
  locations: LocationsEntity[];
}

const SearchResultLocationList: React.FC<SearchResultLocationListProps> = ({ locations }) => {
  return (
    <Box sx={{ pt: 2 }}>
      {locations.map((location) => (
        <SearchResultLocationItem key={location.address} location={location} />
      ))}
    </Box>
  );
};

export default SearchResultLocationList;
