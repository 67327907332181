import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { CancelButton } from '@oh-vcp/components-ui';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface EconsultDeleteDraftDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const EconsultDeleteDraftDialog: FC<EconsultDeleteDraftDialogProps> = ({
  isOpen,
  onCancel,
  onConfirm
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Typography variant="h2" color="base.grey3">
          {t('Case.consult.deleteDraftCase')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="base.grey3">
          {t('Case.consult.areYouSureYouWantToDeleteTheDraftCase')}
        </Typography>
        <Stack direction="row" mt={2} justifyContent="end">
          <Button variant="contained" color="primary" sx={{ mr: 1 }} onClick={onConfirm}>
            {t('Case.button.delete')}
          </Button>
          <CancelButton variant="contained" onClick={onCancel}>
            {t('Case.button.cancel')}
          </CancelButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default EconsultDeleteDraftDialog;
