import React, { FC, SyntheticEvent, useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { CaseConsultOptions, ConsultTab, NoteActionTypes } from '@oh-vcp/components-common';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';
import {
  AskMoreInfoActiveIcon,
  AskMoreInfoInactiveIcon,
  CancelActiveIcon,
  CancelInactiveIcon,
  CloseCaseActiveIcon,
  CloseCaseInactiveIcon,
  DeclineActiveIcon,
  DeclineInactiveIcon,
  ReassignActiveIcon,
  ReassignInactiveIcon,
  WriteConsultActiveIcon,
  WriteConsultInactiveIcon
} from '@oh-vcp/components-ui';
import EconsultCaseProvideConsult from '../econsultCaseProvideConsult/EconsultCaseProvideConsult';
import EconsultCaseReturnConsult from '../econsultCaseReturnConsult/EconsultCaseReturnConsult';
import EconsultCaseComplete from '../econsultCaseComplete/EconsultCaseComplete';
import EconsultCaseRedirect from '../econsultCaseRedirect/EconsultCaseRedirect';

interface EconsultCaseConsultActionDetailsProps {
  options: CaseConsultOptions;
  selectedTab: ConsultTab | undefined;
  onTabSelected?: (tab: ConsultTab) => void;
}

const EconsultCaseConsultActionTabPanel = styled(TabPanel)({
  padding: 0,
  paddingTop: 10
});
const EconsultActionTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&.Mui-selected': {
    color: theme.palette.primary.custom3
  }
}));
const EconsultActionTabList = styled(TabList)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.custom3
  }
}));

const EconsultCaseConsultActionDetails: FC<EconsultCaseConsultActionDetailsProps> = ({
  options,
  selectedTab,
  onTabSelected
}) => {
  const { t } = useTranslation();
  const [reasonForReturning, setReasonForReturning] = useState<string>('');
  const handleTabSelected = (event: SyntheticEvent<Element, Event>, tab: ConsultTab) => {
    if (onTabSelected) {
      onTabSelected(tab);
    }
  };
  const handleOnReasonForReturningChanged = (reason: string) => {
    setReasonForReturning(reason);
  };
  const {
    provideConsultEnabled,
    requestMoreEnabled,
    returnConsultEnabled,
    addNoteEnabled,
    requestClarificationEnabled,
    completeEnabled,
    cancelEnabled,
    redirectEnabled,
    provideMoreInfoEnable
  } = options;
  return (
    <Box>
      <TabContext value={selectedTab || ''}>
        <EconsultActionTabList onChange={handleTabSelected}>
          {provideConsultEnabled && (
            <EconsultActionTab
              label={t('Case.consult.provideConsult')}
              value={ConsultTab.Provided}
              icon={
                <img
                  src={
                    selectedTab === ConsultTab.Provided
                      ? WriteConsultActiveIcon
                      : WriteConsultInactiveIcon
                  }
                  alt="Provide consult icon"
                />
              }
              iconPosition="start"
            />
          )}
          {requestMoreEnabled && (
            <EconsultActionTab
              label={t('Case.consult.requestMoreInfo')}
              value={ConsultTab.MoreInfo}
              icon={
                <img
                  src={
                    selectedTab === ConsultTab.MoreInfo
                      ? AskMoreInfoActiveIcon
                      : AskMoreInfoInactiveIcon
                  }
                  alt="More info icon"
                />
              }
              iconPosition="start"
            />
          )}
          {addNoteEnabled && (
            <EconsultActionTab
              label={t('Case.consult.addNote')}
              value={ConsultTab.AddNote}
              icon={
                <img
                  src={
                    selectedTab === ConsultTab.AddNote
                      ? WriteConsultActiveIcon
                      : WriteConsultInactiveIcon
                  }
                  alt="Add note icon"
                />
              }
              iconPosition="start"
            />
          )}
          {returnConsultEnabled && (
            <EconsultActionTab
              label={t('Case.consult.returnConsult')}
              value={ConsultTab.Returned}
              icon={
                <img
                  src={
                    selectedTab === ConsultTab.Returned ? DeclineActiveIcon : DeclineInactiveIcon
                  }
                  alt="Return consult icon"
                />
              }
              iconPosition="start"
            />
          )}
          {completeEnabled && (
            <EconsultActionTab
              label={t('Case.consult.complete')}
              value={ConsultTab.Complete}
              icon={
                <img
                  src={
                    selectedTab === ConsultTab.Complete
                      ? CloseCaseActiveIcon
                      : CloseCaseInactiveIcon
                  }
                  alt="Return consult icon"
                />
              }
              iconPosition="start"
            />
          )}
          {requestClarificationEnabled && (
            <EconsultActionTab
              label={t('Case.consult.requestClarification')}
              value={ConsultTab.RequestClarification}
              icon={
                <img
                  src={
                    selectedTab === ConsultTab.RequestClarification
                      ? WriteConsultActiveIcon
                      : WriteConsultInactiveIcon
                  }
                  alt="Request clarification icon"
                />
              }
              iconPosition="start"
            />
          )}
          {redirectEnabled && (
            <EconsultActionTab
              label={t('Case.consult.redirect')}
              value={ConsultTab.Redirect}
              icon={
                <img
                  src={
                    selectedTab === ConsultTab.Redirect ? ReassignActiveIcon : ReassignInactiveIcon
                  }
                  alt="Redirect icon"
                />
              }
              iconPosition="start"
            />
          )}
          {cancelEnabled && (
            <EconsultActionTab
              label={t('Case.consult.cancel')}
              value={ConsultTab.Cancel}
              icon={
                <img
                  src={selectedTab === ConsultTab.Cancel ? CancelActiveIcon : CancelInactiveIcon}
                  alt="Cancel icon"
                />
              }
              iconPosition="start"
            />
          )}
          {provideMoreInfoEnable && (
            <EconsultActionTab
              label={t('Case.consult.provideMoreInfo')}
              value={ConsultTab.ProvideMoreInfo}
              icon={
                <img
                  src={
                    selectedTab === ConsultTab.ProvideMoreInfo
                      ? WriteConsultActiveIcon
                      : WriteConsultInactiveIcon
                  }
                  alt="Provide more info icon"
                />
              }
              iconPosition="start"
            />
          )}
        </EconsultActionTabList>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.Provided}>
          <EconsultCaseProvideConsult
            actionType={NoteActionTypes.writeConsult}
            showRecommendation
          />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.MoreInfo}>
          <EconsultCaseProvideConsult actionType={NoteActionTypes.askForMoreInfo} />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.Returned}>
          <EconsultCaseReturnConsult
            reasonForReturning={reasonForReturning}
            onReasonChanged={handleOnReasonForReturningChanged}
          />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.AddNote}>
          <EconsultCaseProvideConsult actionType={NoteActionTypes.addNote} />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.Complete}>
          <EconsultCaseComplete actionType="complete" />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.RequestClarification}>
          <EconsultCaseProvideConsult
            actionType={NoteActionTypes.reopen}
            noteFieldLabel={t('Case.consult.enterNoteForFurtherClarification') as string}
          />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.Cancel}>
          <EconsultCaseComplete actionType="cancel" />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.Redirect}>
          <EconsultCaseRedirect />
        </EconsultCaseConsultActionTabPanel>
        <EconsultCaseConsultActionTabPanel value={ConsultTab.ProvideMoreInfo}>
          <EconsultCaseProvideConsult actionType={NoteActionTypes.reply} />
        </EconsultCaseConsultActionTabPanel>
      </TabContext>
    </Box>
  );
};

export default EconsultCaseConsultActionDetails;
