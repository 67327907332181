import { AccessTime, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { HoursEntity } from '@oh-vcp/components-common/src/types/FhoDTO';
import { useTranslation } from 'react-i18next';
import HoursListing from '../hoursListing/HoursListing';

interface SearchResultLocationHoursItemProps {
  isOpenToday: boolean;
  isToggled: boolean;
  hours: HoursEntity[];
  closingTime?: string;
  toggleState: () => void;
}

const SearchResultLocationHoursItem: FC<SearchResultLocationHoursItemProps> = ({
  isOpenToday,
  isToggled,
  hours,
  closingTime,
  toggleState
}) => {
  const { t } = useTranslation();
  return (
    <Box onClick={toggleState}>
      <Stack direction="row">
        <Box>
          <AccessTime
            sx={{
              fontSize: 16,
              color: 'primary.main',
              mr: 1,
              mt: 1
            }}
          />
        </Box>
        <Stack direction="row">
          <Typography
            variant="body1"
            sx={{
              color: isOpenToday ? 'green' : 'red',
              mt: 0.6
            }}>
            {isOpenToday ? t('Search.open') : t('Search.closed')}
          </Typography>
          {closingTime && isOpenToday && (
            <Typography variant="body1" mt={0.6} ml={0.5}>
              {` • Closes ${closingTime}`}
            </Typography>
          )}
        </Stack>
        <Box>
          {!isToggled && <ArrowDropDown sx={{ color: 'primary.main', mt: 0.5 }} />}
          {isToggled && <ArrowDropUp sx={{ color: 'primary.main', mt: 0.5 }} />}
        </Box>
      </Stack>
      {isToggled && <HoursListing hours={hours} />}
    </Box>
  );
};

export default SearchResultLocationHoursItem;
