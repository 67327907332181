import { useQuery } from 'react-query';
import { EconsultSpecialist } from '@oh-vcp/components-common';
import apiService from './apiService';

const fetchProgramSpecialists = async (programId: string): Promise<EconsultSpecialist[]> => {
  const response = await apiService.get(`programs/${programId}/specialists`);
  return response.data;
};

const useProgramService = (programId: string) => {
  return useQuery<EconsultSpecialist[]>(
    ['useProgramService', programId],
    () => fetchProgramSpecialists(programId),
    {
      enabled: !!programId,
      refetchOnWindowFocus: false
    }
  );
};

export default useProgramService;
