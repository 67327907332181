import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react';

type MapViewType = {
  isMarkerInfoHidden: boolean | null;
  setIsMarkerInfoHidden: (isMarkerInfoHidden: boolean | null) => void;
};

const MapViewContext = createContext<MapViewType | undefined>(undefined);

type MapViewProviderProps = {
  children: ReactNode;
};

export const MapViewProvider: React.FC<MapViewProviderProps> = ({ children }) => {
  const [isMarkerInfoHidden, setIsMarkerInfoHidden] = useState<boolean | null>(null);
  const contextValue = useMemo(
    () => ({
      isMarkerInfoHidden,
      setIsMarkerInfoHidden
    }),
    [isMarkerInfoHidden]
  );
  return <MapViewContext.Provider value={contextValue}>{children}</MapViewContext.Provider>;
};

export const useMapViewContext = () => {
  const context = useContext(MapViewContext);
  if (!context) {
    throw new Error('useMapViewContext must be used within a MapViewProvider');
  }
  return context;
};

export default MapViewContext;
