import { MenuItem, Select, SelectChangeEvent, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flag } from '@mui/icons-material';
import { useNotifierContext } from '@oh-vcp/components-ui';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';
import { useAddCaseFlag, useEconsultFlagService } from '../services/useEconsultFlagService';

const SelectContainer = styled(Select)({
  width: 150,
  minWidth: 150,
  height: 40,
  borderRadius: 5
});

const EconsultCaseFlagSelector = () => {
  const { t } = useTranslation();
  const { data: flags } = useEconsultFlagService();
  const { addNotification } = useNotifierContext();
  const { caseDetails, setCaseDetails } = useEconsultCaseDetailsContext();
  const useAddCaseFlagMutation = useAddCaseFlag();
  const handleFlagChanged = (e: SelectChangeEvent<unknown>) => {
    if (!caseDetails?.caseId) {
      return;
    }
    if (e.target.value === 'NONE') {
      useAddCaseFlagMutation.mutateAsync({ caseId: caseDetails?.caseId, caseFlagCode: 'NONE' });
      setCaseDetails({
        ...caseDetails,
        flagCd: 'NONE'
      });
    } else {
      const updatedFlagCd = e.target.value as string;
      useAddCaseFlagMutation.mutateAsync({
        caseId: caseDetails?.caseId,
        caseFlagCode: updatedFlagCd
      });
      setCaseDetails({
        ...caseDetails,
        flagCd: updatedFlagCd
      });
    }
    addNotification({
      type: 'success',
      message: t('Case.draft.caseFlagHasBeenSaved')
    });
  };
  const getFlagColor = (flagCode: string) => {
    switch (flagCode) {
      case 'OTHER':
        return 'primary.custom3';
      case 'RESEARCH':
        return 'primary.custom5';
      case 'EDUCATION':
      default:
        return 'primary.main';
    }
  };
  return (
    <SelectContainer
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: getFlagColor(caseDetails?.flagCd || '')
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: getFlagColor(caseDetails?.flagCd || '')
        },
        '& .MuiSelect-icon': {
          color: getFlagColor(caseDetails?.flagCd || '')
        }
      }}
      value={caseDetails?.flagCd || ''}
      displayEmpty
      onChange={handleFlagChanged}>
      <MenuItem value="">
        <Stack direction="row">
          <Flag sx={{ color: 'primary.main', mr: 1 }} />
          <Typography sx={{ color: 'primary.main' }} component="span">
            {t('Case.draft.setFlag')}
          </Typography>
        </Stack>
      </MenuItem>
      {flags?.map((f) => (
        <MenuItem value={f.flagCode} key={f.flagCode}>
          <Stack direction="row">
            <Flag sx={{ color: getFlagColor(f.flagCode), mr: 1 }} />
            <Typography sx={{ color: getFlagColor(f.flagCode) }}>{f.description}</Typography>
          </Stack>
        </MenuItem>
      ))}
      <MenuItem value="NONE">
        <Stack direction="row">
          <Flag sx={{ color: 'base.grey4', mr: 1 }} />
          <Typography sx={{ color: 'base.grey4' }}>{t('Case.draft.noFlag')}</Typography>
        </Stack>
      </MenuItem>
    </SelectContainer>
  );
};

export default EconsultCaseFlagSelector;
