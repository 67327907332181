import React, { FC } from 'react';
import { Button, styled } from '@mui/material';
import { Attachment } from '@oh-vcp/components-common';
import { ArrowUpRightFromSquare, FilePlus, EllipsisTypography } from '@oh-vcp/components-ui';

const StyledButton = styled(Button)({
  'justify-content': 'flex-start',
  width: '100%'
});

type ProfileProtocolCardProps = {
  protocol: Attachment;
  handleDownload: (fileId: string) => void;
};

const ProfileProtocolCard: FC<ProfileProtocolCardProps> = ({ protocol, handleDownload }) => {
  const isLink = protocol.url.startsWith('http') || protocol.url.startsWith('https');
  return (
    <StyledButton
      variant="text"
      component={isLink ? 'a' : 'button'}
      startIcon={isLink ? <ArrowUpRightFromSquare /> : <FilePlus />}
      onClick={!isLink ? () => handleDownload(protocol.url) : undefined}
      href={isLink ? protocol.url : undefined}
      target={isLink ? '_blank' : undefined}
      size="small">
      <EllipsisTypography>{protocol.description}</EllipsisTypography>
    </StyledButton>
  );
};

export default ProfileProtocolCard;
