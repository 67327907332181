import { Box } from '@mui/material';
import { ButtonToggleItem } from '@oh-vcp/components-common';
import { ButtonToggleGroup, EconsultFilterService } from '@oh-vcp/components-ui';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface EconsultCaseInboxFilterProps {
  onSelected: (value: string) => void;
}

const EconsultCaseInboxFilter: FC<EconsultCaseInboxFilterProps> = ({ onSelected }) => {
  const { providerType, statusType } = useParams();
  const [filterItems, setFilterItems] = useState<ButtonToggleItem[]>([]);
  const [filterValue, setFilterValue] = useState<string>('');

  useEffect(() => {
    const eConsultFilters = EconsultFilterService.getEconsultFilters(providerType, statusType);
    setFilterItems(eConsultFilters);
    setFilterValue('');
  }, [providerType, statusType]);

  const handleSelected = (value: string) => {
    setFilterValue(value);
    onSelected(value);
  };

  if (!filterItems || filterItems.length === 0) {
    return null;
  }

  return (
    <Box mt={2} ml={1}>
      <ButtonToggleGroup items={filterItems} value={filterValue} onSelected={handleSelected} />
    </Box>
  );
};

export default EconsultCaseInboxFilter;
