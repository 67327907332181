import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material';
import { AppConfig, SpecialtyOption } from '@oh-vcp/components-common';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface EconsultSelectCategoryDialogProps {
  isDialogOpen: boolean;
  specialtyOptions?: SpecialtyOption[];
  name?: string;
  regionName?: string | null;
  isPriorityRegionNoteShown?: boolean;
  onCancelClicked: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onContinueClicked: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedSpecialtyCode?: string,
    selectedSubSpecialtyCode?: string
  ) => void;
}

interface Specialty {
  code: string;
  description: string;
}

const EconsultSelectCategoryDialog: FC<EconsultSelectCategoryDialogProps> = ({
  isDialogOpen,
  specialtyOptions,
  name,
  regionName,
  isPriorityRegionNoteShown,
  onCancelClicked,
  onContinueClicked
}) => {
  const { t } = useTranslation();
  const isOneSpecialtyProgram = Boolean(specialtyOptions && specialtyOptions.length === 1);
  const [specialties, setSpecialties] = useState<Specialty[]>([]);
  const [subSpecialties, setSubSpecialties] = useState<Specialty[]>([]);
  const [selectedSpecialtyCode, setSelectedSpecialtyCode] = useState('');
  const [selectedSubSpecialtyCode, setSelectedSubSpecialtyCode] = useState('');
  const [isWarningShown, setIsWarningShown] = useState<boolean>(false);
  const handleSpecialtySelected = (event: SelectChangeEvent) => {
    const code = event.target.value;
    setSelectedSpecialtyCode(code);
    setSelectedSubSpecialtyCode('');
    const specialty = specialtyOptions?.find((s) => s.specialty.code === code);
    const subSpecialtiesArr: Specialty[] = [];
    if (
      specialty &&
      specialty.subSpecialty &&
      specialty.subSpecialty.code &&
      specialty.subSpecialty.description
    ) {
      subSpecialtiesArr.push({
        code: specialty.subSpecialty.subCode,
        description: specialty.subSpecialty.description
      });
    }
    setSubSpecialties(subSpecialtiesArr);
    if (selectedSpecialtyCode && selectedSubSpecialtyCode) {
      setIsWarningShown(false);
    }
  };
  const handleSubSpecialtySelected = (event: SelectChangeEvent) => {
    setSelectedSubSpecialtyCode(event.target.value);
    if (selectedSpecialtyCode && selectedSubSpecialtyCode) {
      setIsWarningShown(false);
    }
  };
  const handleContinueClicked = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedSpecialtyCode?: string,
    selectedSubSpecialtyCode?: string
  ) => {
    if (!selectedSpecialtyCode || !selectedSubSpecialtyCode) {
      if (!isPriorityRegionNoteShown || (specialtyOptions && specialtyOptions.length > 1)) {
        setIsWarningShown(true);
        return;
      }
    }
    if (isPriorityRegionNoteShown && specialtyOptions && specialtyOptions.length === 1) {
      if (!selectedSpecialtyCode && specialtyOptions[0].specialty) {
        selectedSpecialtyCode = specialtyOptions[0].specialty.code;
      }
      if (!selectedSubSpecialtyCode && specialtyOptions[0].subSpecialty) {
        selectedSubSpecialtyCode = specialtyOptions[0].subSpecialty.subCode;
      }
    }
    setIsWarningShown(false);
    onContinueClicked(e, selectedSpecialtyCode, selectedSubSpecialtyCode);
  };
  useEffect(() => {
    if (specialtyOptions && specialtyOptions.length > 0) {
      const specialtiesArr: Specialty[] = [];
      specialtyOptions.forEach((item) => {
        if (item.specialty && item.specialty.code && item.specialty.description) {
          specialtiesArr.push({
            code: item.specialty.code,
            description: item.specialty.description
          });
        }
      });
      setSpecialties(specialtiesArr);
    }
  }, [specialtyOptions]);
  useEffect(() => {
    setSelectedSpecialtyCode('');
    setSelectedSubSpecialtyCode('');
    setIsWarningShown(false);
  }, [isDialogOpen]);
  return (
    <Dialog open={isDialogOpen}>
      <DialogTitle variant="h2" sx={{ color: 'primary.main' }}>
        {name}
      </DialogTitle>
      <DialogContent>
        {isPriorityRegionNoteShown && (
          <>
            <Typography variant="body1" sx={{ color: 'primary.custom5' }}>
              {t('Econsult.notInRegion1')} <Typography variant="body1b">{regionName}</Typography>{' '}
              {t('Econsult.notInRegion2')}
            </Typography>
            <Typography variant="body1" sx={{ color: 'primary.custom5' }}>
              {t('Econsult.selectRegion1')}{' '}
              <Link
                href={`${AppConfig.baseSelfServeUrl}/#/serviceSettings/econsult`}
                target="_blank">
                {t('Econsult.econsultSettings')}.
              </Link>
            </Typography>
          </>
        )}
        {!isOneSpecialtyProgram && (
          <Stack direction="column">
            <Box>
              <Typography variant="body1" sx={{ color: 'base.grey4' }}>
                {t('Econsult.selectOptionNote')}
              </Typography>
              <FormControl size="medium" sx={{ minWidth: '45%', mt: 3, mr: 3 }}>
                <InputLabel id="select-label">Select category...</InputLabel>
                <Select
                  value={selectedSpecialtyCode}
                  onChange={handleSpecialtySelected}
                  label="Select category..."
                  labelId="select-label">
                  {specialties.map((item) => (
                    <MenuItem key={item.code} value={item.code}>
                      <Typography variant="body1">{item.description}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="medium" sx={{ minWidth: '45%', mt: 3 }}>
                <InputLabel id="select-label">Select option...</InputLabel>
                <Select
                  value={selectedSubSpecialtyCode}
                  onChange={handleSubSpecialtySelected}
                  label="Select option..."
                  labelId="select-label">
                  {subSpecialties.map((item) => (
                    <MenuItem key={item.code} value={item.code}>
                      <Typography variant="body1">{item.description}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {isWarningShown && (
              <Typography variant="body4" color="error.main">
                {t('Econsult.category.warning')}
              </Typography>
            )}
          </Stack>
        )}
        <Stack direction="row" columnGap={2} mt={2} justifyContent="flex-end">
          <Button
            size="large"
            variant="contained"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              handleContinueClicked(e, selectedSpecialtyCode, selectedSubSpecialtyCode)
            }>
            {t('Profile.requestConsult')}
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onCancelClicked(e)}>
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default EconsultSelectCategoryDialog;
