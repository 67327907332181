import { Box, SvgIcon, Tooltip, Typography } from '@mui/material';
import {
  EconsultUnavailabilityDate,
  EconsultUtils,
  ProgramProfile,
  SpecialtyOption
} from '@oh-vcp/components-common';
import {
  ActionButton,
  ActionItemParams,
  ActionMenuItemLabel,
  ChatSvgIcon,
  RedirectService
} from '@oh-vcp/components-ui';
import moment from 'moment';
import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EconsultOutOfOfficeDialog from '../econsultOutOfOfficeDialog/EconsultOutOfOfficeDialog';
import EconsultSelectCategoryDialog from '../econsultSelectCategoryDialog/EconsultSelectCategoryDialog';
import { fetchRegion } from '../services/regionService';
import { fetchEconsultPreferences } from '../services/econsultPreferencesService';
import { fetchProfile } from '../services/profileService';

interface EconsultActionProps {
  params: ActionItemParams;
  translationKey: string;
  actionType: 'menu' | 'toolbar';
}

const EconsultAction: FC<EconsultActionProps> = ({ params, translationKey, actionType }) => {
  const { t } = useTranslation();
  const ActionComponent = actionType === 'toolbar' ? ActionButton : ActionMenuItemLabel;
  const {
    profileType,
    userId,
    userName,
    unavailability,
    programName,
    programId,
    programStatus,
    programType,
    programSpecialtyOptions,
    programRegionName,
    programRegionId,
    onActionComplete
  } = params;
  const [dialogOpenName, setDialogOpenName] = useState<'outOfOffice' | 'selectCategory' | ''>('');
  const previousDialogOpenName = useRef(dialogOpenName);
  const [isPriorityRegionNoteShown, setIsPriorityRegionNoteShown] = useState<boolean>(false);
  const [programSpecialtyOpts, setProgramSpecialtyOpts] = useState<SpecialtyOption[] | undefined>(
    programSpecialtyOptions
  );
  const futureUnavailability: EconsultUnavailabilityDate | null =
    EconsultUtils.getOutOfOfficeDateInAWeek(unavailability);
  const currentUnavailability = EconsultUtils.getCurrentOutOfOfficeDate(unavailability);
  let progRegionId = programRegionId;
  let programSpecialty: string | undefined;
  let programSubSpecialty: string | undefined;
  if (programSpecialtyOptions && programSpecialtyOptions.length > 0) {
    programSpecialty = programSpecialtyOptions[0].specialty.code;
    programSubSpecialty = programSpecialtyOptions[0].subSpecialty?.subCode;
  }

  const redirectToEconsult = (
    selectedSpecialtyCode?: string,
    selectedSubSpecialtyCode?: string
  ) => {
    switch (profileType) {
      case 'people':
        RedirectService.redirectToEconsultCaseCreate(userId);
        break;
      case 'program':
        RedirectService.redirectToProgramEconsultCaseCreate(
          programId,
          programStatus,
          programType,
          selectedSpecialtyCode || programSpecialty,
          selectedSubSpecialtyCode || programSubSpecialty
        );
        break;
      default:
        break;
    }
  };

  const handleOutOfOfficeContinue = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    redirectToEconsult();
    setDialogOpenName('');
    e.stopPropagation();
  };

  const handleContinue = (
    e: MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedSpecialtyCode?: string,
    selectedSubSpecialtyCode?: string
  ) => {
    if (!selectedSpecialtyCode || !selectedSubSpecialtyCode) {
      return;
    }
    redirectToEconsult(selectedSpecialtyCode, selectedSubSpecialtyCode);
    setDialogOpenName('');
    e.stopPropagation();
  };
  const handleCancel = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setDialogOpenName('');
    e.stopPropagation();
  };

  const getUnavailabilityLabel = () => {
    if (!currentUnavailability) {
      return '';
    }
    return `Unavailable from ${moment(currentUnavailability.startDate).format(
      'MMMM D, YYYY'
    )} to ${moment(currentUnavailability.endDate).format('MMMM D, YYYY')}`;
  };

  const getIsCategoryModalShown = async () => {
    let priorityNoteShown = false;
    let specialtyOptions: SpecialtyOption[] | undefined;
    if (
      (!programRegionId || !programSpecialtyOptions || programSpecialtyOptions.length === 0) &&
      programId
    ) {
      const programProfile = await fetchProfile<ProgramProfile>('programs', programId);
      progRegionId = programRegionId || programProfile.regionId?.toString();
      specialtyOptions =
        programSpecialtyOptions && programSpecialtyOptions.length > 0
          ? programSpecialtyOptions
          : programProfile.specialtyOptions;
      setProgramSpecialtyOpts(specialtyOptions);
      if (specialtyOptions && specialtyOptions.length > 0) {
        if (specialtyOptions[0].specialty && !programSpecialty) {
          programSpecialty = specialtyOptions[0].specialty.code;
        }
        if (specialtyOptions[0].subSpecialty && !programSubSpecialty) {
          programSubSpecialty = specialtyOptions[0].subSpecialty.subCode;
        }
      }
    }
    if (progRegionId) {
      const programRegion = await fetchRegion(progRegionId);
      const econsultPreferences = await fetchEconsultPreferences();
      priorityNoteShown = EconsultUtils.getIsPriorityRegionNoteShown(
        programRegion,
        econsultPreferences
      );
      setIsPriorityRegionNoteShown(priorityNoteShown);
    }

    return (
      priorityNoteShown ||
      (specialtyOptions && specialtyOptions?.length > 1) ||
      (programSpecialtyOptions && programSpecialtyOptions?.length > 1)
    );
  };

  const handleOnClick = async (event: MouseEvent<any>) => {
    event.stopPropagation();
    let dialogOpened = false;
    if (currentUnavailability !== null) {
      return;
    }
    if (profileType === 'people') {
      if (futureUnavailability === null) {
        RedirectService.redirectToEconsultCaseCreate(userId);
      } else if (userName) {
        setDialogOpenName('outOfOffice');
        dialogOpened = true;
      }
    } else if (profileType === 'program') {
      const isCategoryModalShown = await getIsCategoryModalShown();
      if (programType === 'MANAGED_SPECIALTY_GROUP' && isCategoryModalShown) {
        setDialogOpenName('selectCategory');
        dialogOpened = true;
      } else {
        RedirectService.redirectToProgramEconsultCaseCreate(
          programId,
          programStatus,
          programType,
          programSpecialty,
          programSubSpecialty
        );
      }
    }
    if (onActionComplete && !dialogOpened) {
      onActionComplete();
    }
  };

  useEffect(() => {
    if (previousDialogOpenName.current && dialogOpenName === '' && onActionComplete) {
      onActionComplete();
    }
    previousDialogOpenName.current = dialogOpenName;
  }, [dialogOpenName]);

  return (
    <Box style={{ flexGrow: 1 }} onClick={handleOnClick}>
      <Tooltip title={getUnavailabilityLabel()} placement="bottom-end" arrow>
        <span>
          <ActionComponent
            sx={{
              borderColor: currentUnavailability !== null ? 'base.grey4' : 'primary.main',
              '&:hover': {
                backgroundColor: currentUnavailability !== null ? 'transparent' : ''
              }
            }}>
            <SvgIcon
              component={ChatSvgIcon}
              sx={{
                mt: 1,
                fill: currentUnavailability !== null ? 'base.grey4' : 'primary.main',
                color: currentUnavailability !== null ? 'base.grey4' : 'primary.main'
              }}
            />
            <Typography
              sx={{ minWidth: '100%' }}
              variant={actionType === 'toolbar' ? 'h4' : undefined}
              color={currentUnavailability !== null ? 'base.grey4' : ''}>
              {t(translationKey)}
            </Typography>
          </ActionComponent>
        </span>
      </Tooltip>
      <EconsultOutOfOfficeDialog
        isDialogOpen={dialogOpenName === 'outOfOffice'}
        userName={userName}
        startDate={futureUnavailability?.startDate}
        endDate={futureUnavailability?.endDate}
        onContinueClicked={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          handleOutOfOfficeContinue(e)
        }
        onCancelClicked={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleCancel(e)}
      />
      <EconsultSelectCategoryDialog
        isDialogOpen={dialogOpenName === 'selectCategory'}
        name={programName}
        regionName={programRegionName}
        specialtyOptions={programSpecialtyOpts}
        isPriorityRegionNoteShown={isPriorityRegionNoteShown}
        onContinueClicked={(
          e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
          selectedSpecialtyCode: string,
          selectedSubSpecialtyCode: string
        ) => handleContinue(e, selectedSpecialtyCode, selectedSubSpecialtyCode)}
        onCancelClicked={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleCancel(e)}
      />
    </Box>
  );
};

export default EconsultAction;
