import { CaseResponsePayloadDTO } from '@oh-vcp/components-common';
import { apiService } from '@oh-vcp/components-web';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

const fetchCaseSearchResults = async (
  searchKeyword: string | undefined,
  pageNo: number,
  casesPerPage: number
): Promise<CaseResponsePayloadDTO> => {
  let url = `cases/dashboard/search?q=${searchKeyword}&casesPerPage=${casesPerPage}&pageNo=${pageNo}`;
  const response = await apiService.get(url);
  return response.data;
};

const useCaseSearch = (
  enabled: boolean,
  searchKeyword: string | undefined,
  pageNo: number,
  casesPerPage: number
) => {
  return useQuery<CaseResponsePayloadDTO, AxiosError>(
    ['caseSearch', searchKeyword, pageNo, casesPerPage],
    () => fetchCaseSearchResults(searchKeyword, pageNo, casesPerPage),
    {
      enabled: enabled && !!searchKeyword,
      refetchOnWindowFocus: false
    }
  );
};

export default useCaseSearch;
