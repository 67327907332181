import React, { FC, ReactNode } from 'react';
import { OutlinedStyledBoxContainer, StyledBoxContainer } from '../StyledComponents';

interface HighlightedBoxContainerProps {
  isHighlighted: boolean;
  children: ReactNode;
}

const HighlightedBoxContainer: FC<HighlightedBoxContainerProps> = ({ isHighlighted, children }) => {
  const HighlightedBoxContainerComponent = isHighlighted
    ? OutlinedStyledBoxContainer
    : StyledBoxContainer;
  return <HighlightedBoxContainerComponent>{children}</HighlightedBoxContainerComponent>;
};

export default HighlightedBoxContainer;
