import React from 'react';
import { CaseDetails, NoteStatusCodes } from '@oh-vcp/components-common';
import { Box } from '@mui/material';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';
import EconsultCaseNoteItem from '../econsultCaseNoteItem/EconsultCaseNoteItem';

const EconsultCaseNotes = () => {
  const { caseDetails } = useEconsultCaseDetailsContext();
  if (!caseDetails) {
    return null;
  }
  const { notes } = caseDetails as CaseDetails;
  return (
    <Box>
      {notes
        ?.filter((note) => note.statusCd === NoteStatusCodes.active)
        .map((note) => (
          <EconsultCaseNoteItem key={note.noteId} note={note} />
        ))}
    </Box>
  );
};

export default EconsultCaseNotes;
