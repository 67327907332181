import React from 'react';
import { TableCell } from '@mui/material';
import { DataItemRenderer } from '@oh-vcp/components-ui';
import { BaseEntity } from '@oh-vcp/components-common';

export class DefaultDataItemRenderer extends DataItemRenderer {
  public entity: BaseEntity;

  static schema = {
    guid: 'ID',
    name: 'Name',
    lat: 'Latitude',
    lng: 'Longitude'
  };

  constructor(entity: BaseEntity) {
    super('UNKOWN');
    this.entity = entity;
  }

  // getSchema() {
  //   return FhoDataItem.schema;
  // }

  // getColumns() {
  //   return Object.values(FhoDataItem.schema);
  // }

  renderListItem() {
    return (
      <>
        {' '}
        Default List - id: {this.entity.id}, type: {this.entity.type}
      </>
    );
  }

  renderTableRow() {
    return (
      <>
        <TableCell>{this.entity.id}</TableCell>
        <TableCell>{this.entity.type}</TableCell>
      </>
    );
  }

  renderMarker() {
    return (
      <small>
        {this.entity.id}, type: {this.entity.type}
      </small>
      // <Marker key={this.guid} position={{ lat: this.lat, lng: this.lng }}>
      //   <Popup>{this.name}</Popup>
      // </Marker>
    );
  }
}
