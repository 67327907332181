import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface EconsultCaseInboxPaginationProps {
  numToDisplay: number;
  total: number;
  currentPage: number;
  onPrev?: () => void;
  onNext?: () => void;
}

const EconsultCaseInboxPagination: FC<EconsultCaseInboxPaginationProps> = ({
  numToDisplay,
  total,
  currentPage,
  onPrev,
  onNext
}) => {
  const { t } = useTranslation();
  const getPaginationLabel = () => {
    const startIndex = (currentPage - 1) * numToDisplay + 1;
    const endIndex = Math.min(startIndex + numToDisplay - 1, total);
    return `${startIndex}-${endIndex} of ${total}`;
  };
  return (
    <Stack direction="row">
      <Typography color="base.grey4">
        {t('Case.inbox.display')}: {numToDisplay}
      </Typography>
      <ArrowLeft onClick={onPrev} />
      <Typography color="base.grey4">{getPaginationLabel()}</Typography>
      <ArrowRight onClick={onNext} />
    </Stack>
  );
};

export default EconsultCaseInboxPagination;
