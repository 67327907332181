import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { apiService } from '@oh-vcp/components-web';
import { ProgramSpecialty } from '@oh-vcp/components-common';

const fetchSubSpecialties = async (
  userId: number,
  specialty: string
): Promise<ProgramSpecialty[]> => {
  let url = `programs/lookup/region/subspecialties/${specialty}/user/${userId}`;
  const response = await apiService.get(url);
  return response.data;
};

const useSubSpecialtyLookup = (
  userId: number | undefined | null,
  specialty: string | null | undefined
) => {
  return useQuery<ProgramSpecialty[], AxiosError>(
    ['specialtyLookup', userId, specialty],
    () => fetchSubSpecialties(userId!, specialty!),
    {
      enabled: !!userId && !!specialty,
      refetchOnWindowFocus: false
    }
  );
};

export default useSubSpecialtyLookup;
