import React, { useEffect, useState } from 'react';
import { styled, Button, Stack, SvgIcon, useMediaQuery, Theme } from '@mui/material';
import {
  CaseActionList,
  CaseSearchIcon,
  CaseSearchIconActive,
  DashBoardIcon,
  DashBoardIconActive,
  ReportIcon,
  ReportIconActive,
  SettingsIcon,
  SettingsIconActive,
  ChatSvgIcon
} from '@oh-vcp/components-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { EconsultCaseActionItem, EconsultUserService, useAuth } from '@oh-vcp/components-web';
import {
  EconsultProviderTypes,
  EconsultStatusTypes,
  NoteActionTypes,
  NoteTypes
} from '@oh-vcp/components-common';
import CaseService from '@oh-vcp/components-web/src/services/caseService';
import useCaseResults from '../../api/useCaseResults';
import { useEconsultCaseResultContext } from '@oh-vcp/components-web/src/econsultCaseResultContext/EconsultCaseResultContext';
import useAssignerPrograms from '../../api/useAssignerPrograms';

const RequestConsultButton = styled(Button)(({ theme }) => ({
  width: 200,
  alignSelf: 'center'
}));

const EconsultCaseActionPanelContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.custom2,
  paddingTop: 20,
  [theme.breakpoints.down('lg')]: {
    width: '100vw'
  }
}));

const EconsultCaseActionPanel = () => {
  const { t } = useTranslation();
  const { providerType, statusType } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [hasTriage, setHasTriage] = useState<boolean>(false);
  const [hasTriageAssigner, setHasTriageAssigner] = useState<boolean>(false);
  const [hasCons, setHasCons] = useState<boolean>(false);
  const [hasRefs, setHasRefs] = useState<boolean>(false);
  const [isEconsultReferrer, setIsEconsultReferrer] = useState<boolean>(false);
  const [isEconsultConsultant, setIsEconsultConsultant] = useState<boolean>(false);
  const [isEconsultDelegate, setIsEconsultDelegate] = useState<boolean>(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { setDashboardUserRole } = useEconsultCaseResultContext();
  const { data: consultResults } = useCaseResults(
    1,
    25,
    EconsultProviderTypes.consultant.toUpperCase()
  );
  const { data: referrerResults } = useCaseResults(
    1,
    25,
    EconsultProviderTypes.referrer.toUpperCase()
  );
  const { data: assingerPrograms } = useAssignerPrograms();

  useEffect(() => {
    let url = '';
    if (consultResults && consultResults.totalCount > 0) {
      setDashboardUserRole('CONSULTANT');
      url = `/case/${NoteTypes.consultant}/needsaction`;
      const hasTriageCases = EconsultUserService.hasTriage(consultResults.data);
      const hasConCases = EconsultUserService.hasCons(consultResults.data);
      const hasRefCases = EconsultUserService.hasRefs(consultResults.data);
      setHasTriage(hasTriageCases);
      setHasCons(hasConCases);
      setHasRefs(hasRefCases);
    } else if (
      consultResults &&
      consultResults.totalCount === 0 &&
      referrerResults &&
      referrerResults.totalCount > 0
    ) {
      setDashboardUserRole('REFERRER');
      url = `/case/${NoteTypes.referrer}/needsaction`;
      const hasTriageCases = EconsultUserService.hasTriage(referrerResults.data);
      const hasConCases = EconsultUserService.hasCons(referrerResults.data);
      const hasRefCases = EconsultUserService.hasRefs(referrerResults.data);
      setHasTriage(hasTriageCases);
      setHasCons(hasConCases);
      setHasRefs(hasRefCases);
    }

    // If mobile view do not redirect to the default inbox
    if (!providerType && !statusType && !isMobile) {
      navigate(url.toLowerCase());
    }
  }, [
    consultResults,
    setDashboardUserRole,
    navigate,
    providerType,
    statusType,
    isMobile,
    referrerResults
  ]);

  useEffect(() => {
    if (consultResults && consultResults.data && assingerPrograms) {
      const hasTriageAssignerCases = EconsultUserService.hasTriageAssigner(
        consultResults.data,
        assingerPrograms
      );
      setHasTriageAssigner(hasTriageAssignerCases);
    }
  }, [assingerPrograms, consultResults]);

  useEffect(() => {
    if (user) {
      const isReferrer = EconsultUserService.isEconsultReferrer(user);
      const isConsultant = EconsultUserService.isEconsultConsultant(user);
      const isDelegate = EconsultUserService.isEconsultDelegate(user);
      setIsEconsultReferrer(isReferrer || false);
      setIsEconsultConsultant(isConsultant || false);
      setIsEconsultDelegate(isDelegate || false);
    }
  }, [user]);

  const handleOnRequestCaseConsultClicked = async () => {
    if (user) {
      const { userid, firstName, lastName } = user;
      if (userid && firstName && lastName) {
        const response = await CaseService.upsertCaseDetails({
          statusCd: NoteActionTypes.draft,
          programType: null,
          specialty: null,
          subSpecialty: null,
          referrer: {
            userId: userid,
            firstName: firstName,
            lastName: lastName,
            roles: {
              econCaseCreate: true
            }
          },
          notes: [
            {
              noteTypeCd: NoteTypes.referrer,
              ownerId: userid,
              statusCd: NoteActionTypes.draft
            }
          ]
        });

        if (response?.data?.caseId) {
          navigate(`/case/${NoteTypes.referrer}/${NoteActionTypes.draft}/${response.data.caseId}`);
        }
      }
    }
  };
  return (
    <EconsultCaseActionPanelContainer direction="column">
      {user?.roles?.econCaseCreate && (
        <RequestConsultButton
          variant="contained"
          color="primary"
          onClick={handleOnRequestCaseConsultClicked}>
          <SvgIcon component={ChatSvgIcon} inheritViewBox sx={{ mr: 1 }} />
          Request Consult
        </RequestConsultButton>
      )}
      <CaseActionList>
        {(hasCons || hasTriage) && (
          <>
            <EconsultCaseActionItem
              icon={DashBoardIcon}
              activeIcon={DashBoardIconActive}
              isSubItem={false}
              text={t('Case.panel.allConsults')}
              url="/case/consultant/all"
              active={
                (!providerType || providerType === EconsultProviderTypes.consultant) &&
                (!statusType || statusType === EconsultStatusTypes.all)
              }
            />
            <EconsultCaseActionItem
              isSubItem={true}
              text={t('Case.panel.needsAttention')}
              url="/case/consultant/needsaction"
              active={
                providerType === EconsultProviderTypes.consultant &&
                statusType === EconsultStatusTypes.needsAction
              }
              count={consultResults?.consultantCount}
            />
            {!hasTriage && (
              <>
                <EconsultCaseActionItem
                  isSubItem={true}
                  text={t('Case.panel.waitingForMoreInfo')}
                  url="/case/consultant/waiting"
                  active={
                    providerType === EconsultProviderTypes.consultant &&
                    statusType === EconsultStatusTypes.waiting
                  }
                />
                <EconsultCaseActionItem
                  isSubItem={true}
                  text={t('Case.panel.consultProvided')}
                  url="/case/consultant/provided"
                  active={
                    providerType === EconsultProviderTypes.consultant &&
                    statusType === EconsultStatusTypes.provided
                  }
                />
                <EconsultCaseActionItem
                  isSubItem={true}
                  text={t('Case.panel.consultReturned')}
                  url="/case/consultant/declined"
                  active={
                    providerType === EconsultProviderTypes.consultant &&
                    statusType === EconsultStatusTypes.declined
                  }
                />
              </>
            )}
          </>
        )}
        {user?.roles?.econCaseCreate && (
          <>
            <EconsultCaseActionItem
              icon={DashBoardIcon}
              activeIcon={DashBoardIconActive}
              isSubItem={false}
              text={t('Case.panel.allRequests')}
              url="/case/referrer/all"
              active={
                providerType === EconsultProviderTypes.referrer &&
                statusType === EconsultStatusTypes.all
              }
            />
            <EconsultCaseActionItem
              isSubItem={true}
              text={t('Case.panel.needsAttention')}
              url="/case/referrer/needsaction"
              active={
                providerType === EconsultProviderTypes.referrer &&
                statusType === EconsultStatusTypes.needsAction
              }
              count={consultResults?.referrerCount}
            />
            <EconsultCaseActionItem
              isSubItem={true}
              text={t('Case.panel.waitingForResponse')}
              url="/case/referrer/waiting"
              active={
                providerType === EconsultProviderTypes.referrer &&
                statusType === EconsultStatusTypes.waiting
              }
            />
            <EconsultCaseActionItem
              isSubItem={true}
              text={t('Case.panel.completed')}
              url="/case/referrer/completed"
              active={
                providerType === EconsultProviderTypes.referrer &&
                statusType === EconsultStatusTypes.completed
              }
            />
            <EconsultCaseActionItem
              isSubItem={true}
              text={t('Case.panel.cancelled')}
              url="/case/referrer/cancelled"
              active={
                providerType === EconsultProviderTypes.referrer &&
                statusType === EconsultStatusTypes.cancelled
              }
            />
            <EconsultCaseActionItem
              isSubItem={true}
              text={t('Case.panel.drafts')}
              url="/case/referrer/draft"
              active={
                providerType === EconsultProviderTypes.referrer &&
                statusType === EconsultStatusTypes.draft
              }
            />
          </>
        )}
        {(hasCons || hasRefs || hasTriageAssigner) && (
          <EconsultCaseActionItem
            icon={ReportIcon}
            activeIcon={ReportIconActive}
            isSubItem={false}
            text={t('Case.panel.reports')}
            url="/case/reports"
            active={providerType === 'reports'}
          />
        )}
        <EconsultCaseActionItem
          icon={CaseSearchIcon}
          activeIcon={CaseSearchIconActive}
          isSubItem={false}
          text={t('Case.panel.search')}
          url="/case/search"
          active={providerType === 'search'}
        />
        {(isEconsultReferrer || isEconsultConsultant || isEconsultDelegate) && (
          <EconsultCaseActionItem
            icon={SettingsIcon}
            activeIcon={SettingsIconActive}
            isSubItem={false}
            text={t('Case.panel.settings')}
          />
        )}
      </CaseActionList>
    </EconsultCaseActionPanelContainer>
  );
};

export default EconsultCaseActionPanel;
