import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React, { FC } from 'react';
import { HoursEntity } from '@oh-vcp/components-common';
import { useTranslation } from 'react-i18next';
import TimeService from '@oh-vcp/components-ui/src/timeService';
import HoursListingItem from '../hoursListingItem/HoursListingItem';

interface HoursListingProps {
  hours: HoursEntity[];
  title?: string | null;
}

const HoursListing: FC<HoursListingProps> = ({ hours, title }) => {
  const { t } = useTranslation();
  const daysOfWeek = [
    {
      id: 1,
      name: t('DaysOfWeek.monday')
    },
    {
      id: 2,
      name: t('DaysOfWeek.tuesday')
    },
    {
      id: 3,
      name: t('DaysOfWeek.wednesday')
    },
    {
      id: 4,
      name: t('DaysOfWeek.thursday')
    },
    {
      id: 5,
      name: t('DaysOfWeek.friday')
    },
    {
      id: 6,
      name: t('DaysOfWeek.saturday')
    },
    {
      id: 7,
      name: t('DaysOfWeek.sunday')
    }
  ];

  return (
    <Box>
      {title && (
        <Typography variant="h4" color="primary.main">
          {title}
        </Typography>
      )}
      {daysOfWeek.map((day) => (
        <Grid
          key={day.id}
          container
          spacing={0}
          sx={{
            color: moment().day() === day.id ? 'green' : 'inherit',
            pb: 0.5
          }}>
          <Grid item xs={5}>
            <Typography variant="body1">{day.name}</Typography>
          </Grid>
          <Grid item xs={5}>
            <HoursListingItem hours={TimeService.getHoursForDayOfWeek(day.id, hours)} />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default HoursListing;
