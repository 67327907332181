import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Stack,
  Typography,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  styled,
  Tooltip,
  useTheme,
  Button,
  Select,
  MenuItem,
  tooltipClasses,
  TooltipProps,
  TextareaAutosize
} from '@mui/material';
import { useEconsultCaseDetailsContext } from '@oh-vcp/components-web/src/econsultCaseDetailsContext/EconsultCaseDetailsContext';
import {
  CaseAttachment,
  CaseDetails,
  EconsultManagedSpecialtyProgramRegion,
  EconsultRestrictionNoteSection,
  NameUtils,
  NoteActionTypes,
  NoteStatusCodes,
  NoteTypes,
  UserDTO
} from '@oh-vcp/components-common';
import {
  DragDropFileUpload,
  EconsultAttachmentListing,
  EconsultCasePatientForm,
  EconsultCaseSpecialtySection,
  EconsultDeleteDraftDialog,
  EconsultDraftCaseActions,
  EconsultRestrictionNote,
  EconsultSpecialistSearch,
  NotificationFactory,
  NotificationsConfig,
  useAuth
} from '@oh-vcp/components-web';
import CaseService from '@oh-vcp/components-web/src/services/caseService';
import {
  OhipValidationService,
  RedirectService,
  RequiredFieldTypographyWithInfo,
  useNotifierContext,
  RequiredFieldTypography
} from '@oh-vcp/components-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useManagedSpecialtyProgramLookup from '../../api/useManagedSpecialtyProgramLookup';
import useProgramLookup from '../../api/useProgramLookup';
import moment from 'moment';
import ValidatorUtils from '@oh-vcp/components-web/src/econsultBaseValidator/EconsultBaseValidator';
import { ValidationTypes } from '@oh-vcp/components-web/src/econsultBaseValidator/EconsultValidationTypes';
import { ValidationResult } from '@oh-vcp/components-web/src/econsultBaseValidator/ValidationResult';

const DraftDetailsSection = styled(Grid)(({ theme }) => ({
  borderBottomColor: theme.palette.base.grey6,
  borderBottomStyle: 'solid',
  borderBottomWidth: 2,
  paddingTop: 10,
  paddingBottom: 10
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}));

interface EconsultCasePatientFormHandle {
  validate: () => boolean;
}

const EconsultCaseDraftDetailsView = () => {
  const { caseDetails, setCaseDetails } = useEconsultCaseDetailsContext();
  const { addNotification } = useNotifierContext();
  const [isDeleteDraftDialogOpen, setIsDeleteDraftDialogOpen] = useState<boolean>(false);
  const [regionList, setRegionList] = useState<EconsultManagedSpecialtyProgramRegion[] | undefined>(
    undefined
  );
  const [selectedRegionId, setSelectedRegionId] = useState<number | undefined>(undefined);
  const [selectedProgramId, setSelectedProgamId] = useState<number | undefined>(undefined);
  const [specialtyRestriction, setSpecialtyRestrictions] = useState<
    EconsultRestrictionNoteSection[] | []
  >([]);
  const [programRestrictions, setProgramRestrictions] = useState<
    EconsultRestrictionNoteSection[] | []
  >([]);
  const [isRegionSelectShown, setIsRegionSelectShown] = useState<boolean>(false);
  const [options, setOptions] = useState<UserDTO[]>([]);
  const theme = useTheme();
  const { t } = useTranslation();
  const { caseId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { data: managedPrograms } = useManagedSpecialtyProgramLookup(
    user?.userid,
    caseDetails?.specialty?.code,
    caseDetails?.subSpecialty?.subCode
  );
  const { data: managedProgram } = useProgramLookup(selectedProgramId);

  /* validation configuration */

  let [dirtyFields, setFieldDiryty] = useState<string[]>([]);
  let [invalidFields, setFieldInvalid] = useState<string[]>([]);
  const requiredFields: Map<string, ValidationTypes> = new Map();
  requiredFields.set('requestNte', ValidationTypes.Text);
  const vaildFieldMessageType = {
    display: 'none'
  };
  const inVaildFieldMessageType = {
    display: 'block',
    color: 'red'
  };
  const childRef = useRef<EconsultCasePatientFormHandle>();
  const [requestNteErrorMessageStyle, setRequestNteErrorMessageStyle] =
    useState(vaildFieldMessageType);

  /* end of validation configuration */

  useEffect(() => {
    const regions = managedPrograms?.map((program) => {
      return program.region;
    });
    setRegionList(regions);
    setSelectedRegionId(regions?.[0]?.id);
  }, [managedPrograms]);

  useEffect(() => {
    const program = managedPrograms?.find((program) => {
      return program.region.id === selectedRegionId;
    });
    setSelectedProgamId(program?.programId);
  }, [selectedRegionId, managedPrograms]);

  useEffect(() => {
    if (!managedProgram) return;
    let econsultRestrictions: EconsultRestrictionNoteSection = {
      title: 'eConsult Case Conditions',
      properties: []
    };
    let aboutGroupRestrictions: EconsultRestrictionNoteSection = {
      title: 'About the Group',
      properties: [
        {
          name: '',
          value: managedProgram.programDescription
        }
      ]
    };
    if (managedProgram.econCondRegionText) {
      econsultRestrictions.properties.push({
        name: 'Only accepted from LHINs/Regions',
        value: managedProgram.econCondRegionText
      });
    }
    if (managedProgram.econCondition) {
      econsultRestrictions.properties.push({
        name: 'Other conditions',
        value: managedProgram.econCondition
      });
    }
    setProgramRestrictions([econsultRestrictions, aboutGroupRestrictions]);
  }, [managedProgram]);

  useEffect(() => {
    let restrictions: EconsultRestrictionNoteSection[] = [];
    if (caseDetails?.participant?.consultant?.specializedAreaOfPractice) {
      restrictions.push({
        title: 'Telemedicine Service Details',
        properties: [
          {
            name: '',
            value: caseDetails.participant.consultant.specializedAreaOfPractice
          }
        ]
      });
    }
    if (
      caseDetails?.participant?.consultant?.econsult &&
      (caseDetails.participant.consultant.econsult.catchmentArea ||
        caseDetails.participant.consultant.econsult.otherConditions)
    ) {
      let econsultRestriction: EconsultRestrictionNoteSection = {
        title: 'eConsult Case Conditions',
        properties: []
      };
      if (
        caseDetails.participant.consultant.econsult &&
        caseDetails.participant.consultant.econsult.catchmentArea
      ) {
        econsultRestriction.properties.push({
          name: 'Only accepted from LHINs/Regions',
          value: caseDetails.participant.consultant.econsult.catchmentArea
        });
      }
      if (
        caseDetails.participant.consultant.econsult &&
        caseDetails.participant.consultant.econsult.otherConditions
      ) {
        econsultRestriction.properties.push({
          name: 'Other conditions',
          value: caseDetails.participant.consultant.econsult.otherConditions
        });
      }
    }
    setSpecialtyRestrictions(restrictions);
  }, [caseDetails?.participant?.consultant]);

  if (!caseDetails || caseDetails.caseId?.toString() !== caseId) {
    return null;
  }
  const { referrer, programType, notes } = caseDetails;

  const requestTextAreaStyle = {
    width: '100%',
    outline: 'none',
    border: 'none',
    resize: 'none' as 'none',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    paddingTop: 20
  };

  const handleSearchRecipientInputChange = async (inputValue: string) => {
    if (inputValue.length > 2) {
      const specialistsAndGroups = await CaseService.fetchCaseSpecialistsAndGroups(inputValue);
      setOptions(specialistsAndGroups);
    } else {
      setOptions([]);
    }
  };
  const handleConsultantChanged = (consultant: UserDTO | null) => {
    const updatedCaseDetails = {
      ...caseDetails,
      participant: {
        ...caseDetails.participant,
        consultant: consultant
      }
    };
    setCaseDetails(updatedCaseDetails);
  };
  const handleProgramTypeChanged = async (event: ChangeEvent<HTMLInputElement>, value: string) => {
    if (!value) {
      return;
    }
    const updatedCaseDetails = {
      ...caseDetails,
      programType: value
    };
    if (value === 'DIRECT') {
      delete updatedCaseDetails?.specialty;
      delete updatedCaseDetails?.subSpecialty;
      setIsRegionSelectShown(false);
    } else if (value === 'MANAGED_SPECIALTY_GROUP') {
      delete updatedCaseDetails?.participant?.consultant;
    }
    setCaseDetails(updatedCaseDetails);
    await CaseService.upsertCaseDetails(updatedCaseDetails);
  };

  const handleNoteContentChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    const { name } = event.target;
    const fieldName = { name }.name;
    const res: ValidationResult = ValidatorUtils.handleValidation(
      requiredFields,
      dirtyFields,
      invalidFields,
      fieldName,
      value
    );
    setFieldDiryty(res.dirtyFields);
    setFieldInvalid(res.invalidFields);

    const valid = res.isValid;

    if (valid) {
      setRequestNteErrorMessageStyle(vaildFieldMessageType);
    } else {
      setRequestNteErrorMessageStyle(inVaildFieldMessageType);
    }

    const updatedCaseDetails = {
      ...caseDetails,
      notes: [
        ...(caseDetails.notes
          ? [
              {
                ...caseDetails.notes[0],
                noteContent: value
              }
            ]
          : []),
        ...(caseDetails.notes ? caseDetails.notes.slice(1) : [])
      ]
    };
    setCaseDetails(updatedCaseDetails);
  };
  const handleDraftSaved = async () => {
    await CaseService.upsertCaseDetails(caseDetails);
    addNotification(
      NotificationFactory.createNotification(
        'success',
        NotificationsConfig.econsultSaveDraft.success
      )
    );
  };
  const handleAddNoteAttachment = async (fileId: number, fileName: string) => {
    if (!caseDetails.notes || caseDetails.notes.length === 0) return;
    const cloneCaseDetails: CaseDetails | undefined = JSON.parse(JSON.stringify(caseDetails));
    if (!cloneCaseDetails || !cloneCaseDetails.notes || cloneCaseDetails.notes.length === 0) return;
    const note = cloneCaseDetails.notes[0];
    const attachment = {
      fileId: fileId,
      statusCd: 'ACTIVE'
    };
    note.attachments = [];
    note.attachments.push(attachment);
    const responseNote = await CaseService.upsertCaseNote(note);
    const updatedCaseDetails = {
      ...cloneCaseDetails,
      notes: [
        ...(cloneCaseDetails.notes
          ? [
              {
                ...cloneCaseDetails.notes[0],
                attachments: [
                  ...(responseNote.attachments
                    ? responseNote.attachments.map((att) =>
                        att.fileId === fileId
                          ? {
                              ...att,
                              uploadedFile: {
                                fileName,
                                contentType: 'application/pdf',
                                createdBy: 'test',
                                createdOn: moment().toISOString(),
                                fileId: fileId,
                                statusCd: NoteStatusCodes.active
                              }
                            }
                          : att
                      )
                    : [])
                ]
              }
            ]
          : []),
        ...(cloneCaseDetails.notes ? cloneCaseDetails.notes.slice(1) : [])
      ]
    };
    setCaseDetails(updatedCaseDetails);
  };
  const handleAttachmentDeleted = async (attachmentId: number) => {
    let attachments: CaseAttachment[] | undefined = JSON.parse(
      JSON.stringify(caseDetails?.notes?.[0]?.attachments)
    );
    if (!attachments || attachments.length === 0) return;
    const updatedAttachments = attachments?.map((attachment) => {
      if (attachment.attachmentId === attachmentId) {
        //const { uploadedFile, ...rest } = attachment;
        return {
          ...attachment,
          uploadedFile: {
            ...attachment.uploadedFile,
            statusCd: NoteStatusCodes.deleted
          },
          statusCd: NoteStatusCodes.deleted
        };
      }
      return attachment;
    });
    const updatedCaseDetails = {
      ...caseDetails,
      notes: [
        ...(caseDetails.notes
          ? [
              {
                ...caseDetails.notes[0],
                attachments: updatedAttachments
              }
            ]
          : []),
        ...(caseDetails.notes ? caseDetails.notes.slice(1) : [])
      ]
    };
    setCaseDetails(updatedCaseDetails);
    if (caseDetails && caseDetails.notes) {
      CaseService.upsertCaseNote({
        ...caseDetails.notes[0],
        attachments: updatedAttachments
      });
    }
  };

  const isPatientFormValid = (): boolean => {
    let isPatientDetailsValid: boolean = true;

    if (
      !OhipValidationService.isOhipNumberValid(caseDetails?.patient?.ohipNumber) &&
      !caseDetails?.patient?.noOhip
    ) {
      isPatientDetailsValid = false;
    }
    if (childRef.current) {
      const isPatientValid = childRef.current.validate();
      isPatientDetailsValid = isPatientValid;
    }
    const isRequestNteValid = caseDetails.notes?.[0].noteContent;

    if (isRequestNteValid) {
      setRequestNteErrorMessageStyle(vaildFieldMessageType);
    } else {
      setRequestNteErrorMessageStyle(inVaildFieldMessageType);
      isPatientDetailsValid = false;
    }

    setFieldInvalid(invalidFields);
    return isPatientDetailsValid;
  };
  const handleDraftDeleteConfirm = async () => {
    const updatedCaseDetails: CaseDetails = {
      ...caseDetails,
      statusCd: NoteStatusCodes.deleted
    };
    setCaseDetails(updatedCaseDetails);
    await CaseService.upsertCaseDetails(updatedCaseDetails);
    addNotification(
      NotificationFactory.createNotification(
        'success',
        NotificationsConfig.econsultDeleteDraft.success
      )
    );
    navigate(`/case/${NoteTypes.referrer}/${NoteActionTypes.draft}`);
  };
  const handleDraftDeleted = () => {
    setIsDeleteDraftDialogOpen(true);
  };

  const handleDraftSend = async () => {
    if (!isPatientFormValid()) {
      return;
    }
    const region = regionList?.find((region) => {
      return region.id === selectedRegionId;
    });
    const updatedCaseDetails: CaseDetails = {
      ...caseDetails,
      statusCd: NoteStatusCodes.active,
      participant: managedProgram
        ? {
            program: {
              ...managedProgram,
              region: region
            },
            statusCd: NoteStatusCodes.active,
            triageStatusCd: NoteStatusCodes.active
          }
        : {
            ...caseDetails.participant,
            statusCd: NoteStatusCodes.active
          },
      notes: [
        ...(caseDetails.notes
          ? [
              {
                ...caseDetails.notes[0],
                ownerId: user?.userid,
                statusCd: NoteStatusCodes.active,
                noteTypeCd: NoteTypes.referrer,
                actionTypeCd: NoteActionTypes.send
              }
            ]
          : []),
        ...(caseDetails.notes ? caseDetails.notes.slice(1) : [])
      ]
    };
    setCaseDetails(updatedCaseDetails);
    await CaseService.upsertCaseDetails(updatedCaseDetails);
    addNotification(
      NotificationFactory.createNotification(
        'success',
        NotificationsConfig.econsultDraftConsult.success
      )
    );
    navigate(`/case/${NoteTypes.referrer}/${NoteActionTypes.draft}`);
  };
  const handleViewProgram = () => {
    if (selectedProgramId) {
      RedirectService.redirectToProgramProfile(selectedProgramId.toString());
    }
  };
  const handleViewPeopleProfile = () => {
    if (caseDetails?.participant?.consultant?.userId) {
      RedirectService.redirectToDirectoryPeopleProfile(
        caseDetails.participant.consultant.userId.toString()
      );
    }
  };
  return (
    <>
      <Stack direction="column" p={4}>
        <Grid container spacing={0}>
          <DraftDetailsSection item xs={12} md={2}>
            <Typography variant="body1" color="base.grey4">
              {t('Case.draft.details.view.requester')}
            </Typography>
          </DraftDetailsSection>
          <DraftDetailsSection item xs={12} md={10}>
            <Typography variant="body1" color="primary.main" mb={2}>
              {referrer &&
                NameUtils.makeName(
                  referrer.lastName || '',
                  referrer.firstName,
                  referrer.salutation,
                  null,
                  null,
                  null
                )}
            </Typography>
          </DraftDetailsSection>
          <DraftDetailsSection item xs={12} md={2}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <b>BASE Managed Specialty:</b>
                  This option enables you to submit cases to either a regional (where available) or
                  provincial managed specialty group, which is a group of specialists responding to
                  eConsult cases for a given specialty or sub-specialty (e.g. pediatric cardiology).
                  The case is then assigned based on specialist availability.'
                  <br />
                  <br />
                  <b>Specific Provider or Group: </b> This option allows you to submit cases to
                  specific consultants by name or to organizational or regional groups (ex. UHN\'s
                  SCOPE group). Cases are sent directly to the consultant or group.'
                </React.Fragment>
              }>
              <RequiredFieldTypographyWithInfo variant="body1" color="base.grey4" pt={1}>
                {t('Case.detials.model')}
              </RequiredFieldTypographyWithInfo>
            </HtmlTooltip>
          </DraftDetailsSection>
          <DraftDetailsSection item xs={12} md={10}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                onChange={handleProgramTypeChanged}
                value={programType}>
                <FormControlLabel
                  value="MANAGED_SPECIALTY_GROUP"
                  control={<Radio />}
                  label="BASE Managed Specialty"
                  labelPlacement="end"
                  sx={{ color: 'base.grey4' }}
                />
                <FormControlLabel
                  value="DIRECT"
                  control={<Radio />}
                  label="Specific Provider or Group"
                  labelPlacement="end"
                  sx={{ color: 'base.grey4' }}
                />
              </RadioGroup>
            </FormControl>
          </DraftDetailsSection>
          {caseDetails?.programType && (
            <>
              {caseDetails?.programType === 'DIRECT' && (
                <>
                  <DraftDetailsSection item xs={12} md={2}>
                    <RequiredFieldTypography variant="body1" color="base.grey4" pt={2}>
                      {t('Case.draft.details.view.recipient')}
                    </RequiredFieldTypography>
                  </DraftDetailsSection>
                  <DraftDetailsSection item xs={12} md={10}>
                    <EconsultSpecialistSearch
                      options={options}
                      onInputChange={handleSearchRecipientInputChange}
                      onSelectionChange={handleConsultantChanged}
                    />
                    {caseDetails.participant &&
                      caseDetails.participant.consultant &&
                      ((caseDetails.participant.consultant.econsult &&
                        (caseDetails.participant.consultant.econsult.catchmentArea ||
                          caseDetails.participant.consultant.econsult.otherConditions)) ||
                        caseDetails.participant.consultant.patientEligibility ||
                        caseDetails.participant.consultant.specializedAreaOfPractice) && (
                        <>
                          <EconsultRestrictionNote
                            note={t('Case.consult.specialistRestrictionsNote')}
                            properties={specialtyRestriction}
                          />
                        </>
                      )}
                    {caseDetails?.participant?.consultant && (
                      <Button variant="text" sx={{ pl: 0 }} onClick={handleViewPeopleProfile}>
                        {t('Case.consult.viewDirectoryProfile')}
                      </Button>
                    )}
                  </DraftDetailsSection>
                </>
              )}
              {caseDetails?.programType === 'MANAGED_SPECIALTY_GROUP' && (
                <>
                  <DraftDetailsSection item xs={12} md={2}>
                    <RequiredFieldTypography variant="body1" color="base.grey4" pt={2}>
                      {t('Case.draft.details.view.speciality')}
                    </RequiredFieldTypography>
                  </DraftDetailsSection>
                  <DraftDetailsSection item xs={12} md={10}>
                    <EconsultCaseSpecialtySection />
                  </DraftDetailsSection>
                  <DraftDetailsSection item xs={12} md={2}>
                    <RequiredFieldTypography variant="body1" color="base.grey4" pt={2}>
                      Region
                    </RequiredFieldTypography>
                  </DraftDetailsSection>
                  <DraftDetailsSection item xs={12} md={10}>
                    <Stack direction="row" alignItems="center">
                      {regionList && regionList.length > 0 && (
                        <>
                          {!isRegionSelectShown ? (
                            <>
                              <Typography>{regionList?.[0]?.name}</Typography>
                              <Button
                                variant="text"
                                sx={{ ml: 3 }}
                                onClick={() => setIsRegionSelectShown(true)}>
                                Change
                              </Button>
                            </>
                          ) : (
                            <Select
                              value={selectedRegionId}
                              onChange={(e) =>
                                setSelectedRegionId(e.target.value as number | undefined)
                              }>
                              {regionList.map((region) => (
                                <MenuItem value={region.id}>{region.name}</MenuItem>
                              ))}
                            </Select>
                          )}
                        </>
                      )}
                    </Stack>
                    {(managedProgram?.econCondRegionText || managedProgram?.econCondition) && (
                      <>
                        <EconsultRestrictionNote
                          note={t('Case.consult.restrictionsNote')}
                          properties={programRestrictions}
                        />
                      </>
                    )}
                    {regionList && regionList.length > 0 && (
                      <Button variant="text" sx={{ pl: 0 }} onClick={handleViewProgram}>
                        {t('Case.consult.viewDirectoryProfile')}
                      </Button>
                    )}
                  </DraftDetailsSection>
                </>
              )}
              <DraftDetailsSection item xs={12} md={2}>
                <Typography variant="body1" color="base.grey4">
                  {t('Case.draft.details.view.patient')}
                </Typography>
              </DraftDetailsSection>
              <DraftDetailsSection item xs={12} md={10}>
                <EconsultCasePatientForm ref={childRef} />
              </DraftDetailsSection>
              <DraftDetailsSection item xs={12} md={2}>
                <RequiredFieldTypography variant="body1" color="base.grey4" pt={2}>
                  {t('Case.draft.details.view.request')}
                </RequiredFieldTypography>
              </DraftDetailsSection>
              <DraftDetailsSection item xs={12} md={10}>
                <TextareaAutosize
                  onChange={handleNoteContentChanged}
                  placeholder={t('Case.draft.requestPlaceholder') as string}
                  maxRows={10}
                  value={notes?.[0].noteContent || ''}
                  style={requestTextAreaStyle}
                  name="requestNte"
                />
                <Typography id="requestNteValidationdMessage" style={requestNteErrorMessageStyle}>
                  {t('Case.draft.details.view.request.nte.required')}
                </Typography>
              </DraftDetailsSection>
            </>
          )}
        </Grid>
        {caseDetails?.programType && (
          <>
            <Box mt={3}>
              <EconsultDraftCaseActions
                onSaveDraft={handleDraftSaved}
                onDeleteDraft={handleDraftDeleted}
                onSend={handleDraftSend}
              />
            </Box>
            <Box mt={3}>
              <Box mb={1}>
                {caseDetails?.notes?.[0]?.attachments && (
                  <EconsultAttachmentListing
                    attachments={caseDetails?.notes?.[0]?.attachments}
                    onAttachmentDeleted={handleAttachmentDeleted}
                  />
                )}
              </Box>
              <DragDropFileUpload onUploadComplete={handleAddNoteAttachment} />
            </Box>
          </>
        )}
      </Stack>
      <EconsultDeleteDraftDialog
        isOpen={isDeleteDraftDialogOpen}
        onConfirm={handleDraftDeleteConfirm}
        onCancel={() => setIsDeleteDraftDialogOpen(false)}
      />
    </>
  );
};

export default EconsultCaseDraftDetailsView;
