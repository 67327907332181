import { ToolbarActionItem } from '@oh-vcp/components-common';

const getProfileToolbarActionItemConfig = (): ToolbarActionItem[] => {
  return [
    {
      name: 'print',
      translationKey: 'ActionItem.print'
    },
    {
      name: 'econsult',
      mtName: 'isRequestEconsultAllowed',
      translationKey: 'ActionItem.requestConsult'
    },
    {
      name: 'edit',
      mtName: 'isEditAllowed',
      translationKey: 'ActionItem.edit'
    },
    {
      name: 'econsultApplyForPermission',
      mtName: 'isApplyForPermissionAllowed',
      translationKey: 'ActionItem.requestApplyForPermissionConsult'
    }
  ];
};

const getNumberOfItemsToPin = (
  profileType?: 'people' | 'site' | 'community' | 'fho' | 'program'
): number => {
  switch (profileType) {
    default:
      return 3;
  }
};

const ProfileToolbarActionItemConfig = {
  getProfileToolbarActionItemConfig,
  getNumberOfItemsToPin
};

export default ProfileToolbarActionItemConfig;
