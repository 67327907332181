import { Popper, PopperProps } from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';

interface SearchSuggestionPopperProps extends PopperProps {
  anchorEl: HTMLElement | null;
}

const SearchSuggestionPopper = forwardRef<HTMLDivElement, SearchSuggestionPopperProps>(
  (props, ref) => {
    const { style, anchorEl, ...rest } = props;
    const [popperWidth, setPopperWidth] = useState<number | null>(null);
    useEffect(() => {
      if (anchorEl) {
        setPopperWidth(anchorEl.offsetWidth);
      }
    }, [anchorEl]);
    return (
      <Popper
        anchorEl={anchorEl}
        ref={ref}
        {...rest}
        style={{ ...style, width: popperWidth ? `${popperWidth}px` : 'auto' }}
        placement="bottom-start"
      />
    );
  }
);

export default SearchSuggestionPopper;
