import React, { FC } from 'react';
import { FhoEntity } from '@oh-vcp/components-common';
import { BuildingUserSmSVGIcon } from '@oh-vcp/components-ui/src/icons';
import SearchResultCardHeader from '../searchResultCardHeader/SearchResultCardHeader';

interface FhoListHeaderCardProps {
  fho: FhoEntity;
  isCompact?: boolean;
  highlightText?: string | null;
}

const FhoListHeaderCard: FC<FhoListHeaderCardProps> = ({ fho, isCompact, highlightText }) => {
  const { name, fhoType, organization, id, highlight } = fho;
  const url = `/fho/${organization?.id}/service/${id}`;
  return (
    <SearchResultCardHeader
      title={organization?.name || name}
      subTitle={fhoType}
      secondarySubTitle={name}
      icon={BuildingUserSmSVGIcon}
      url={url}
      highlightText={highlightText}
      highlight={highlight}
      isCompact={isCompact}
    />
  );
};

export default FhoListHeaderCard;
