import { ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface EconsultCaseActionItemProps {
  icon?: any;
  activeIcon?: any;
  text?: string | null;
  isSubItem?: boolean;
  url?: string;
  active?: boolean;
  count?: number;
}

const CaseActionText = styled(
  ({
    isActive,
    primary,
    ...otherProps
  }: {
    isActive: boolean | undefined;
    primary?: string | null;
  }) => <ListItemText primary={primary} {...otherProps} />
)<{ isActive: boolean | undefined }>(({ theme, isActive }) => ({
  '& .MuiTypography-root': {
    fontSize: theme.typography.body2.fontSize,
    color: isActive ? theme.palette.primary.custom3 : theme.palette.base.grey4
  }
}));
const CaseActionButton = styled(ListItemButton)({
  padding: '0.3rem',
  '&:hover': {
    backgroundColor: 'transparent'
  }
});
const CaseActionSubMenuButton = styled(CaseActionButton)({
  paddingLeft: '1.5rem'
});
const CaseActionIcon = styled(ListItemIcon)({
  minWidth: '2rem'
});

const EconsultCaseActionItem: FC<EconsultCaseActionItemProps> = ({
  icon: Icon = null,
  activeIcon: ActiveIcon = null,
  text,
  isSubItem,
  url,
  active,
  count
}) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean>(false);
  const CaseButton = isSubItem ? CaseActionSubMenuButton : CaseActionButton;

  useEffect(() => {
    setIsActive(active || false);
  }, [active]);

  const handleOnClick = () => {
    if (url) {
      navigate(url);
    }
  };
  return (
    <CaseButton
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(active || false)}
      onClick={handleOnClick}>
      <CaseActionIcon>
        {isActive || active ? ActiveIcon ? <ActiveIcon /> : null : Icon ? <Icon /> : null}
      </CaseActionIcon>
      <CaseActionText
        isActive={isActive || active}
        primary={count && count > 0 ? `${text} (${count})` : text}
      />
    </CaseButton>
  );
};

export default EconsultCaseActionItem;
