import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AppConfig } from '@oh-vcp/components-common';
import { FooterContainer, FooterSection, ResponsiveGrid } from '../StyledComponents';
import TextLink from '../textLink/TextLink';
import { OntarioHealthLogo } from '../icons';

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const copyright = t('Footer.copyright').replace('$currentYear', currentYear.toString());
  return (
    <FooterContainer>
      <Stack direction="row">
        <Stack flexGrow={1}>
          <FooterSection>
            <ResponsiveGrid container sx={{ rowGap: 2, gap:1.5 }}>
              <TextLink
                id="home-link-id"
                label={t('Link.privacy')}
                url={AppConfig.privacyUrl}
                target="_blank"
              />
              <TextLink
                id="contacts-link-id"
                label={t('Link.contactUs')}
                url={AppConfig.contactUsUrl}
                target="_blank"
              />
              <TextLink
                id="feedback-link-id"
                label={t('Link.feedback')}
                url={AppConfig.feedbackUrl}
                target="_blank"
              />
            </ResponsiveGrid>
          </FooterSection>
          <FooterSection>
            <Typography variant="caption">{copyright}</Typography>
          </FooterSection>
        </Stack>
        <Stack pt={2}>
          <OntarioHealthLogo />
        </Stack>
      </Stack>
    </FooterContainer>
  );
};

export default Footer;
