import { logger } from './logUtils';

export const isHttps = (url: string) => {
  return url && url.indexOf('https') === 0;
};

export const getPath = (url: string): string => {
  if (!url) {
    return '';
  }
  logger.info('getPah url:', url);
  const parsedUrl = new URL(url);

  logger.info('parsedUrl:', parsedUrl);
  logger.info('Pathname:', parsedUrl.pathname); // Outputs: "/user/123"

  return `${parsedUrl.pathname}${parsedUrl.search}`;
};

const urlUtils = {
  isHttps,
  getPath
};

export default urlUtils;
