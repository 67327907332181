import { Policy } from '@oh-vcp/components-common';
import React, { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { ActionItemParams } from '@oh-vcp/components-ui/src/types/ActionItemParams';
import ProfileToolbarActionItemConfig from '../profileToolbarActionItemConfig/ProfileToolbarActionItemConfig';
import ProfileActionMenu from '../profileActionMenu/ProfileActionMenu';
import ProfileActionToolbar from '../profileActionToolbar/ProfileActionToolbar';
import { useSearchResultsViewContext } from '../searchResults/SearchResultsViewContext';

interface SearchListToolbarProps {
  policy?: Policy;
  actionParams: ActionItemParams;
}

const SearchListToolbar: FC<SearchListToolbarProps> = ({ policy, actionParams }) => {
  const { isMinimalView } = useSearchResultsViewContext();
  const toolbarActionItems =
    ProfileToolbarActionItemConfig.getProfileToolbarActionItemConfig()?.filter(
      (i) =>
        ((policy && policy[i.mtName as keyof Policy]) || !i.mtName) &&
        i.name !== 'edit' &&
        i.name !== 'print'
    );

  return (
    <>
      {toolbarActionItems?.length > 0 && isMinimalView && (
        <Stack mt={0} mr={0}>
          <ProfileActionMenu
            menuType="list"
            params={actionParams}
            toolbarActionItems={toolbarActionItems}
          />
        </Stack>
      )}
      {toolbarActionItems?.length === 1 && !isMinimalView && (
        <Stack pt={2} pr={2}>
          <ProfileActionToolbar
            numItemsToPin={1}
            toolbarActionItems={toolbarActionItems}
            params={actionParams}
          />
        </Stack>
      )}
    </>
  );
};

export default SearchListToolbar;
