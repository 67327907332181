import { Box, Grid, Stack, styled, SvgIcon, Theme, Typography, useMediaQuery } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppConfig,
  EconsultCaseFlag,
  EconsultUserRoles,
  NameUtils,
  dateUtils,
  storageUtils
} from '@oh-vcp/components-common';

import { DynamicLink, EmrIcon, PDFIconWhite, useNotifierContext } from '@oh-vcp/components-ui';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';
import EconsultPatientDetailsContainer from '../econsultPatientDetailsContainer/EconsultPatientDetailsContainer';
import EconsultExportEmrDialog from '../econsultExportEmrDialog/EconsultExportEmrDialog';
import CaseService from '../services/caseService';
import { NotificationFactory } from '../notifier/NotificationFactory';
import EconsultCaseFlagSelector from '../econsultCaseFlagSelector/EconsultCaseFlagSelector';

const LinkButton = styled(Typography)({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
});

const IconContainer = styled(Box)<{ isActive: boolean }>(({ theme, isActive }) => ({
  backgroundColor: isActive ? theme.palette.primary.main : theme.palette.base.grey5,
  height: 35,
  width: 35,
  borderRadius: '50%',
  paddingLeft: 3,
  paddingTop: 3,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: isActive ? theme.palette.primary.custom3 : theme.palette.base.grey5
  }
}));

interface EconsultCaseHeaderProps {
  isPdfEnabled: boolean;
  isEmrEnabled: boolean;
}

const EconsultCaseHeader: FC<EconsultCaseHeaderProps> = ({ isPdfEnabled, isEmrEnabled }) => {
  const { t } = useTranslation();
  const { addNotification } = useNotifierContext();
  const { caseDetails, userRole } = useEconsultCaseDetailsContext();
  const [emrButtonDisabled, setEmrButtonDisabled] = useState<boolean>(false);
  const [isEmrDialogOpen, setIsEmrDialogOpen] = useState<boolean>(false);
  const [isPatientDetailsShown, setIsPatientDetailsShown] = useState<boolean>(false);
  const [selectedFlag, setSelectedFlag] = useState<EconsultCaseFlag | undefined>(undefined);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (
      ((userRole === EconsultUserRoles.consultant ||
        userRole === EconsultUserRoles.consultantDelegate) &&
        caseDetails?.participant?.lastHrmOn) ||
      ((userRole === EconsultUserRoles.referrer ||
        userRole === EconsultUserRoles.referrerDelegate) &&
        caseDetails?.lastHrmOn)
    ) {
      setEmrButtonDisabled(true);
    } else {
      setEmrButtonDisabled(false);
    }
  }, [caseDetails]);

  if (!caseDetails) {
    return null;
  }

  const { referrer, participant, patient, caseId } = caseDetails;
  const getPatientGender = () => {
    const genderName = NameUtils.makeGenderName(patient?.gender);
    if (genderName) {
      return `, ${genderName}`;
    }
  };
  const getPatientAge = () => {
    if (!patient?.dateOfBirth) {
      return '';
    }
    const age = dateUtils.getAge(new Date(patient.dateOfBirth));
    if (age) {
      return `, ${age} ${t('Case.detials.yearsOld')}`;
    }
  };
  const exportToPdf = () => {
    const token = storageUtils.getUserToken();
    const url = `${AppConfig.apiHost}cases/export/${caseId}?api_access_token=${token}`;
    window.location.href = url;
  };
  const exportToEmr = async () => {
    if (caseId) {
      const response = await CaseService.exportToHrm(caseId.toString());
      if (
        response &&
        response['Mirth-Response'] &&
        response['Mirth-Response'].indexOf('|AA|') > -1
      ) {
        addNotification(
          NotificationFactory.createNotification('success', {
            message: `${t('Case.emr.caseId')} - ${t('Case.emr.exportSuccess')}`
          })
        );
      } else {
        addNotification(
          NotificationFactory.createNotification('error', {
            message: `${t('Case.emr.caseId')} - ${t('Case.emr.exportFailed')}`
          })
        );
      }
    }
  };
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={2}>
          <Typography variant="body1" color="base.grey4" mb={isMobile ? 0 : 2}>
            {t('Case.detials.requester')}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <DynamicLink href={`${AppConfig.baseHubUrl}/#/profile/people/${referrer?.contactId}`}>
            <Typography variant="body1" color="primary.main" mb={2}>
              {NameUtils.makeName(
                referrer?.lastName,
                referrer?.firstName,
                referrer?.salutation,
                null,
                null,
                null
              )}
            </Typography>
          </DynamicLink>
        </Grid>
        <Grid item xs={2} md={4}>
          <Stack direction="row" columnGap={1}>
            <EconsultCaseFlagSelector />
            {isPdfEnabled && (
              <IconContainer
                title="Export to PDF"
                onClick={exportToPdf}
                isActive
                sx={{ padding: 0 }}>
                <img src={PDFIconWhite} alt="Export to PDF" />
              </IconContainer>
            )}
            {isEmrEnabled && (
              <IconContainer
                title="Export case to EMR"
                onClick={() => setIsEmrDialogOpen(true)}
                isActive={!emrButtonDisabled}>
                <SvgIcon component={EmrIcon} inheritViewBox />
              </IconContainer>
            )}
          </Stack>
        </Grid>
        {participant?.consultant && (
          <>
            {participant?.program && (
              <>
                <Grid item xs={12} md={2}>
                  <Typography variant="body1" color="base.grey4" mb={isMobile ? 0 : 2}>
                    {t('Case.detials.group')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="body1" color="primary.main" mb={2}>
                    {participant?.program?.programName}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12} md={2}>
              <Typography variant="body1" color="base.grey4" mb={isMobile ? 0 : 2}>
                {t('Case.detials.specialist')}
              </Typography>
            </Grid>
            <Grid item xs={8} md={4}>
              <DynamicLink
                href={`${AppConfig.baseHubUrl}/#/people/profile/${participant?.consultant?.contactId}`}>
                <Typography variant="body1" color="primary.main" mb={2}>
                  {NameUtils.makeName(
                    participant?.consultant?.lastName,
                    participant?.consultant?.firstName,
                    participant?.consultant?.salutation,
                    null,
                    null,
                    null
                  )}
                </Typography>
              </DynamicLink>
            </Grid>
            <Grid xs={4} md={6} />
          </>
        )}
        <Grid item xs={12} md={2}>
          <Typography variant="body1" color="base.grey4" mb={isMobile ? 0 : 2}>
            {t('Case.detials.patient')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Stack direction="row">
            <LinkButton
              variant="body1"
              color="primary.main"
              mb={isMobile ? 0 : 2}
              onClick={() => setIsPatientDetailsShown(!isPatientDetailsShown)}>
              {patient?.firstName === '***'
                ? '*****'
                : NameUtils.makeName(patient?.lastName, patient?.firstName, null, null, null, null)}
            </LinkButton>
            <Typography>{getPatientGender()}</Typography>
            <Typography>{getPatientAge()}</Typography>
            {caseDetails?.patient?.noOhip && !isMobile && (
              <Typography color="error" ml={1} variant="body1b">
                ({t('Case.consult.ohipNotAvailable')})
              </Typography>
            )}
          </Stack>
          {caseDetails?.patient?.noOhip && isMobile && (
            <Stack mb={2}>
              <Typography color="error" ml={1} variant="body1b">
                ({t('Case.consult.ohipNotAvailable')})
              </Typography>
            </Stack>
          )}
        </Grid>
        {isPatientDetailsShown && (
          <>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={10}>
              <Box mb={2}>
                <EconsultPatientDetailsContainer />
              </Box>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={2}>
          <Typography variant="body1" color="base.grey4" mb={2}>
            {t('Case.detials.caseId')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body1" color="base.grey3" mb={2}>
            {caseId}
          </Typography>
        </Grid>
      </Grid>
      <EconsultExportEmrDialog
        isOpen={isEmrDialogOpen}
        onClose={() => setIsEmrDialogOpen(false)}
        onConfirm={exportToEmr}
      />
    </>
  );
};

export default EconsultCaseHeader;
