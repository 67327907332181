import { MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import { EconsultUser, NameUtils } from '@oh-vcp/components-common';
import React, { FC } from 'react';

const SelectContainer = styled(Select)({
  height: 25,
  width: '100%'
});

interface EconsultDelegatorSelectorProps {
  userId: string;
  onUserSelected: (userId: string) => void;
  options: EconsultUser[];
}

const EconsultDelegatorSelector: FC<EconsultDelegatorSelectorProps> = ({
  userId,
  onUserSelected,
  options
}) => {
  const handleChange = (e: SelectChangeEvent<unknown>) => {
    onUserSelected(e.target.value as string);
  };
  if (options.length <= 1) {
    return null;
  }
  return (
    <SelectContainer value={userId} onChange={handleChange}>
      {options.map((o) => (
        <MenuItem value={o.userId} key={o.userId}>
          {NameUtils.makeName(o.lastName || '', o.firstName, o.salutation, o.specialty, null, null)}
        </MenuItem>
      ))}
    </SelectContainer>
  );
};

export default EconsultDelegatorSelector;
