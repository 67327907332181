import {
  AssignerPrograms,
  CaseResultDetails,
  EconsultUserRoles,
  NoteTypes,
  UserDTO
} from '@oh-vcp/components-common';

const isEconsultDelegateOfConsultantOnlyInternal = (user: UserDTO) => {
  const { services } = user;
  const { roles } = user;
  return (
    services &&
    services.econsult &&
    roles?.econCaseDelegateInherit &&
    !roles?.econCaseConsultOnAsDelegate &&
    !roles?.econManagedCaseConsultOnAsDelegate
  );
};

const EconsultUserService = {
  hasTriage: (caseResults: CaseResultDetails[]) => {
    const triageCase = caseResults.find((c) => c.userRole === NoteTypes.triageAdmin);
    if (triageCase) return true;
    return false;
  },
  hasTriageAssigner: (caseResults: CaseResultDetails[], assignerPrograms: AssignerPrograms) => {
    const triageCase = caseResults.find((c) => c.userRole === NoteTypes.triageAdmin);
    if (!triageCase) {
      return false;
    }
    const isInProgram = (programId: number) => {
      const program = assignerPrograms?.triageAssigner?.find((id: number) => id === programId);
      if (program) return true;
      return false;
    };
    const triageAssignerCase = caseResults.find((c) => isInProgram(c.programId));
    if (triageAssignerCase) return true;
    return false;
  },
  hasRefs: (caseResults: CaseResultDetails[]) => {
    const refCases = caseResults.find(
      (c) =>
        c.userRole === EconsultUserRoles.referrer ||
        c.userRole === EconsultUserRoles.referrerDelegate
    );
    if (refCases) return true;
    return false;
  },
  hasCons: (caseResults: CaseResultDetails[]) => {
    const consultCases = caseResults.find(
      (c) =>
        c.userRole === EconsultUserRoles.consultant ||
        c.userRole === EconsultUserRoles.consultantDelegate
    );
    if (consultCases) return true;
    return false;
  },
  isEconsultReferrer: (user: UserDTO) => {
    const { services } = user;
    const { roles } = user;
    return (
      services &&
      services.econsult &&
      (roles?.econManagedCaseCreateOn || roles?.econCaseCreate) &&
      !isEconsultDelegateOfConsultantOnlyInternal(user)
    );
  },
  isEconsultConsultant: (user: UserDTO) => {
    const { services } = user;
    const { roles } = user;
    return (
      services &&
      services.econsult &&
      (roles?.econManagedCaseConsultOn || roles?.econCaseConsultOn) &&
      !isEconsultDelegateOfConsultantOnlyInternal(user)
    );
  },
  isEconsultDelegate: (user: UserDTO) => {
    return user && user.roles && user.roles.econCaseDelegateInherit === true;
  }
};

export default EconsultUserService;
