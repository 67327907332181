import { SortType } from '../types/SortType';

const Score: SortType = {
  paramName: 'score',
  labelKey: 'Search.bestMatch'
};

const Proximity: SortType = {
  paramName: 'proximity',
  labelKey: 'Search.proximity'
};

const SortTypes = {
  Proximity,
  Score
};

export default SortTypes;
