import React, { FC, useState, MouseEvent } from 'react';
import { MenuItem, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ActionButton, ChatSvgIcon } from '@oh-vcp/components-ui';
import EconsultApplyForProgPermissionDialog from '../econsultApplyForProgPermissionDialog/EconsultApplyForProgPermissionDialog';
import useApplyForProgEconPermission from '../hooks/useApplyForProgEconPermission';

interface ApplyForProgEconPermissionActionProps {
  programId: string;
  programName: string;
  translationKey: string;
  actionType: 'menu' | 'toolbar';
}

const ApplyForProgEconPermissionAction: FC<ApplyForProgEconPermissionActionProps> = ({
  programId,
  programName,
  translationKey,
  actionType
}) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const applyForProgEconPermission = useApplyForProgEconPermission(programId.toString());

  const handleApply = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsDialogOpen(false);
    applyForProgEconPermission();
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsDialogOpen(false);
    e.stopPropagation();
  };

  const handleOnClick = (event: MouseEvent<any>) => {
    event.stopPropagation();
    setIsDialogOpen(true);
  };

  const ActionComponent = actionType === 'toolbar' ? ActionButton : MenuItem;

  return (
    <>
      <ActionComponent onClick={handleOnClick}>
        <SvgIcon
          component={ChatSvgIcon}
          sx={{ mt: 1, fill: actionType === 'toolbar' ? 'primary.main' : 'base.grey1' }}
        />
        <Typography variant={actionType === 'toolbar' ? 'h4' : undefined}>
          {t(translationKey)}
        </Typography>
      </ActionComponent>
      <EconsultApplyForProgPermissionDialog
        isDialogOpen={isDialogOpen}
        programName={programName}
        onActionClicked={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleApply(e)}
        onCancelClicked={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleCancel(e)}
      />
    </>
  );
};

export default ApplyForProgEconPermissionAction;
