import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ProcessInProgress } from '@oh-vcp/components-ui';
import { useAuth } from './AuthContext';

const Login = () => {
  const { t } = useTranslation();
  const { login } = useAuth();
  login(false);

  return <ProcessInProgress title={t('loginInProgress')} />;
};
export default Login;
