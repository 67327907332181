export const AppConfig = {
  apiHost: `${window.ENV.API_HOST}/`,
  logLevel: window.ENV.LOG_LEVEL,
  googleAPIKey: window.ENV.GOOGLE_API_KEY,
  healthlineUrl: window.ENV.HEALTHLINE_URL,
  contactUsUrl: window.ENV.CONTACT_US_URL,
  privacyUrl: window.ENV.PRIVACY_URL,
  helpUrl: window.ENV.HELP_URL,
  gaTrackingId: window.ENV.GA_TRACKING_ID,
  feedbackUrl: window.ENV.FEEDBACK_URL,
  baseHubUrl: window.ENV.BASE_HUB_URL,
  baseEconUrl: window.ENV.BASE_ECON_URL,
  baseSelfServeUrl: window.ENV.BASE_SELF_SERVE_URL
};
