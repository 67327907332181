import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProcessInProgress } from '@oh-vcp/components-ui';
import { useAuth } from './AuthContext';

const Logout = () => {
  const { logout } = useAuth();
  const { t } = useTranslation();
  logout();

  return <ProcessInProgress title={t('logoutInProgress')} />;
};

export default Logout;
