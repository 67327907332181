import axios, { AxiosRequestConfig } from 'axios';
import { AppConfig, storageUtils, Logger } from '@oh-vcp/components-common';
import { doLogout, refreshToken } from './openidconnectService';

const logger = new Logger({ moduleName: 'apiService' });

const apiService = axios.create({
  baseURL: AppConfig.apiHost,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json'
  }
});

apiService.interceptors.request.use(
  (config) => {
    logger.info('intercept request: ', config);
    const token = storageUtils.getUserToken();
    if (token) {
      logger.info('PRIVATE');
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      logger.info('PUBLIC');
    }
    return config;
  },
  (error) => {
    const { response } = error;
    logger.info('intercept ERROR: ', response);
    return Promise.reject(error);
  }
);

apiService.interceptors.response.use(
  (response) => {
    logger.info('intercept response', response);
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    logger.error('originalRequest: ', error.config);

    if (!originalRequest) {
      return Promise.reject(error);
    }

    if (error?.response?.status === 401) {
      logger.error('401: Unauthorized');
      return Promise.resolve(refreshTokenRetry(error.config));
    }

    // Handle 403: Forbidden
    if (error?.response?.status === 403) {
      // Navigate the user to a "Permission Denied" page or show a relevant message.
      logger.error('403 Permission Denied', error.config.url);
      doLogout();
      // const redirectUri = `${
      //   window.location.origin
      // }/logout?appState=403&originalUri=${encodeURIComponent(window.location.href)}`;
      // logger.info('intercept response redirectUri', redirectUri);
      // window.location.href = redirectUri;
      return Promise.resolve(true);
    }
    // Handle 404: Not Found
    // No special handling just reject it

    return Promise.reject(error);
  }
);

export const refreshTokenRetry = async (originalRequest: AxiosRequestConfig) => {
  logger.info('refreshTokenRetry invoked', originalRequest);
  try {
    const newUserToken = await refreshToken();
    apiService.defaults.headers.Authorization = `Bearer ${newUserToken}`;
    return await apiService(originalRequest);
  } catch (refreshError) {
    return Promise.reject(refreshError);
  }
};

export async function fetchList<T>(url: string): Promise<T[]> {
  const response = await apiService.get(url);
  logger.info('fetchList response: ', response.data);
  return response.data;
}

export async function apiGet<T>(
  url: string,
  params?: Record<string, any>,
  config?: AxiosRequestConfig | null
): Promise<T> {
  const response = await apiService.get(url, { ...config, params });
  logger.info('apiGet response: ', response.data);
  return response.data;
}

export async function apiPost<T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig | null
): Promise<T> {
  const response = await apiService.post(url, data, { ...config });
  logger.info('apiPost response: ', response.data);
  return response.data;
}

export async function apiGetRaw(
  url: string,
  params?: Record<string, any>,
  config?: AxiosRequestConfig | null
) {
  const response = await apiService.get(url, { ...config, params });
  logger.info('apiGetRaw response: ', response);
  return response;
}
export default apiService;
