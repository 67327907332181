import { ConsultTab } from '@oh-vcp/components-common';
import React, { FC, PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

interface EconsultTabContextProps {
  selectedTab: ConsultTab | undefined;
  setSelectedTab: (tab: ConsultTab | undefined) => void;
}

export const EconsultTabContext = createContext<EconsultTabContextProps | undefined>(undefined);

export const useEconsultTabContext = () => {
  const value = useContext(EconsultTabContext);
  if (!value) {
    throw new Error(
      'no value found for useEconsultTabContext, did you forget to wrap it in a provider?'
    );
  }
  return value;
};

export const EconsultTabContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState<ConsultTab | undefined>(undefined);
  const value = useMemo(() => {
    return {
      selectedTab,
      setSelectedTab
    };
  }, [selectedTab]);

  return <EconsultTabContext.Provider value={value}>{children}</EconsultTabContext.Provider>;
};
