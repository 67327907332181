import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  InputAdornment,
  darken,
  lighten,
  styled
} from '@mui/material';

import {
  BaseEntity,
  CommunityServicesEntity,
  FhoEntity,
  PeopleEntity,
  ProgramEntity
} from '@oh-vcp/components-common';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SiteEntity } from '@oh-vcp/components-common/src/models/site/SiteEntity';
import {
  TextFieldNoOutline,
  SearchBarIcon,
  AllSearchOption,
  SearchSVGIcon,
  useSearchParamContext
} from '@oh-vcp/components-ui';
import SearchSuggestionPopper from '../searchSuggestionPopper/SearchSuggestionPopper';
import CommunityServicesListHeaderCard from '../listHeaderCards/CommunityServicesListHeaderCard';
import FhoListHeaderCard from '../listHeaderCards/FhoListHeaderCard';
import PeopleListHeaderCard from '../listHeaderCards/PeopleListHeaderCard';
import ProgramListHeaderCard from '../listHeaderCards/ProgramListHeaderCard';
import SiteListHeaderCard from '../listHeaderCards/SiteListHeaderCard';

export type SearchBarWithSuggestProps = {
  id: string;
  placeholder: string;
  value: string | null;
  onEnter: () => void;
  onSelect: (selectedObj: BaseEntity | string | null) => void;
  onInputChange: (inputValue: string) => void;
  options: BaseEntity[];
  anchorEl?: HTMLDivElement | null;
};

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8)
}));

const GroupItems = styled('ul')({
  padding: 0
});

/**
 * flag that used to decide to show options grouped or not
 */
const isRenderGroup: boolean = false;

const SearchBarWithSuggest: React.FC<SearchBarWithSuggestProps> = ({
  id,
  placeholder,
  onEnter,
  onSelect,
  onInputChange,
  options,
  value,
  anchorEl
}) => {
  const [aValue, setAValue] = useState<BaseEntity | null | string>(value);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setSearchKeyword } = useSearchParamContext();

  const getListHeader = (option: BaseEntity, highlightText?: string) => {
    switch (option.type) {
      case 'contacts':
        return (
          <PeopleListHeaderCard
            people={option as PeopleEntity}
            highlightText={highlightText}
            isCompact
          />
        );
      case 'programs':
        return (
          <ProgramListHeaderCard
            program={option as ProgramEntity}
            highlightText={highlightText}
            isCompact
          />
        );
      case 'site_with_systems':
        return (
          <SiteListHeaderCard site={option as SiteEntity} highlightText={highlightText} isCompact />
        );
      case 'community_services':
        return (
          <CommunityServicesListHeaderCard
            communityService={option as CommunityServicesEntity}
            highlightText={highlightText}
            isCompact
          />
        );
      case 'hs_services':
        return (
          <FhoListHeaderCard fho={option as FhoEntity} highlightText={highlightText} isCompact />
        );
      default:
        return undefined;
    }
  };

  const renderOptions = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: BaseEntity | string,
    state: any
  ) => {
    if (typeof option === 'string') {
      return undefined;
    }
    return <li {...props}>{getListHeader(option, state?.inputValue)}</li>;
  };

  const renderGroup = isRenderGroup
    ? (params: AutocompleteRenderGroupParams) => (
        <li key={params.key}>
          <GroupHeader>{t(`SearchSuggestion.${params.group}`)}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )
    : undefined;
  const groupBy = isRenderGroup
    ? (option: BaseEntity | string) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.type;
      }
    : undefined;

  const getOptionLabel = (option: BaseEntity | string) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.name;
  };

  const getOptionKey = (option: BaseEntity | string) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.id || '';
  };

  const navigateToProfile = (profileType: string, profileId: string) => {
    if (typeof aValue === 'string') {
      setSearchKeyword(aValue);
    }
    let url = '';
    switch (profileType) {
      case 'contacts':
        url = `/profile/people/${profileId}`;
        break;
      case 'programs':
        url = `/program/${profileId}`;
        break;
      case 'site_with_systems':
        url = `/profile/places/${profileId}`;
        break;
      case 'community_services':
        url = `/communityservices/${profileId}`;
        break;
      default:
        break;
    }
    navigate(url);
  };

  useEffect(() => {
    setAValue(value);
  }, [value]);

  return (
    <Autocomplete
      id={id}
      options={options}
      getOptionKey={getOptionKey}
      getOptionLabel={getOptionLabel}
      componentsProps={{ popper: { anchorEl, open: Boolean(anchorEl) } }}
      PopperComponent={SearchSuggestionPopper}
      freeSolo
      groupBy={groupBy}
      filterOptions={(o) => o}
      value={aValue}
      onChange={(event: React.SyntheticEvent, newValue: BaseEntity | null | string) => {
        console.log('onChange newValue', newValue);
        // setAValue(newValue);
        // onSelect(newValue);
        if (newValue && typeof newValue !== 'string') {
          const newValueEntity = newValue as BaseEntity;
          navigateToProfile(newValueEntity.type, newValueEntity.id.toString());
        }
      }}
      onInputChange={(event, newInputValue) => {
        onInputChange(newInputValue);
      }}
      renderGroup={renderGroup}
      renderOption={renderOptions}
      renderInput={(props) => (
        <TextFieldNoOutline
          {...props}
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onEnter();
            }
          }}
          InputProps={{
            ...props.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchBarIcon>{aValue ? <AllSearchOption /> : <SearchSVGIcon />}</SearchBarIcon>
              </InputAdornment>
            )
          }}
        />
      )}
    />
    // </>
  );
};

export default SearchBarWithSuggest;
