import * as React from 'react';
import { useMapsContext } from '@oh-vcp/components-common';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { InputAdornment } from '@mui/material';
import { LocationDotLight, LocationDotLightActive } from '../icons';
import { TextFieldNoOutline } from '../StyledComponents';

const autocompleteService = { current: null };
const placesService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
}
export type PlaceValue = {
  place_id: string;
  place_desc: string;
  lat: string;
  lng: string;
};
export type AutocompletePlacesProps = {
  onSelect: (params: PlaceValue) => void;
  placeValue?: PlaceValue;
  placeholder: string;
};
const AutocompletePlaces: React.FC<AutocompletePlacesProps> = ({
  onSelect,
  placeValue,
  placeholder
}) => {
  // console.log('AutocompletePlaces render');
  // const [value, setValue] = React.useState<PlaceType | null>(null);
  const value = placeValue ? placeValue.place_desc : '';
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  const { isMapsLoaded } = useMapsContext();

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: {
            input: string;
            componentRestrictions: {
              country: string;
            };
          },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(request, callback);
        },
        400
      ),
    []
  );

  React.useEffect(() => {
    if (isMapsLoaded && !autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
      placesService.current = new (window as any).google.maps.places.PlacesService(
        document.createElement('div')
      );
    }
  }, [isMapsLoaded]);

  React.useEffect(() => {
    let active = true;
    fetch(
      {
        input: inputValue,
        componentRestrictions: {
          country: 'ca'
        }
      },
      (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = [];
          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      freeSolo
      // noOptionsText="No locations"
      onChange={(event: any, newValue: PlaceType | null) => {
        console.log('AutocompletePlaces onChange newValue', newValue);
        // console.log('onChange options', ...options);
        // setOptions(newValue ? [newValue, ...options] : options);
        if (newValue != null) {
          (placesService.current as any).getDetails(
            { placeId: newValue.place_id },
            (result, status) => {
              console.log('AutocompletePlaces onChange getDetails', result, status);
              onSelect({
                place_id: newValue.place_id,
                place_desc: newValue.description,
                lat: result.geometry.location.lat(),
                lng: result.geometry.location.lng()
              });
            }
          );
        } else {
          onSelect(undefined);
        }
      }}
      onInputChange={(event, newInputValue) => {
        console.log(`AutocompletePlaces onInputChange newInputValue "${newInputValue}"`);
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextFieldNoOutline
          {...params}
          placeholder={placeholder}
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                {inputValue ? <LocationDotLightActive /> : <LocationDotLight />}
              </InputAdornment>
            )
          }}
        />
      )}
      renderOption={(props, option) => {
        // console.log('renderOption props', props);
        // console.log('renderOption option', option);
        const matches = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length])
        );
        // console.log('renderOption parts', parts);
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 20 }}>
                <LocationDotLightActive sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 20px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Typography
                    key={index}
                    component="span"
                    sx={{
                      fontWeight: part.highlight ? 'bold' : 'regular',
                      color: part.highlight ? 'primary.main' : ''
                    }}>
                    {part.text}
                  </Typography>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default AutocompletePlaces;
