import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { apiService } from '@oh-vcp/components-web';
import { ProgramProfile } from '@oh-vcp/components-common';

const fetchPrograms = async (programId: number): Promise<ProgramProfile> => {
  let url = `programs/${programId}`;
  const response = await apiService.get(url);
  return response.data;
};

const useProgramLookup = (programId: number | undefined | null) => {
  return useQuery<ProgramProfile, AxiosError>(
    ['programLookup', programId],
    () => fetchPrograms(programId!),
    {
      enabled: !!programId,
      refetchOnWindowFocus: false
    }
  );
};

export default useProgramLookup;
