import { AxiosRequestConfig } from 'axios';
import { AppConfig, logUtils, urlUtils, storageUtils } from '@oh-vcp/components-common';
import { apiGet } from './apiService';

export const redirectToLogin = (searchParams: URLSearchParams) => {
  logUtils.logger.info('redirectToLogin invoked');
  const rootUrl = AppConfig.apiHost;
  const redirectUri = `${window.location.origin}/login_callback`;
  const appState: string | undefined = searchParams.get('appState') ?? undefined;

  // Note: To test this, the redirect URI must be https. Start the server with: "set HTTPS=true&&npm start"
  if (!urlUtils.isHttps(redirectUri)) {
    throw new Error('Auth cannot work if redirectUri is not HTTPS');
  }
  if (!rootUrl) {
    throw new Error('Login URL not defined. Check environment variables.');
  }

  logUtils.logger.info('appState: ', appState);

  const redirectUriEnc = encodeURIComponent(redirectUri);

  const loginUrl: string = `${rootUrl}openidconnect/login?redirectUri=${redirectUriEnc}`;

  logUtils.logger.info(`Redirecting to loginUrl: ${loginUrl}`);
  window.location.href = loginUrl;
};

export const redirectToLogout = (userToken: string) => {
  logUtils.logger.info('redirectToLogout invoked');
  const rootUrl = AppConfig.apiHost;
  const redirectUri = `${window.location.origin}/`;
  // const appState: string | undefined = searchParams.get('appState') ?? undefined;

  // Note: To test this, the redirect URI must be https. Start the server with: "set HTTPS=true&&npm start"
  if (!urlUtils.isHttps(redirectUri)) {
    throw new Error('Auth cannot work if redirectUri is not HTTPS');
  }
  if (!rootUrl) {
    throw new Error('Login URL not defined. Check environment variables.');
  }

  // logUtils.logger.info('appState: ', appState);

  const redirectUriEnc = encodeURIComponent(redirectUri);

  const logoutUrl: string = `${rootUrl}openidconnect/user/logout?logout_idp=true&post_logout_uri=${redirectUriEnc}&access_token=${userToken}&isSessionExpire=true`;

  logUtils.logger.info(`Redirecting to logoutUrl: ${logoutUrl}`);
  window.location.href = logoutUrl;
};

export const doLogout = () => {
  logUtils.logger.info('doLogout invoked');
  const userToken = storageUtils.getUserToken();
  storageUtils.clearUserToken();
  // storageUtils.clearRefreshToken();
  storageUtils.clearOriginalPath();
  redirectToLogout(userToken);
  // const redirectUri = `${window.location.origin}`;
  // window.location.href = redirectUri;
};

export const getUserTokenInfo = async <T>(token: string | null): Promise<T> => {
  logUtils.logger.info('getUserTokenInfo invoked', token);

  const rootUrl = AppConfig.apiHost;
  const tokenValidateUrl: string = `${rootUrl}openidconnect/user/token/info`;
  const customConfig: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  return apiGet<T>(tokenValidateUrl, undefined, customConfig);
};

export const refreshToken = async (): Promise<String> => {
  logUtils.logger.info('refreshToken invoked');
  // storageUtils.clearUserToken();
  // const tokenValidateUrl: string = `${
  //   AppConfig.apiHost
  // }openidconnect/oauth2/token/refreshBearerToken?refresh_token=${storageUtils.getRefreshToken()}`;
  const tokenValidateUrl: string = `${AppConfig.apiHost}openidconnect/oauth2/token/refreshBearerToken`;
  const data = await apiGet<any>(tokenValidateUrl, undefined, {});
  const newUserToken = data.access_token;
  storageUtils.storeUserToken(newUserToken);
  // storageUtils.storeRefreshToken(data.refresh_token);
  return newUserToken;
};
