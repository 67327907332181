import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled
} from '@mui/material';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Kpi } from '@oh-vcp/components-common/src/models/case/CaseDetails';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';

interface EconsultSendDialogProps {
  isOpen: boolean;
  onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onConfirm: (kpi: Kpi) => void;
}

const SendDialogSelect = styled(Select)({
  height: 30
});

const SendDialogInput = {
  height: 30,
  width: '100%'
};

const EconsultSendDialog: FC<EconsultSendDialogProps> = ({ isOpen, onCancel, onConfirm }) => {
  const { t } = useTranslation();
  const { timeOptions } = useEconsultCaseDetailsContext();
  const [timeSpentOnConsult, setTimeSpentOnConsult] = useState<string>('');
  const [minutesSpent, setMinutesSpent] = useState<string>('');
  const [comments, setComments] = useState<string>('');

  useEffect(() => {
    if (isOpen) {
      setTimeSpentOnConsult('');
      setMinutesSpent('');
    }
  }, [isOpen]);

  if (!timeOptions) {
    return null;
  }
  const exactTimeRequired = timeOptions.find(
    ({ label }) => label === timeSpentOnConsult
  )?.exactTimeRequired;
  const handleInputChange = useCallback((setter: React.Dispatch<React.SetStateAction<string>>) => {
    return (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>
    ) => {
      setter(event.target.value as string);
    };
  }, []);
  const handleConfirm = () => {
    const timeOption = timeOptions.find(({ label }) => label === timeSpentOnConsult);
    if (timeOption) {
      onConfirm({
        endRange: timeOption.endRange,
        startRange: timeOption.startRange,
        kpiQuestion: t('Case.consult.timeSpentOnConsult') as string,
        kpiAnswer: minutesSpent || null,
        kpiComment: comments
      });
    }
  };
  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Typography variant="h2" color="base.grey3">
          {t('Case.consult.beforeYouSend')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography>{t('Case.consult.timeSpentOnConsult')}: </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <SendDialogSelect
              onChange={handleInputChange(setTimeSpentOnConsult)}
              displayEmpty
              fullWidth>
              <MenuItem value="">{t('Case.consult.pleaseSelect')}</MenuItem>
              {timeOptions
                .filter(({ typeCd }) => typeCd === 'ORIGINAL')
                .sort((a, b) => a.startRange - b.startRange)
                .map(({ label }) => (
                  <MenuItem value={label} key={label}>
                    {label}
                  </MenuItem>
                ))}
            </SendDialogSelect>
          </Grid>
          {exactTimeRequired && (
            <>
              <Grid item xs={12} md={6} mt={2}>
                <Typography>{t('Case.consult.minutesSpent')}: </Typography>
              </Grid>
              <Grid item xs={12} md={6} mt={2}>
                <input
                  type="number"
                  min={26}
                  max={60}
                  value={minutesSpent}
                  onChange={handleInputChange(setMinutesSpent)}
                  style={SendDialogInput}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} mt={2}>
            <TextField
              value={comments}
              onChange={handleInputChange(setComments)}
              multiline
              rows={5}
              fullWidth
              placeholder={t('Case.consult.enterComments') as string}
            />
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end" pt={3}>
            <Button variant="contained" color="primary" sx={{ mr: 1 }} onClick={handleConfirm}>
              {t('Case.button.ok')}
            </Button>
            <Button variant="contained" color="primary" onClick={onCancel}>
              {t('Case.button.cancel')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EconsultSendDialog;
