/* eslint-disable class-methods-use-this */
import { isEmpty } from 'lodash';
import { ValidationTypes } from './EconsultValidationTypes';
import { ValidationResult } from './ValidationResult';

const isFieldRequired = (requiredFields: Map<string, ValidationTypes>, key: string) => {
  const el = requiredFields.get(key);
  if (el) {
    return el;
  }
  return ValidationTypes.Unknown;
};

const isValidField = (value: any, validationType: ValidationTypes) => {
  // eslint-disable-next-line no-console
  console.log(`isValidField ${value}`);
  if (validationType === ValidationTypes.Unknown) {
    return false;
  }
  if (validationType === ValidationTypes.Text && !isEmpty(value)) {
    return true;
  }
  if (validationType === ValidationTypes.Date && !isEmpty(value)) {
    return true;
  }

  return false;
};
// eslint-disable-next-line prettier/prettier
const isFieldNotValidAndDirty = (dirtyFields: string[], invalidFields: string[], fieldName: string) => {
  if (!dirtyFields) {
    dirtyFields = [];
  }
  if (!invalidFields) {
    invalidFields = [];
  }
  return dirtyFields.indexOf(fieldName) > -1 && invalidFields.indexOf(fieldName) > -1;
};

const removeFromList = (records: string[], toRemove: string) => {
  const updatedList = records.filter((item) => item !== toRemove);
  return updatedList;
};

const addToList = (records: string[], toAdd: string) => {
  if (records.indexOf(toAdd) > -1) {
    return records;
  }
  records.push(toAdd);
  return records;
};

// eslint-disable-next-line prettier/prettier
const handleValidation = (requiredFields: Map<string, ValidationTypes>, dirtyFields: string[], invalidFields: string[],fieldName: string, value: any) => {
  if (!dirtyFields) {
    dirtyFields = [];
  }
  dirtyFields = addToList(dirtyFields, fieldName);

  const validationType: ValidationTypes = isFieldRequired(requiredFields, fieldName);
  const isValid = isValidField(value, validationType);
  if (isValid) {
    invalidFields = removeFromList(invalidFields, fieldName);
  } else {
    if (!invalidFields) {
      invalidFields = [];
    }
    invalidFields = addToList(invalidFields, fieldName);
  }
  const res: ValidationResult = {
    isValid,
    dirtyFields,
    invalidFields
  };

  return res;
};

const ValidatorUtils = {
  isFieldRequired,
  isValidField,
  isFieldNotValidAndDirty,
  handleValidation,
  addToList,
  removeFromList
};
export default ValidatorUtils;
