import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { apiService } from '@oh-vcp/components-web';
import { EconsultManagedSpecialtyProgram } from '@oh-vcp/components-common';

const fetchPrograms = async (
  userId: number,
  specialty: string,
  subSpecialty: string
): Promise<EconsultManagedSpecialtyProgram[]> => {
  let url = `programs/specialty/${specialty}/subspecialty/${subSpecialty}/user/${userId}`;
  const response = await apiService.get(url);
  return response.data;
};

const useManagedSpecialtyProgramLookup = (
  userId: number | undefined | null,
  specialty: string | undefined | null,
  subSpecialty: string | undefined | null
) => {
  return useQuery<EconsultManagedSpecialtyProgram[], AxiosError>(
    ['programLookup', userId, specialty, subSpecialty],
    () => fetchPrograms(userId!, specialty!, subSpecialty!),
    {
      enabled: !!userId && !!specialty && !!subSpecialty,
      refetchOnWindowFocus: false
    }
  );
};

export default useManagedSpecialtyProgramLookup;
