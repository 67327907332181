import React, { FC, useState } from 'react';
import { IconButton } from '@mui/material';
import { CopyIcon } from '../icons';

type CopyToClipboardButtonProps = {
  textToCopy: string;
};
const CopyToClipboardButton: FC<CopyToClipboardButtonProps> = ({ textToCopy }) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsDisabled(true);
  };

  return (
    <IconButton disabled={isDisabled} onClick={copyToClipboard} aria-label="copyToClipboard">
      <CopyIcon />
    </IconButton>
  );
};

export default CopyToClipboardButton;
