import { Stack, styled } from '@mui/material';
import React from 'react';
import EconsultCaseActionPanel from '../econsultCaseActionPanel/EconsultCaseActionPanel';
import EconsultCaseInbox from '../econsultCaseInbox/EconsultCaseInbox';
import EconsultCaseDetailsView from '../econsultCaseDetailsView/EconsultCaseDetailsView';
import {
  EconsultCaseDetailsProvider,
  EconsultCaseResultProvider,
  EconsultTabContextProvider
} from '@oh-vcp/components-web';
import { DisplayDesktop, DisplayMobile } from '@oh-vcp/components-ui';
import { useParams } from 'react-router-dom';
import EconsultReportsView from '../econsultReportsView/EconsultReportsView';

const EconsultDetailsStack = styled(Stack)({
  flexGrow: 1,
  height: 'calc(100vh - 11.8rem)',
  overflowY: 'auto'
});

const EconsultCaseView = () => {
  const { providerType, caseId } = useParams();
  return (
    <EconsultCaseResultProvider>
      <DisplayDesktop>
        <Stack direction="row" flexGrow={1}>
          <EconsultCaseActionPanel />
          <EconsultCaseDetailsProvider>
            <EconsultTabContextProvider>
              {providerType?.toLowerCase() === 'reports' ? (
                <EconsultDetailsStack>
                  <EconsultReportsView />
                </EconsultDetailsStack>
              ) : (
                <>
                  <EconsultCaseInbox />
                  <EconsultDetailsStack>
                    <EconsultCaseDetailsView />
                  </EconsultDetailsStack>
                </>
              )}
            </EconsultTabContextProvider>
          </EconsultCaseDetailsProvider>
        </Stack>
      </DisplayDesktop>
      <DisplayMobile>
        <EconsultCaseDetailsProvider>
          <EconsultTabContextProvider>
            {!providerType && !caseId && <EconsultCaseActionPanel />}
            {providerType && providerType !== 'reports' && !caseId && <EconsultCaseInbox />}
            {providerType && providerType === 'reports' && !caseId && <EconsultReportsView />}
            {providerType && caseId && (
              <EconsultDetailsStack>
                <EconsultCaseDetailsView />
              </EconsultDetailsStack>
            )}
          </EconsultTabContextProvider>
        </EconsultCaseDetailsProvider>
      </DisplayMobile>
    </EconsultCaseResultProvider>
  );
};

export default EconsultCaseView;
