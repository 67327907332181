import React, { FC, useState } from 'react';
import { Box } from '@mui/system';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Stack } from '@mui/material';
import { FlexGrow, ProfileCardFullHeight, ProfilePropertyContainer } from '../StyledComponents';

interface CollapsibleCardProps {
  header?: React.ReactNode;
  children?: React.ReactNode;
}

const CollapsibleCard: FC<CollapsibleCardProps> = ({ header, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleOnToggle = (isToggled: boolean) => {
    setIsOpen(isToggled);
  };
  return (
    <ProfileCardFullHeight>
      <ProfilePropertyContainer>
        <Stack direction="row">
          <FlexGrow>{header}</FlexGrow>
          <Box>
            {isOpen ? (
              <KeyboardDoubleArrowUpIcon onClick={() => handleOnToggle(false)} />
            ) : (
              <KeyboardDoubleArrowDownIcon onClick={() => handleOnToggle(true)} />
            )}
          </Box>
        </Stack>
        {isOpen && children}
      </ProfilePropertyContainer>
    </ProfileCardFullHeight>
  );
};

export default CollapsibleCard;
