import React, { FC } from 'react';
import { Badge, Button, styled, Typography } from '@mui/material';
import { ActionItemParams } from '@oh-vcp/components-ui/src/types/ActionItemParams';
import { useTranslation } from 'react-i18next';
import { ActionMenuItemLabel, ConditionalShow, RedirectService } from '@oh-vcp/components-ui';

interface UpdatesActionProps {
  params: ActionItemParams;
  actionType: 'menu' | '';
}

const UpdatesBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    borderRadius: '20%'
  }
}));

const UpdatesAction: FC<UpdatesActionProps> = ({ params, actionType }) => {
  const { t } = useTranslation();
  let updatesLinkLabel;
  let updatesRedirect = function () {};

  if (params.profileType === 'people') {
    if (params.policy?.isEditSuggestAllowed) {
      // ADMIN
      updatesLinkLabel = t('Profile.viewSuggestedUpdates');
      updatesRedirect = () =>
        RedirectService.redirectToSuggestViewPeopleProfile(params?.policy?.id);
    } else if (params.policy?.isSuggestAllowed) {
      // REGULAR USER
      updatesLinkLabel = t('Profile.suggestAnUpdate');
      updatesRedirect = () =>
        RedirectService.redirectToSuggestEditPeopleProfile(params?.policy?.id);
    }
  } else if (params.profileType === 'community' && params.updateLink) {
    updatesLinkLabel = t('Profile.suggestAnUpdate');
    updatesRedirect = () => {
      window.open(params.updateLink);
    };
  } else if (params.profileType === 'site') {
    if (params.policy?.isEditSuggestAllowed) {
      updatesLinkLabel = t('Profile.viewSuggestedUpdates');
      updatesRedirect = () => RedirectService.redirectToSuggestViewSiteProfile(params?.policy?.id);
    } else if (params.policy?.isSuggestAllowed) {
      updatesLinkLabel = t('Profile.suggestAnUpdate');
      updatesRedirect = () => RedirectService.redirectToSuggestEditSiteProfile(params?.policy?.id);
    }
  }

  const handleOnClick = (event: any) => {
    event.stopPropagation();
    updatesRedirect();
  };

  if (updatesLinkLabel) {
    if (actionType === 'menu' && !!updatesLinkLabel) {
      return (
        <ActionMenuItemLabel onClick={handleOnClick}>
          {updatesLinkLabel}
          {params?.policy?.isEditSuggestAllowed && params.suggestUpdatePendingCount && (
            <UpdatesBadge
              badgeContent={params.suggestUpdatePendingCount}
              color="error"
              sx={{ ml: 2 }}
            />
          )}
        </ActionMenuItemLabel>
      );
    }

    return (
      <Button sx={{ marginBottom: '0.35rem', padding: 0 }} onClick={handleOnClick}>
        <Typography variant="body4" sx={{ textDecoration: 'underline' }}>
          {updatesLinkLabel}
        </Typography>
        <ConditionalShow
          showWhen={
            !!(
              params?.policy?.isEditSuggestAllowed &&
              params?.suggestUpdatePendingCount &&
              params?.suggestUpdatePendingCount > 0
            )
          }>
          <UpdatesBadge
            badgeContent={params.suggestUpdatePendingCount}
            color="error"
            sx={{ ml: 2 }}
          />
        </ConditionalShow>
      </Button>
    );
  }
};

export default UpdatesAction;
