import { Paper } from '@mui/material';
import { styled } from '@mui/system';

export const SimpleCard = styled('div')(({ theme }) => ({
  padding: '20px',
  margin: '10px 0',
  border: '1px solid',
  boxShadow: `3px 3px 3px ${theme.palette.primary.main}`,
  borderColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius * 5
}));

export const GridItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
  boxShadow: theme.shadows[3]
}));
