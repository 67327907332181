import { useNotifierContext } from '@oh-vcp/components-ui';
import { applyForEconPermission } from '../services/programsService';
import { NotificationFactory } from '../notifier/NotificationFactory';
import NotificationsConfig from '../notifier/NotificationsConfig';

const useApplyForProgEconPermission = (id: string) => {
  const { addNotification } = useNotifierContext();

  return async () => {
    try {
      await applyForEconPermission(id);
      addNotification(
        NotificationFactory.createNotification(
          'success',
          NotificationsConfig.econsultApplyForPermission.success
        )
      );
    } catch (err) {
      addNotification(
        NotificationFactory.createNotification(
          'error',
          NotificationsConfig.econsultApplyForPermission.failed
        )
      );
    }
  };
};

export default useApplyForProgEconPermission;
