import { Button, Stack, styled, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchSVGIcon } from '@oh-vcp/components-ui';
import { useNavigate } from 'react-router-dom';

const SearchTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: 0
  }
});

const SearchButton = styled(Button)({
  borderRadius: 0
});

const EconsultCaseSearchBox = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState<string>('');
  const handleKeywordChanged = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setKeyword(event.target.value);
  };
  const handleCaseSearch = () => {
    if (keyword && keyword.length > 0) {
      navigate(`/case/search/${keyword}`);
    }
  };
  return (
    <Stack direction="row">
      <SearchTextField
        fullWidth
        type="search"
        placeholder={t('Case.search.searchForCases') as string}
        value={keyword}
        onChange={handleKeywordChanged}
      />
      <SearchButton variant="contained" onClick={handleCaseSearch}>
        <SearchSVGIcon />
      </SearchButton>
    </Stack>
  );
};

export default EconsultCaseSearchBox;
