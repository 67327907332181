export const NoteActionTypes = {
  send: 'SEND',
  sendDraft: 'SEND-DRAFT',
  writeConsult: 'WRITECONSULT',
  askForMoreInfo: 'ASKFORMOREINFO',
  askForMoreInfoDraft: 'ASKFORMOREINFO-DRAFT',
  decline: 'DECLINE',
  reply: 'REPLY',
  finish: 'FINISH',
  reopen: 'REOPEN',
  recomplete: 'RECOMPLETE',
  cancel: 'CANCEL',
  forward: 'FORWARD',
  addNote: 'ADDNOTE',
  assign: 'ASSIGN',
  unassign: 'UNASSIGN',
  draft: 'DRAFT',
  addNoteDraft: 'ADDNOTE-DRAFT',
  assignDraft: 'ASSIGN-DRAFT'
};
