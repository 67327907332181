import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { ToolbarActionItem } from '@oh-vcp/components-common';
import { ActionItemParams } from '@oh-vcp/components-ui/src/types/ActionItemParams';
import ProfileActionMenu from '../profileActionMenu/ProfileActionMenu';
import PrintAction from '../actions/PrintAction';
import EconsultAction from '../actions/EconsultAction';
import ApplyForProgEconPermissionAction from '../actions/ApplyForProgEconPermissionAction';
import EditAction from '../actions/EditAction';

interface ProfileActionToolbarProps {
  toolbarActionItems: ToolbarActionItem[];
  params: ActionItemParams;
  numItemsToPin: number;
}

const ProfileActionToolbar: FC<ProfileActionToolbarProps> = ({
  toolbarActionItems,
  params,
  numItemsToPin
}) => {
  const pinnedItems = toolbarActionItems.slice(0, numItemsToPin);
  const unpinnedItems = toolbarActionItems.slice(numItemsToPin);

  return (
    <Stack direction="row" sx={{ columnGap: 2 }}>
      {
        // check if there are any unpinned items before rendering the ActionButton ellipsis
        (unpinnedItems.length && (
          <ProfileActionMenu params={params} toolbarActionItems={unpinnedItems} menuType="action" />
        )) ||
          null
      }
      {pinnedItems.map((actionItem) => {
        const { translationKey, name } = actionItem;
        switch (name) {
          case 'print':
            return <PrintAction translationKey={translationKey} actionType="toolbar" />;
          case 'econsult':
            return (
              <EconsultAction
                params={params}
                translationKey={translationKey}
                actionType="toolbar"
              />
            );
          case 'econsultApplyForPermission':
            return (
              <ApplyForProgEconPermissionAction
                programId={params.programId || ''}
                translationKey={translationKey}
                actionType="toolbar"
                programName={params.programName || ''}
              />
            );
          case 'edit':
            return (
              <EditAction params={params} translationKey={translationKey} actionType="toolbar" />
            );
          default:
            return null;
        }
      })}
    </Stack>
  );
};

export default ProfileActionToolbar;
