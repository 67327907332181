import React, { ChangeEvent, FC, useState } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Stack, TextField } from '@mui/material';

import { useTranslation } from 'react-i18next';
import {
  EconsultProviderTypes,
  NoteActionTypes,
  NoteStatusCodes,
  NoteTypes
} from '@oh-vcp/components-common';
import {
  CaseAttachment,
  CaseDetails,
  Kpi
} from '@oh-vcp/components-common/src/models/case/CaseDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { useNotifierContext } from '@oh-vcp/components-ui';
import moment from 'moment';
import DragDropFileUpload from '../dragDropFileUpload/DragDropFileUpload';
import EconsultDraftCaseActions from '../econsultDraftCaseActions/EconsultDraftCaseActions';
import EconsultSendDialog from '../econsultSendDialog/EconsultSendDialog';
import CaseService from '../services/caseService';
import { useAuth } from '../auth/AuthContext';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';
import EconsultAttachmentListing from '../econsultAttachmentListing/EconsultAttachmentListing';
import { NotificationFactory } from '../notifier/NotificationFactory';
import NotificationsConfig from '../notifier/NotificationsConfig';

interface EconsultCaseProvideConsultProps {
  showRecommendation?: boolean;
  noteFieldLabel?: string;
  actionType: (typeof NoteActionTypes)[keyof typeof NoteActionTypes];
}

const EconsultCaseProvideConsult: FC<EconsultCaseProvideConsultProps> = ({
  showRecommendation,
  noteFieldLabel,
  actionType
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotification } = useNotifierContext();
  const { caseDetails, setCaseDetails } = useEconsultCaseDetailsContext();
  const { providerType } = useParams();
  const [isSendDialogOpen, setIsSendDialogOpen] = useState<boolean>(false);
  const [isRecommendedToBeSeen, setIsRecommendedToBeSeen] = useState<boolean>(false);
  const [noteContent, setNoteContent] = useState<string>('');
  const { user } = useAuth();

  if (!user || !caseDetails) {
    return null;
  }

  const getNotificationMessage = (success: boolean) => {
    let message = { message: '' };
    switch (actionType) {
      case NoteActionTypes.writeConsult:
        message = success
          ? NotificationsConfig.econsultProvideConsult.success
          : NotificationsConfig.econsultProvideConsult.failed;
        break;
      default:
        message = success
          ? NotificationsConfig.econsultGeneral.success
          : NotificationsConfig.econsultGeneral.failed;
        break;
    }

    return message;
  };

  const handleSaved = async (kpi?: Kpi) => {
    const note = await CaseService.upsertCaseNote({
      ownerId: user.userId as number,
      noteTypeCd:
        actionType === NoteActionTypes.reply || actionType === NoteActionTypes.reopen
          ? NoteTypes.referrer
          : NoteTypes.consultant,
      actionTypeCd: actionType,
      caseId: caseDetails.caseId,
      statusCd: NoteStatusCodes.active,
      kpis: kpi ? [kpi] : undefined,
      noteContent
    });
    if (actionType !== NoteActionTypes.addNote) {
      const updatedCaseDetails = {
        ...caseDetails,
        statusCd: NoteStatusCodes.active,
        notes: [
          {
            ...note,
            patientToBeSeen: isRecommendedToBeSeen,
            kpis: note.kpis?.map(({ kpiId, caseId, ...rest }) => rest)
          }
        ]
      };
      setCaseDetails(updatedCaseDetails);
      await CaseService.upsertCaseDetails(updatedCaseDetails);
    }
    setIsSendDialogOpen(false);
    addNotification(
      NotificationFactory.createNotification('success', getNotificationMessage(true))
    );
    navigate('/case/consultant/needsaction');
  };
  const handleDraftSaved = () => {};
  const handleSend = () => {
    if (providerType === EconsultProviderTypes.consultant) {
      setIsSendDialogOpen(true);
    } else {
      handleSaved(undefined);
    }
  };
  const handleCancel = () => {
    setIsSendDialogOpen(false);
  };
  const handleAddNoteAttachment = async (fileId: number, fileName: string) => {
    if (!caseDetails.notes || caseDetails.notes.length === 0) return;
    const cloneCaseDetails: CaseDetails | undefined = JSON.parse(JSON.stringify(caseDetails));
    if (!cloneCaseDetails || !cloneCaseDetails.notes || cloneCaseDetails.notes.length === 0) return;
    const note = cloneCaseDetails.notes[0];
    const attachment = {
      fileId,
      statusCd: 'ACTIVE'
    };
    note.attachments = [];
    note.attachments.push(attachment);
    const responseNote = await CaseService.upsertCaseNote(note);
    const updatedCaseDetails = {
      ...cloneCaseDetails,
      notes: [
        ...(cloneCaseDetails.notes
          ? [
              {
                ...cloneCaseDetails.notes[0],
                attachments: [
                  ...(responseNote.attachments
                    ? responseNote.attachments.map((att) =>
                        att.fileId === fileId
                          ? {
                              ...att,
                              uploadedFile: {
                                fileName,
                                contentType: 'application/pdf',
                                createdBy: 'test',
                                createdOn: moment().toISOString(),
                                fileId,
                                statusCd: NoteStatusCodes.active
                              }
                            }
                          : att
                      )
                    : [])
                ]
              }
            ]
          : []),
        ...(cloneCaseDetails.notes ? cloneCaseDetails.notes.slice(1) : [])
      ]
    };
    setCaseDetails(updatedCaseDetails);
  };
  const handleAttachmentDeleted = async (attachmentId: number) => {
    const attachments: CaseAttachment[] | undefined = JSON.parse(
      JSON.stringify(caseDetails?.notes?.[0]?.attachments)
    );
    if (!attachments || attachments.length === 0) return;
    const updatedAttachments = attachments?.map((attachment) => {
      if (attachment.attachmentId === attachmentId) {
        return {
          ...attachment,
          uploadedFile: {
            ...attachment.uploadedFile,
            statusCd: NoteStatusCodes.deleted
          },
          statusCd: NoteStatusCodes.deleted
        };
      }
      return attachment;
    });
    const updatedCaseDetails = {
      ...caseDetails,
      notes: [
        ...(caseDetails.notes
          ? [
              {
                ...caseDetails.notes[0],
                attachments: updatedAttachments
              }
            ]
          : []),
        ...(caseDetails.notes ? caseDetails.notes.slice(1) : [])
      ]
    };
    setCaseDetails(updatedCaseDetails);
    if (caseDetails && caseDetails.notes) {
      CaseService.upsertCaseNote({
        ...caseDetails.notes[0],
        attachments: updatedAttachments
      });
    }
  };
  const handleNoteContentChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNoteContent(event.target.value);
  };
  return (
    <>
      <Stack direction="column">
        <TextField
          value={noteContent}
          onChange={handleNoteContentChanged}
          multiline
          rows={10}
          fullWidth
          placeholder={`${noteFieldLabel || (t('Case.consult.enterNote') as string)}...`}
        />
        {showRecommendation && (
          <FormGroup
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setIsRecommendedToBeSeen(event.target.checked)
            }>
            <FormControlLabel
              checked={isRecommendedToBeSeen}
              control={<Checkbox />}
              label={t('Case.consult.recommendation')}
              sx={{ color: 'base.grey4' }}
            />
          </FormGroup>
        )}
        <Box mt={3}>
          <EconsultDraftCaseActions onSaveDraft={handleDraftSaved} onSend={handleSend} />
        </Box>
        <Box mt={3} mb={3}>
          <Box mb={1}>
            {caseDetails?.notes?.[0]?.attachments && (
              <EconsultAttachmentListing
                attachments={caseDetails?.notes?.[0]?.attachments}
                onAttachmentDeleted={handleAttachmentDeleted}
              />
            )}
          </Box>
          <DragDropFileUpload onUploadComplete={handleAddNoteAttachment} />
        </Box>
      </Stack>
      <EconsultSendDialog
        isOpen={isSendDialogOpen}
        onCancel={handleCancel}
        onConfirm={handleSaved}
      />
    </>
  );
};

export default EconsultCaseProvideConsult;
