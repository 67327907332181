import React, { FC, ReactNode } from 'react';

type ConditionalShowProps = {
  showWhen: boolean;
  children: ReactNode;
};

const ConditionalShow: FC<ConditionalShowProps> = ({ showWhen, children }) =>
  showWhen ? children : null;

export default ConditionalShow;
