import React from 'react';
import { Box, TableCell } from '@mui/material';
import { GroupSmSVGIcon, DataItemRenderer, MapService } from '@oh-vcp/components-ui';
import { FhoEntity, MapMarkerResult, Policy, SelectedResult } from '@oh-vcp/components-common';
import { Marker } from '@react-google-maps/api';
import FhoPin from '@oh-vcp/components-ui/src/assets/FHO-pin.svg';
import FhoActivePin from '@oh-vcp/components-ui/src/assets/FHO-active-pin.svg';
import FhoListRenderer from '../../fhoListRenderer/FhoListRenderer';
import MapMarkerWithInfoWindow from '../../map/MapMarkerWithInfoWindow';

export class FhoDataItemRenderer extends DataItemRenderer {
  public fho: FhoEntity;

  private displayName: string;

  private lon?: number;

  private lat?: number;

  static schema = {
    guid: 'ID',
    name: 'Name',
    lat: 'Latitude',
    lng: 'Longitude'
  };

  constructor(fho: FhoEntity) {
    super('hs_services');
    this.fho = fho;
    this.displayName = (fho.organization && fho.organization.name) || 'NA';
    if (fho.pin && fho.pin.location && fho.pin.location.length > 0) {
      this.lat = fho.pin.location[0].lat;
      this.lon = fho.pin.location[0].lon;
    }
  }

  // getSchema() {
  //   return FhoDataItem.schema;
  // }

  // getColumns() {
  //   return Object.values(FhoDataItem.schema);
  // }

  getId(): string {
    return this.fho.id.toString();
  }

  getPosition(): { lat: number; lon: number } | undefined {
    if (this.fho.pin && this.fho.pin.location && this.fho.pin.location.length > 0) {
      return {
        lat: this.fho.pin.location[0].lat,
        lon: this.fho.pin.location[0].lon
      };
    }
    return undefined;
  }

  setPolicy(policy: Policy): void {
    this.fho.policy = policy;
  }

  getPolicy(): Policy | undefined {
    return this.fho.policy;
  }

  renderListItem() {
    return <FhoListRenderer result={this.fho} />;
  }

  renderTableRow() {
    return (
      <>
        <TableCell>{this.fho.id}</TableCell>
        <TableCell>{this.displayName}</TableCell>
        <TableCell>
          <GroupSmSVGIcon />
        </TableCell>
        <TableCell>{`Lat: ${this.lat}, Lng: ${this.lon}`}</TableCell>
      </>
    );
  }

  renderMarker(
    activeMarker: MapMarkerResult,
    setActive: (marker: MapMarkerResult) => void,
    selectedResult: SelectedResult | undefined,
    isMarkerInfoHidden: boolean | null
  ): JSX.Element {
    if (!this.fho.locations) return <div />;
    return (
      <div>
        {this.fho.locations?.map((location) => {
          const markerId = `${this.type}_${this.getId()}_${location.clinicName}`;
          const { city, address, postalCode, province, pin, clinicName } = location;
          const { lat, lon } = pin.location;
          const marker = {
            lat,
            lng: lon,
            markerId,
            resultId: this.getId(),
            resultType: this.type,
            clinicName,
            displayName: clinicName as string,
            address: {
              city,
              address,
              postalCode,
              province
            },
            profileUrl: `/fho/${this.fho.organization?.id}/service/${this.getId()}`
          };
          const isActive = MapService.getIsActiveMarker(activeMarker, marker, selectedResult);
          return (
            <MapMarkerWithInfoWindow
              marker={marker}
              isActive={isActive}
              onClick={() => setActive(marker)}
              activeIcon={FhoActivePin}
              icon={FhoPin}
              hideInfoWindow={isMarkerInfoHidden}
            />
          );
        })}
      </div>
    );
  }
}
