const NotificationsConfig = {
  profiles: {
    failed: {
      message: 'profiles.failed.message',
      solution: 'profiles.failed.solution'
    },
    success: {
      message: 'profiles.success.message'
    }
  },
  search: {
    failed: {
      message: 'search.failed.message',
      solution: 'search.failed.solution'
    }
  },
  econsultApplyForPermission: {
    failed: {
      message: 'econsultApplyForPermission.failed.message'
    },
    success: {
      message: 'econsultApplyForPermission.success.message'
    }
  },
  econsultRequestClarification: {
    failed: {
      message: 'Case.consult.requestClarification'
    },
    success: {
      message: 'Case.consult.requestClarification'
    }
  },
  econsultProvideConsult: {
    failed: {
      message: 'Case.consult.provideConsult'
    },
    success: {
      message: 'Case.consult.provideConsult'
    }
  },
  econsultGeneral: {
    failed: {
      message: 'EconsultGeneral.failed.message'
    },
    success: {
      message: 'EconsultGeneral.success.message'
    }
  },
  econsultCompleteConsult: {
    failed: {
      message: 'Case.consult.complete'
    },
    success: {
      message: 'Case.consult.complete'
    }
  },
  econsultCancelConsult: {
    failed: {
      message: 'Case.consult.cancel'
    },
    success: {
      message: 'Case.consult.cancel'
    }
  },
  econsultDraftConsult: {
    failed: {
      message: 'Case.consult.draftSendFailed'
    },
    success: {
      message: 'Case.consult.draftSent'
    }
  },
  econsultDeleteDraft: {
    failed: {
      message: 'Case.consult.draftDeleteFailed'
    },
    success: {
      message: 'Case.consult.draftDeleted'
    }
  },
  econsultSaveDraft: {
    failed: {
      message: 'Case.consult.draftSaveFailed'
    },
    success: {
      message: 'Case.consult.draftSaved'
    }
  }
};

export default NotificationsConfig;
