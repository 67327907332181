import React, { ChangeEvent, FC, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import {
  NoteActionTypes,
  NoteParticipantStatusReasons,
  NoteStatusCodes,
  NoteTypes
} from '@oh-vcp/components-common';
import { CaseDetails, Kpi } from '@oh-vcp/components-common/src/models/case/CaseDetails';
import { useNavigate } from 'react-router-dom';
import CaseService from '../services/caseService';
import { useAuth } from '../auth/AuthContext';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';
import EconsultSendDialog from '../econsultSendDialog/EconsultSendDialog';

interface EconsultCaseReturnConsultProps {
  reasonForReturning: string;
  onReasonChanged: (reason: string) => void;
}

const ReturnButton = styled(Button)({
  width: 100,
  marginTop: 10,
  marginBottom: 10
});

const EconsultCaseReturnConsult: FC<EconsultCaseReturnConsultProps> = ({
  reasonForReturning,
  onReasonChanged
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { caseDetails, setCaseDetails } = useEconsultCaseDetailsContext();
  const [noteContent, setNoteContent] = useState<string>('');
  const [isSendDialogOpen, setIsSendDialogOpen] = useState<boolean>(false);

  if (!user || !caseDetails) {
    return null;
  }

  const handleReasonForReturning = (event: React.ChangeEvent<HTMLInputElement>) => {
    onReasonChanged(event.target.name);
  };
  const handleNoteContentChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNoteContent(event.target.value);
  };
  const getRejectedReason = () => {
    switch (reasonForReturning) {
      case 'incorrectSpecialty':
        return NoteParticipantStatusReasons.incorrectSpecialty;
      case 'notAvailable':
        return NoteParticipantStatusReasons.consultantUnavailable;
      default:
        return NoteParticipantStatusReasons.rejectedOther;
    }
  };
  const handleConfirm = async (kpi?: Kpi) => {
    const returnedNote = await CaseService.upsertCaseNote({
      ownerId: user.userId as number,
      noteTypeCd: NoteTypes.consultant,
      actionTypeCd: NoteActionTypes.decline,
      caseId: caseDetails.caseId,
      statusCd: NoteStatusCodes.active,
      kpis: kpi ? [kpi] : undefined,
      parameter1: t(`Case.consult.${reasonForReturning}`) as string,
      noteContent
    });
    const updatedCaseDetails: CaseDetails = {
      ...caseDetails,
      participant: {
        ...caseDetails?.participant,
        statusCd: NoteStatusCodes.closed,
        statusReason: getRejectedReason()
      },
      notes: [
        {
          ...returnedNote,
          kpis: returnedNote.kpis?.map(({ kpiId, caseId, ...rest }) => rest)
        }
      ]
    };
    setCaseDetails(updatedCaseDetails);
    await CaseService.upsertCaseDetails(updatedCaseDetails);
    setIsSendDialogOpen(false);
    navigate('/case/consultant/needsaction');
  };
  const handleCancel = () => {
    setIsSendDialogOpen(false);
  };
  const handleReturn = async () => {
    setIsSendDialogOpen(true);
  };
  return (
    <>
      <Stack direction="column">
        <Typography>{t('Case.consult.reasonForReturning')}</Typography>
        <FormGroup>
          <FormControlLabel
            name="incorrectSpecialty"
            control={
              <Checkbox
                onChange={handleReasonForReturning}
                checked={reasonForReturning === 'incorrectSpecialty'}
              />
            }
            label={t('Case.consult.incorrectSpecialty')}
            sx={{ color: 'base.grey4' }}
          />
          <FormControlLabel
            name="notAvailable"
            control={
              <Checkbox
                onChange={handleReasonForReturning}
                checked={reasonForReturning === 'notAvailable'}
              />
            }
            label={t('Case.consult.notAvailable')}
            sx={{ color: 'base.grey4' }}
          />
          <FormControlLabel
            name="other"
            control={
              <Checkbox
                onChange={handleReasonForReturning}
                checked={reasonForReturning === 'other'}
              />
            }
            label={t('Case.consult.other')}
            sx={{ color: 'base.grey4' }}
          />
        </FormGroup>
        <TextField
          value={noteContent}
          onChange={handleNoteContentChanged}
          multiline
          rows={5}
          fullWidth
          placeholder={`${t('Case.consult.enterComments')}${
            reasonForReturning === 'other' ? '' : ` ${t('Case.consult.optional')}`
          } - ${t('Case.consult.doNotIncludePersonalDetails')}`}
        />
        <ReturnButton variant="contained" color="primary" onClick={handleReturn}>
          {t('Case.consult.return')}
        </ReturnButton>
      </Stack>
      <EconsultSendDialog
        isOpen={isSendDialogOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default EconsultCaseReturnConsult;
