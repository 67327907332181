import React from 'react';
import { useEconsultCaseDetailsContext } from '@oh-vcp/components-web/src/econsultCaseDetailsContext/EconsultCaseDetailsContext';
import { useEffect } from 'react';
import useCaseDetails from '../../api/useCaseDetails';
import EconsultCaseConsultDetailsView from '../econsultCaseConsultDetailsView/EconsultCaseConsultDetailsView';
import { NoteActionTypes } from '@oh-vcp/components-common';
import EconsultCaseDraftDetailsView from '../econsultCaseDraftDetailsView/EconsultCaseDraftDetailsView';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DisplayMobile } from '@oh-vcp/components-ui';
import { Box } from '@mui/material';
import useAssignerPrograms from '../../api/useAssignerPrograms';

const EconsultCaseDetailsView = () => {
  const { setCaseDetails, caseDetails, setUserRole, setAssignerPrograms } =
    useEconsultCaseDetailsContext();
  const { caseId, providerType, statusType } = useParams();
  const navigate = useNavigate();
  const { data: caseDetailsResponse } = useCaseDetails(caseId ? parseInt(caseId) : undefined);
  const { data: programs } = useAssignerPrograms();

  useEffect(() => {
    if (caseDetailsResponse?.data) {
      setUserRole(caseDetailsResponse?.userRole);
      setCaseDetails(caseDetailsResponse.data);
    }
  }, [caseDetailsResponse, setUserRole, setCaseDetails]);

  useEffect(() => {
    setAssignerPrograms(programs);
  }, [programs, setAssignerPrograms]);

  const handleGoBack = () => {
    navigate(`/case/${providerType}/${statusType}`);
  };

  return (
    <>
      <DisplayMobile>
        <Box mt={2}>
          <ArrowBackIcon onClick={handleGoBack} />
        </Box>
      </DisplayMobile>
      {caseDetails?.statusCd?.toUpperCase() === NoteActionTypes.draft ? (
        <EconsultCaseDraftDetailsView />
      ) : (
        <EconsultCaseConsultDetailsView />
      )}
    </>
  );
};

export default EconsultCaseDetailsView;
