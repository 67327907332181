import { Logger } from './logUtils';
const TOKEN_KEY = 'token';
// const REFRESH_TOKEN_KEY = 'refreshToken';
const ORIGINAL_PATH = 'originalPath';

const logger = new Logger({ moduleName: 'storageUtils' });

export const getItem = (key: string) => {
  return localStorage.getItem(key);
};

export const storeItem = (key: string, item: string | null) => {
  logger.info('storeItem key: {}, item: {}', key, item);
  if (!item) {
    logger.info('storeItem called with empty item');
    return;
  }
  localStorage.setItem(key, item);
};

export const removeItem = (key: string) => {
  localStorage.removeItem(key);
};

export const getUserToken = () => {
  return getItem(TOKEN_KEY);
};

export const storeUserToken = (token: string | null) => {
  storeItem(TOKEN_KEY, token);
};

export const clearUserToken = () => {
  removeItem(TOKEN_KEY);
};

// export const getRefreshToken = () => {
//   return getItem(REFRESH_TOKEN_KEY);
// };

// export const storeRefreshToken = (token: string | null) => {
//   storeItem(REFRESH_TOKEN_KEY, token);
// };

// export const clearRefreshToken = () => {
//   removeItem(REFRESH_TOKEN_KEY);
// };

export const getOriginalPath = () => {
  return getItem(ORIGINAL_PATH);
};

export const storeOriginalPath = (originalPath: string | null) => {
  originalPath = originalPath ? originalPath.toLocaleLowerCase() : '';
  if (originalPath.includes('login') || originalPath.includes('logout')) {
    originalPath = '/';
  }

  storeItem(ORIGINAL_PATH, originalPath);
};

export const clearOriginalPath = () => {
  removeItem(ORIGINAL_PATH);
};

const storageUtils = {
  getItem,
  storeItem,
  removeItem,
  getUserToken,
  storeUserToken,
  clearUserToken,
  // getRefreshToken,
  // storeRefreshToken,
  // clearRefreshToken,
  getOriginalPath,
  storeOriginalPath,
  clearOriginalPath
};

export default storageUtils;
