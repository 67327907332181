import {
  ButtonToggleItem,
  EconsultFilters,
  EconsultProviderTypes,
  EconsultStatusTypes
} from '@oh-vcp/components-common';

const getEconsultFilters = (
  providerType: string | undefined,
  statusType: string | undefined
): ButtonToggleItem[] => {
  if (providerType?.toLowerCase() === EconsultProviderTypes.consultant) {
    switch (statusType?.toLowerCase()) {
      case EconsultStatusTypes.needsAction:
        return EconsultFilters.ConsultantNeedsActionFilterItems;
      case EconsultStatusTypes.provided:
        return EconsultFilters.ConsultantProvidedFilterItems;
      default:
        return [];
    }
  } else if (providerType?.toLowerCase() === EconsultProviderTypes.referrer) {
    switch (statusType?.toLowerCase()) {
      case EconsultStatusTypes.needsAction:
        return EconsultFilters.ReferrerNeedsActionFilterItems;
      default:
        return [];
    }
  } else {
    return [];
  }
};

const EconsultFilterService = {
  getEconsultFilters
};

export default EconsultFilterService;
