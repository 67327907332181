import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { CaseResponsePayloadDTO } from '@oh-vcp/components-common';
import { apiService } from '@oh-vcp/components-web';
import { EconsultFilterService } from '@oh-vcp/components-ui';

const fetchCaseResults = async (
  pageNo: number,
  casesPerPage: number,
  userRole: string,
  folder?: string | undefined,
  subFolder?: string | undefined,
  delegatorId?: string | undefined,
  programName?: string | undefined
): Promise<CaseResponsePayloadDTO> => {
  let url = `cases/dashboard?&casesPerPage=${casesPerPage}&pageNo=${pageNo}&userRole=${userRole}`;
  if (folder) {
    url += `&folder=${folder}`;
  }
  if (subFolder) {
    url += `&subFolder=${subFolder}`;
  }
  if (delegatorId) {
    url += `&delegatorId=${delegatorId}`;
  }
  if (programName) {
    url += `&programName=${programName}`;
  }
  const response = await apiService.get(url);
  return response.data;
};

const isCaseResultsEnabled = (userRole: string, folder?: string, subFolder?: string): boolean => {
  const eConsultFilters = EconsultFilterService.getEconsultFilters(userRole, folder);
  if (userRole?.toLowerCase() === 'search' || userRole?.toLowerCase() === 'reports') {
    return false;
  }
  if (subFolder) {
    if (!eConsultFilters || eConsultFilters.length === 0) {
      return false;
    }
    const existingFolder = eConsultFilters.find((f) => f.value === subFolder);
    if (!existingFolder) {
      return false;
    }
  }
  return true;
};

const useCaseResults = (
  pageNo: number,
  casesPerPage: number,
  userRole: string,
  folder?: string | undefined,
  subFolder?: string | undefined,
  delegatorId?: string | undefined,
  programName?: string | undefined
) => {
  return useQuery<CaseResponsePayloadDTO, AxiosError>(
    ['caseResults', pageNo, casesPerPage, userRole, folder, subFolder, delegatorId, programName],
    () =>
      fetchCaseResults(pageNo, casesPerPage, userRole, folder, subFolder, delegatorId, programName),
    {
      enabled: isCaseResultsEnabled(userRole, folder, subFolder),
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );
};

export default useCaseResults;
