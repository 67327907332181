import React, { FC, MouseEvent } from 'react';
import { SvgIcon, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  ActionItemParams,
  ActionMenuItemLabel,
  PenToSquareIcon,
  RedirectService
} from '@oh-vcp/components-ui';

interface EditActionProps {
  params: ActionItemParams;
  translationKey: string;
  actionType: 'menu' | 'toolbar';
}

const EditAction: FC<EditActionProps> = ({ params, translationKey, actionType }) => {
  const { profileType, profileId, hsServiceId } = params;
  const { t } = useTranslation();

  const handleOnClick = (event: MouseEvent<any>) => {
    event.stopPropagation();

    switch (profileType) {
      case 'people':
        RedirectService.redirectToEditPeopleProfile(profileId);
        break;
      case 'site':
        RedirectService.redirectToEditSiteProfile(profileId);
        break;
      case 'program':
        RedirectService.redirectToEditProgramProfile(profileId);
        break;
      case 'fho':
        RedirectService.redirectToEditFhoProfile(profileId, hsServiceId);
        break;
      default:
        break;
    }
  };

  return (
    <Box style={{ flexGrow: 1 }}>
      {actionType === 'toolbar' ? (
        <ActionButton onClick={handleOnClick}>
          <SvgIcon component={PenToSquareIcon} sx={{ mt: 1, fill: 'primary.main' }} />
          <Typography variant="h4">{t(translationKey)}</Typography>
        </ActionButton>
      ) : (
        <ActionMenuItemLabel onClick={handleOnClick}>
          <SvgIcon component={PenToSquareIcon} sx={{ mt: 1, fill: 'base.grey1' }} />
          {t(translationKey)}
        </ActionMenuItemLabel>
      )}
    </Box>
  );
};

export default EditAction;
