import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface EconsultOutOfOfficeDialogProps {
  isDialogOpen: boolean;
  userName?: string;
  startDate?: string | null;
  endDate?: string | null;
  onCancelClicked: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onContinueClicked: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const EconsultOutOfOfficeDialog: FC<EconsultOutOfOfficeDialogProps> = ({
  isDialogOpen,
  userName,
  startDate,
  endDate,
  onCancelClicked,
  onContinueClicked
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isDialogOpen}>
      <DialogTitle variant="h2" sx={{ color: 'base.grey4' }}>
        {t('Econsult.UnavailabilityDates')}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ color: 'base.grey4' }}>
          {userName} is unavailable from {moment(startDate).format('MMMM D, YYYY')} to{' '}
          {moment(endDate).format('MMMM D, YYYY')}
        </Typography>
        <Typography variant="body1" sx={{ color: 'base.grey4' }}>
          Are you sure you want to continue?
        </Typography>
        <Stack direction="row" columnGap={2} mt={2} justifyContent="flex-end">
          <Button
            size="large"
            variant="contained"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onContinueClicked(e)}>
            Continue
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onCancelClicked(e)}>
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default EconsultOutOfOfficeDialog;
