// Converts UNIX timestamp (as used in JWT token) to JS Date object
export const utcSecondsToDate = (utcSeconds: number | undefined) => {
  if (!utcSeconds) {
    return undefined;
  }
  const date = new Date(0);
  date.setUTCSeconds(utcSeconds);
  return date;
};

const getAge = (dateObject: Date) => {
  const timeDifferenceMs = Date.now() - dateObject.getTime();
  const ageDate = new Date(timeDifferenceMs);
  return '' + Math.abs(ageDate.getUTCFullYear() - 1970);
};

const dateUtils = {
  utcSecondsToDate,
  getAge
};

export default dateUtils;
