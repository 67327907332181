import { MapMarkerResult, Policy, SelectedResult } from '@oh-vcp/components-common';

export default abstract class DataItemRenderer {
  constructor(
    public type:
      | 'hs_services'
      | 'contacts'
      | 'site_with_systems'
      | 'community_services'
      | 'programs'
      | 'UNKOWN'
      | string
  ) {}
  // abstract getSchema(): object;
  abstract renderListItem(): JSX.Element;
  abstract renderTableRow(): JSX.Element;
  abstract getId(): string;
  abstract setPolicy(policy: Policy): void;
  abstract getPolicy(): Policy | undefined;
  getPosition?():
    | {
        lat: number;
        lon: number;
      }
    | undefined;
  renderMarker?(
    activeMarker: MapMarkerResult | undefined,
    setActive: (marker: MapMarkerResult) => void,
    selectedResult: SelectedResult | undefined,
    isMarkerInfoHidden?: boolean | null
  ): JSX.Element;
  getColumns?(): string[];
}
