import React, { FC, PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

type SearchParamContextProps = {
  searchKeyword: string;
  highlightMT: string;
  setSearchKeyword: React.Dispatch<React.SetStateAction<string>>;
  setHighlightMT: React.Dispatch<React.SetStateAction<string>>;
};

export const SearchParamContext = createContext<SearchParamContextProps | undefined>(undefined);

export const useSearchParamContext = () => {
  const context = useContext(SearchParamContext);
  if (!context) {
    throw new Error('useSearchParamContext must be used within a Provider');
  }
  return context;
};

export const SearchParamContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [highlightMT, setHighlightMT] = useState<string>('');

  const value = useMemo(() => {
    return { searchKeyword, setSearchKeyword, highlightMT, setHighlightMT };
  }, [searchKeyword, highlightMT]);

  return <SearchParamContext.Provider value={value}>{children}</SearchParamContext.Provider>;
};
