import React from 'react';
import { AppConfig } from '@oh-vcp/components-common';

const redirectToSuggestViewPeopleProfile = (id?: string) => {
  // window.location.replace(`${AppConfig.baseHubUrl}/#/profile/people/${id}/suggest/view`);
  window.open(`${AppConfig.baseHubUrl}/#/profile/people/${id}/suggest/view`);
};

const redirectToSuggestEditPeopleProfile = (id?: string) => {
  window.open(`${AppConfig.baseHubUrl}/#/profile/people/${id}/suggest/edit`);
};

const redirectToEditPeopleProfile = (id?: string) => {
  window.open(`${AppConfig.baseHubUrl}/#/profile/people/${id}/edit`); // original
};

const redirectToPeopleProfile = (id?: string) => {
  window.open(`/profile/people/${id}`, `_self`);
};

const redirectToDirectoryPeopleProfile = (id?: string) => {
  window.open(`${AppConfig.baseHubUrl}/#/profile/people/${id}`);
};

const redirectToSuggestViewSiteProfile = (id?: string) => {
  window.open(`${AppConfig.baseHubUrl}/#/profile/places/${id}/suggest/view`);
};

const redirectToSuggestEditSiteProfile = (id?: string) => {
  window.open(`${AppConfig.baseHubUrl}/#/profile/places/${id}/suggest/edit`);
};

const redirectToEditSiteProfile = (id?: string) => {
  window.open(`${AppConfig.baseHubUrl}/#/profile/places/${id}/edit`);
};

const redirectToEditProgramProfile = (id?: string) => {
  window.open(`${AppConfig.baseHubUrl}/#/programs/${id}/edit`);
};

const redirectToProgramProfile = (id?: string) => {
  window.open(`${AppConfig.baseHubUrl}/#/programs/${id}`);
};

const redirectToEditFhoProfile = (hsOrgId?: string, hsServiceId?: string) => {
  window.open(`${AppConfig.baseHubUrl}/#/fho/${hsOrgId}/service/${hsServiceId}/edit`);
};

const redirectToEconsultCaseCreate = (id?: string) => {
  window.open(
    `${AppConfig.baseEconUrl}/#/cases/filter2/REFERRER/DRAFT/create?specialistId=${id}&seenUnavailability=true`
  );
};

const redirectToProgramEconsultCaseCreate = (
  id?: string,
  status?: string,
  type?: string,
  specialty?: string,
  subSpecialty?: string
) => {
  let url =
    `${AppConfig.baseEconUrl}/#/cases/filter2/REFERRER/DRAFT/create?` +
    `programId=${id}&programStatus=${status}&programType=${type}`;
  if (specialty) {
    url += `&specialty=${specialty}`;
  }
  if (subSpecialty) {
    url += `&subSpecialty=${subSpecialty}`;
  }
  window.open(url);
};

const RedirectService = {
  redirectToSuggestViewPeopleProfile,
  redirectToSuggestEditPeopleProfile,
  redirectToEditPeopleProfile,
  redirectToPeopleProfile,
  redirectToSuggestViewSiteProfile,
  redirectToSuggestEditSiteProfile,
  redirectToEditSiteProfile,
  redirectToEditProgramProfile,
  redirectToEditFhoProfile,
  redirectToEconsultCaseCreate,
  redirectToProgramEconsultCaseCreate,
  redirectToProgramProfile,
  redirectToDirectoryPeopleProfile
};

export default RedirectService;
