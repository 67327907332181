import React, { FC, useState } from 'react';
import { UserDTO, NameUtils, AppConfig, EconsultUtils } from '@oh-vcp/components-common';
import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Stack,
  Toolbar,
  Link as MuiLink,
  Alert,
  Box
} from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { OHTheme, RedirectService } from '@oh-vcp/components-ui';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Login, Logout, PersonOutline, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { OntarioHealthLogo, QuestionCircleSVGIcon } from '../icons';
import {
  Logo,
  NavBar,
  HelpIcon,
  FlexGrow,
  LanguageSelectorContainer,
  ResponsiveFlexContainer,
  NavBarPaddedContainer,
  DisplayDesktop
} from '../StyledComponents';

interface TopNavigationProps {
  user: UserDTO | null;
}

const TopNavigation: FC<TopNavigationProps> = ({ user }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    navigate(`/logout`);
  };
  const handleEdit = () => {
    RedirectService.redirectToPeopleProfile(`${user?.userid}`);
  };
  const { t } = useTranslation();

  const visibleStyle = {
    display: 'block'
  };
  const invisibleStyle = {
    display: 'none'
  };
  const [alertVisibility, setAlertVisibility] = useState(invisibleStyle);
  const userUnavailable: string = user?.unavailability!;
  if (EconsultUtils.getCurrentOutOfOfficeDate(userUnavailable)) {
    setAlertVisibility(visibleStyle);
  }
  return (
    <NavBar position="sticky" about="NavBar">
      <NavBarPaddedContainer about="DisplayDesktopPaddedContainer">
        <Toolbar variant="dense" disableGutters about="Toolbar">
          <FlexGrow sx={{ pt: 1 }} about="FlexGrow">
            <Link to="/" aria-label="Ontario Health logo">
              {/* TODO use mui Link */}
              <Logo component={OntarioHealthLogo} inheritViewBox />
            </Link>
          </FlexGrow>
          <Stack direction="row" alignItems="center">
            <LanguageSelectorContainer>
              {/* <FlexGrow>
              <LanguageSelector />
            </FlexGrow> */}
              <MuiLink href={AppConfig.helpUrl} target="_blank" aria-label="Help link">
                <Avatar sx={{ bgcolor: OHTheme.palette.base.grey6 }}>
                  <HelpIcon component={QuestionCircleSVGIcon} viewBox="-7 -5 25 25" />
                </Avatar>
              </MuiLink>
            </LanguageSelectorContainer>
          </Stack>
          <ResponsiveFlexContainer ml={5}>
            <Stack direction="row" alignItems="center">
              <Tooltip title={`${user?.firstName} ${user?.lastName}`} onClick={handleClick}>
                <Avatar sx={{ bgcolor: OHTheme.palette.primary.main }}>
                  {NameUtils.makeNameInitials(user?.lastName, user?.firstName)}
                </Avatar>
              </Tooltip>
              <DisplayDesktop>
                <Button
                  endIcon={anchorEl ? <ArrowDropDown /> : <ArrowDropUp />}
                  onClick={handleClick}
                  size="large"
                  aria-label="More options">
                  {user && `${user.firstName} ${user.lastName}`}
                </Button>
              </DisplayDesktop>
            </Stack>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
              {user?.publishedProfile && (
                <MenuItem onClick={handleEdit}>
                  <ListItemIcon>
                    <PersonOutline />{' '}
                  </ListItemIcon>
                  My Directory Profile
                </MenuItem>
              )}
              {user && (
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  {t('logout')}
                </MenuItem>
              )}
              {!user && (
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Login fontSize="small" />
                  </ListItemIcon>
                  Login
                </MenuItem>
              )}
            </Menu>
          </ResponsiveFlexContainer>
        </Toolbar>
      </NavBarPaddedContainer>
      <Box style={alertVisibility}>
        <Alert
          severity="warning"
          onClose={() => {
            setAlertVisibility(invisibleStyle);
          }}>
          {t('TopNavBar.unavailable.on')}
        </Alert>
      </Box>
    </NavBar>
  );
};

TopNavigation.defaultProps = {
  user: null
};

export default TopNavigation;
