import React from 'react';
import { Box, Link, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  DisplayDesktop,
  DisplayMobile,
  ResultCardHeader,
  ResultHeaderIcon,
  StyledCardHeaderSubTitle,
  StyledCardHeaderTitle,
  TextSeparator,
  useSearchParamContext
} from '@oh-vcp/components-ui';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSearchResultsViewContext } from '../searchResults/SearchResultsViewContext';

const extractHighlight = (text: string) => {
  const split = text.split('</em>');
  const res = split[0].split('<em>')[1];
  return res;
};
const findMTHighlightValue = (mtHighlights: Record<string, string>): string => {
  if (!mtHighlights) {
    return '';
  }
  const highlightKeys = Object.keys(mtHighlights).filter((key) => key !== 'languageCd');
  const highlights = highlightKeys.map((key) => {
    return extractHighlight(mtHighlights[key].toString());
  });
  const res = highlights[0] || '';
  return res;
};

interface SearchResultCardHeaderProps {
  title: string;
  secondaryTitle?: string;
  subTitle?: string;
  secondarySubTitle?: string | React.ReactNode;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  viewBox?: string;
  children?: React.ReactNode;
  url: string;
  isCompact?: boolean;
  highlightText?: string | null;
  highlight?: any;
  optionalHighlight?: string | undefined;
  optionalHighlightLabel?: string | undefined;
}

const SearchResultCardHeader: React.FC<SearchResultCardHeaderProps> = ({
  title,
  secondaryTitle,
  subTitle,
  secondarySubTitle,
  icon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  viewBox,
  children,
  url,
  isCompact,
  highlightText,
  highlight,
  optionalHighlight,
  optionalHighlightLabel
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setHighlightMT, setSearchKeyword } = useSearchParamContext();
  const { isMinimalView } = useSearchResultsViewContext();
  const highlightTextTrimmed = highlightText?.trim();
  const highlightTextMT = findMTHighlightValue(highlight) || highlightTextTrimmed || '';
  const removeInvalidStrings = (str: string) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/(?<!<)\b\w+>/g, '') // Remove incomplete html tags
      .replace(/<\/?[^>]+(>|$)/g, ' ') // Remove remaining html tags
      .replace(/&[^\s]*?;/g, '');
  };
  const trimSpecialChars = (text: string) => {
    const regex = /[\^$\\.*+?|()[\]{}]/g;
    const parts = text.split(regex);
    return parts[0];
  };
  const highlightMatch = (text: string, highlightMT: string, keyword?: string) => {
    if (!keyword) {
      return text;
    }
    let trimmedKeyword = trimSpecialChars(keyword);
    let words = trimmedKeyword.split(/\s+/).filter(Boolean);
    const keywordRegexPattern = words.map((word) => `(${word})`).join('|');
    const keywordRegex = new RegExp(keywordRegexPattern, 'gi');
    let parts = removeInvalidStrings(text).split(keywordRegex).filter(Boolean);
    if (parts.length === 1 && highlightMT) {
      parts = removeInvalidStrings(text).split(new RegExp(`(${highlightMT})`, 'gi'));
      trimmedKeyword = highlightMT;
      words = [highlightMT];
    }
    const wordsLowerCase = words.map((word) => word.toLowerCase());
    return (
      <span>
        {parts.map((part, index) => (
          <span
            key={index}
            style={
              wordsLowerCase.includes(part.toLowerCase())
                ? { color: theme.palette.primary.main, fontWeight: 'bold' }
                : {}
            }>
            {part}
          </span>
        ))}
      </span>
    );
  };
  const highlightMatchWithOptionalText = (highlightMT: string, text?: string, keyword?: string) => {
    if (!highlightMT) {
      return highlightMatch(text as string, highlightMT, keyword);
    }
    if (
      !keyword ||
      title.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
      title.toLowerCase().indexOf(highlightMT.toLowerCase()) > -1
    ) {
      return text;
    }
    const highlightKeys = Object.keys(highlight || {});
    const sanitazedText = removeInvalidStrings(text || '').toLowerCase();
    if (
      sanitazedText.indexOf(keyword.toLowerCase()) > -1 ||
      sanitazedText.indexOf(highlightMT.toLowerCase()) > -1
    ) {
      return highlightMatch(text as string, highlightMT, keyword);
    }
    let optionalHighlightText = optionalHighlight;
    let optionalKey = highlightKeys?.find((key) => key !== 'languageCd');
    if (optionalKey) {
      [optionalHighlightText] = highlight[optionalKey];
    } else if (optionalHighlightLabel) {
      optionalKey = optionalHighlightLabel;
    }
    if (optionalHighlightText) {
      const optionalHighlightedMatches = highlightMatch(
        removeInvalidStrings(optionalHighlightText),
        highlightTextMT,
        keyword
      );
      return (
        <Box>
          {isCompact ? (
            <span>
              {text}
              {text ? ', ' : ''}
              {t(`Highlight.${optionalKey}`)} | {optionalHighlightedMatches}
            </span>
          ) : (
            <>
              <Typography>{text}</Typography>
              <Typography>
                {t(`Highlight.${optionalKey}`)} | {optionalHighlightedMatches}
              </Typography>
            </>
          )}
        </Box>
      );
    }
    return text;
  };
  const handleResultClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (highlightText) {
      setSearchKeyword(highlightText);
    }
    setHighlightMT(highlightTextMT);
    e.preventDefault();
    e.stopPropagation();
    navigate(url);
  };
  const MinimalDisplayComponent = isMinimalView ? Box : DisplayMobile;
  return (
    <ResultCardHeader
      sx={{ margin: isCompact ? 0 : 2 }}
      title={
        <Stack direction="row">
          <Box>
            <ResultHeaderIcon>
              <SvgIcon component={icon} inheritViewBox sx={{ width: '15px', height: '15px' }} />
            </ResultHeaderIcon>
          </Box>
          <Box>
            <Link
              href={url}
              underline={isCompact ? 'none' : 'hover'}
              color="base.grey3"
              onClick={handleResultClick}>
              <Stack direction="row">
                <StyledCardHeaderTitle variant={isCompact ? 'body1b' : 'h3'}>
                  {highlightMatch(title, highlightTextMT, highlightTextTrimmed)}
                </StyledCardHeaderTitle>
                {!isMinimalView && (
                  <DisplayDesktop>
                    {secondaryTitle && (
                      <Stack direction="row">
                        <TextSeparator />
                        <StyledCardHeaderTitle variant={isCompact ? 'body1b' : 'h3'}>
                          {highlightMatch(secondaryTitle, highlightTextMT, highlightTextTrimmed)}
                        </StyledCardHeaderTitle>
                      </Stack>
                    )}
                  </DisplayDesktop>
                )}
              </Stack>
            </Link>
            <MinimalDisplayComponent>
              <StyledCardHeaderSubTitle variant="body1">
                {secondaryTitle &&
                  highlightMatch(secondaryTitle, highlightTextMT, highlightTextTrimmed)}
              </StyledCardHeaderSubTitle>
            </MinimalDisplayComponent>
            <StyledCardHeaderSubTitle variant={isCompact ? 'body2' : 'body1'}>
              {highlightMatchWithOptionalText(highlightTextMT, subTitle, highlightTextTrimmed)}
            </StyledCardHeaderSubTitle>
            {!isCompact && (
              <>
                <StyledCardHeaderSubTitle variant="body1">
                  {secondarySubTitle}
                </StyledCardHeaderSubTitle>
                {children}
              </>
            )}
          </Box>
        </Stack>
      }
    />
  );
};

SearchResultCardHeader.defaultProps = {
  subTitle: ''
};

SearchResultCardHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default SearchResultCardHeader;
