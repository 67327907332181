// src/TableView.tsx
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material';
import { DataItemRenderer } from '../data/renderers/DataItemRenderer';
import { SimpleCard } from '../styles/SharedStyles';

interface TableViewProps {
  title?: string;
  items: DataItemRenderer[];
  headers: string[];
}

const TableView: React.FC<TableViewProps> = ({ title, items, headers }) => {
  return (
    <SimpleCard>
      {title && (
        <Typography variant="h3" gutterBottom sx={{ color: 'primary.main' }}>
          {title}
        </Typography>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.guid}>{item.renderTableRow()}</TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SimpleCard>
  );
};

TableView.defaultProps = {
  title: ''
};

export default TableView;
