import React, { FC } from 'react';
import { Tab, Container, Typography, styled, useTheme, useMediaQuery } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useTranslation } from 'react-i18next';
import { logUtils } from '@oh-vcp/components-common';
import {
  AllSearchOption,
  SearchSVGIcon,
  HandHoldingHeart1Icon,
  HandHoldingHeart1AIcon,
  ReferrersSearchOption,
  Group4248,
  SitesSearchOption,
  SitesSearchOptionActive,
  SearchParams
} from '@oh-vcp/components-ui';
import { PlaceValue } from '@oh-vcp/components-ui/src/autocompletePlaces/AutocompletePlaces';
import { useLocation } from 'react-router-dom';
import { useSearchGuidedTabsContext } from './SearchGuidedTabsContext';
import SearchFields from '../searchFields/SearchFields';
import { useSearchResultsViewContext } from '../searchResults/SearchResultsViewContext';

const minimalTabStyle = (theme: any, selected: boolean) => {
  return {
    color: theme.palette.base.grey4,
    backgroundColor: selected ? '' : theme.palette.primary.custom4,
    borderColor: selected ? '#0D47A1' : 'none',
    borderWidth: selected ? 1 : 0,
    borderStyle: selected ? 'solid' : 'none',
    borderRadius: selected ? '4px' : '4px',
    minWidth: '120px',
    minHeight: '45px',
    padding: '0',
    'box-shadow': selected ? 'none' : '0px 1px 2px rgba(0, 0, 0, 0.16)',
    marginRight: '8px',
    marginBottom: '8px'
  };
};

const MinimalStyledTabList = styled(TabList)(({ theme }) => {
  return {};
});

const MinimalStyledTab = styled(Tab)(({ theme, selected }) => {
  return minimalTabStyle(theme, selected);
});

const StyledTabList = styled(TabList)(({ theme }) => {
  return {
    [theme.breakpoints.up('md')]: {
      overflow: 'visible',
      'min-height': 'auto',
      '& button:first-of-type': {
        'border-radius': '4px 0 0 0'
      },
      '& button:last-of-type': {
        'border-radius': '0 4px 0 0'
      },
      '& > div:nth-child(-n+2)': {
        overflow: 'visible !important'
      }
    },
    [theme.breakpoints.down('md')]: {}
  };
});

const StyledTab = styled(Tab)(({ theme, selected }) => {
  return {
    'font-size': '16px',
    [theme.breakpoints.up('md')]: {
      'min-height': '2.6875rem',
      'background-color': selected ? theme.palette.background.default : '#EEEEEE',
      'z-index': selected ? '2' : '0',
      'box-shadow': selected ? '0px 1px 12px rgba(39,39,39,.2)' : 'none',
      overflow: 'visible',
      fontVariant: theme.typography.body2,
      'max-width': '160px',
      'padding-top': '2px',
      'padding-bottom': '2px',
      '& > svg': {
        overflow: 'inherit'
      },
      ...(selected
        ? {
            '&:after': {
              content: '""',
              position: 'absolute',
              bottom: '-10px',
              left: '0px',
              width: '100%',
              height: '10px',
              'background-color': 'white'
            }
          }
        : {})
    },
    [theme.breakpoints.down('md')]: minimalTabStyle(theme, selected)
  };
});
type SearchGuidedTabsProps = {
  onLocationChanged?: (newLocation: PlaceValue | undefined) => void;
};
const SearchGuidedTabs: FC<SearchGuidedTabsProps> = ({ onLocationChanged }) => {
  logUtils.logger.info('SearchGuidedTabs render');
  const { params, onSearch, isDrawerOpen, onSwitchTab, onSearchFieldsChange } =
    useSearchGuidedTabsContext();
  const {
    selectedGuidedOption: value,
    keyword,
    location,
    isIndigenousServices,
    isFrenchLanguageServices
  } = params;
  // const [value, setValue] = useState(selectedGuidedOption);
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMinimalView } = useSearchResultsViewContext();
  const isSm = useMediaQuery(theme.breakpoints.down('sm')) || isMinimalView;
  const StyledTabListComponent = isMinimalView ? MinimalStyledTabList : StyledTabList;
  const StyledTabComponent = isMinimalView ? MinimalStyledTab : StyledTab;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    onSwitchTab(newValue);
  };

  const handleAction = (searchParams: SearchParams) => {
    const p = { ...searchParams, selectedGuidedOption: value };
    onSearch(p);
  };

  const handleOnSearchFieldsChangeAction = (searchParams: SearchParams) => {
    const p = { ...searchParams, selectedGuidedOption: value };
    onSearchFieldsChange(p);
  };

  return (
    <Container maxWidth="lg" sx={{ mb: 1 }}>
      {!isDrawerOpen && (
        <Typography align="center" variant={isSm ? 'h2' : 'h1'} color="primary.dark" gutterBottom>
          {t('Search.title')}
        </Typography>
      )}
      {!isSm && (
        <Typography align="center" variant="h5" color="base.grey3" marginBottom={2}>
          {t('Search.description')}
        </Typography>
      )}
      <TabContext value={value}>
        <StyledTabListComponent
          TabIndicatorProps={{ style: { display: 'none' } }}
          value={value}
          onChange={handleChange}
          aria-label="Guided search options"
          variant="scrollable"
          scrollButtons={isMinimalView}>
          <StyledTabComponent
            icon={value === '0' ? <AllSearchOption /> : <SearchSVGIcon />}
            iconPosition="start"
            label={t('SearchGuidedTabs.ALL.label')}
            value="0"
          />
          <StyledTabComponent
            icon={value === '1' ? <ReferrersSearchOption /> : <Group4248 />}
            iconPosition="start"
            label={t('SearchGuidedTabs.PHYSICIANS.label')}
            value="1"
          />
          <StyledTabComponent
            icon={value === '2' ? <HandHoldingHeart1AIcon /> : <HandHoldingHeart1Icon />}
            iconPosition="start"
            label={t('SearchGuidedTabs.COMMUNITY_SERVICES.label')}
            value="2"
          />
          <StyledTabComponent
            icon={value === '3' ? <SitesSearchOptionActive /> : <SitesSearchOption />}
            iconPosition="start"
            label={t('SearchGuidedTabs.SITES.label')}
            value="3"
          />
        </StyledTabListComponent>

        <TabPanel value="0" sx={{ p: 0 }}>
          <SearchFields
            onSearch={handleAction}
            onLocationChanged={onLocationChanged}
            keyword={keyword || ''}
            placeValueProps={location}
            isIndigenousServices={isIndigenousServices}
            isFrenchLanguageServices={isFrenchLanguageServices}
            SearchBarPlaceHolderI18nKey="SearchBar.ALL.placeholder"
            onSearchFieldsChange={handleOnSearchFieldsChangeAction}
          />
        </TabPanel>
        <TabPanel value="1" sx={{ p: 0 }}>
          <SearchFields
            onSearch={handleAction}
            onLocationChanged={onLocationChanged}
            keyword={keyword || ''}
            placeValueProps={location}
            isIndigenousServices={isIndigenousServices}
            isFrenchLanguageServices={isFrenchLanguageServices}
            SearchBarPlaceHolderI18nKey="SearchBar.REFERRALS.placeholder"
            onSearchFieldsChange={handleOnSearchFieldsChangeAction}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          <SearchFields
            onSearch={handleAction}
            onLocationChanged={onLocationChanged}
            keyword={keyword || ''}
            placeValueProps={location}
            isIndigenousServices={isIndigenousServices}
            isFrenchLanguageServices={isFrenchLanguageServices}
            SearchBarPlaceHolderI18nKey="SearchBar.COMMUNITY_SERVICES.placeholder"
            isIndigenousServicesVisible={false}
            isFrenchLanguageServicesVisible={false}
            onSearchFieldsChange={handleOnSearchFieldsChangeAction}
          />
        </TabPanel>
        <TabPanel value="3" sx={{ p: 0 }}>
          <SearchFields
            onSearch={handleAction}
            onLocationChanged={onLocationChanged}
            keyword={keyword || ''}
            placeValueProps={location}
            isIndigenousServices={isIndigenousServices}
            isFrenchLanguageServices={isFrenchLanguageServices}
            SearchBarPlaceHolderI18nKey="SearchBar.SITES.placeholder"
            onSearchFieldsChange={handleOnSearchFieldsChangeAction}
          />
        </TabPanel>
      </TabContext>
    </Container>
  );
};

export default SearchGuidedTabs;
