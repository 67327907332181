import { Button, Stack, styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface EconsultDraftCaseActionsProps {
  onSaveDraft?: () => void;
  onDeleteDraft?: () => void;
  onSend?: () => void;
}

const EconsultDraftCaseActions: FC<EconsultDraftCaseActionsProps> = ({
  onSaveDraft,
  onDeleteDraft,
  onSend
}) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={1} justifyContent="end">
      {onSaveDraft && (
        <Button variant="contained" color="primary" onClick={onSaveDraft}>
          {t('Case.draft.saveAsDraft')}
        </Button>
      )}
      {onDeleteDraft && (
        <Button variant="contained" color="primary" onClick={onDeleteDraft}>
          {t('Case.draft.deleteDraft')}
        </Button>
      )}
      {onSend && (
        <Button variant="contained" color="primary" onClick={onSend}>
          {t('Case.draft.send')}
        </Button>
      )}
    </Stack>
  );
};

export default EconsultDraftCaseActions;
