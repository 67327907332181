import { EconsultCaseFoldersCache } from '@oh-vcp/components-common';
import React, { FC, PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

interface EconsultCaseResultContextProps {
  selectedCaseId: number | undefined;
  setSelectedCaseId: (caseId: number | undefined) => void;
  dashboardUserRole: 'CONSULTANT' | 'REFERRER' | undefined;
  setDashboardUserRole: (userRole: 'CONSULTANT' | 'REFERRER' | undefined) => void;
  caseFoldersCache: EconsultCaseFoldersCache | undefined;
  setCaseFoldersCache: React.Dispatch<React.SetStateAction<EconsultCaseFoldersCache | undefined>>;
}

export const EconsultCaseResultContext = createContext<EconsultCaseResultContextProps | undefined>(
  undefined
);

export const useEconsultCaseResultContext = () => {
  const value = useContext(EconsultCaseResultContext);
  if (!value) {
    throw new Error(
      'no value found for EconsultCaseResultContext, did you forget to wrap it in a provider?'
    );
  }
  return value;
};

export const EconsultCaseResultProvider: FC<PropsWithChildren> = ({ children }) => {
  const [selectedCaseId, setSelectedCaseId] = useState<number | undefined>(undefined);
  const [dashboardUserRole, setDashboardUserRole] = useState<'CONSULTANT' | 'REFERRER' | undefined>(
    undefined
  );
  const [caseFoldersCache, setCaseFoldersCache] = useState<EconsultCaseFoldersCache | undefined>(
    undefined
  );
  const value = useMemo(() => {
    return {
      selectedCaseId,
      setSelectedCaseId,
      dashboardUserRole,
      setDashboardUserRole,
      caseFoldersCache,
      setCaseFoldersCache
    };
  }, [selectedCaseId, dashboardUserRole, caseFoldersCache]);

  return (
    <EconsultCaseResultContext.Provider value={value}>
      {children}
    </EconsultCaseResultContext.Provider>
  );
};
