import { AppConfig, Address, MapMarkerResult, SelectedResult } from '@oh-vcp/components-common';

const MapService = {
  getStaticMapUrl: (address: Address | null, height: number, width: number): string => {
    const mapUrl =
      `http://maps.googleapis.com/maps/api/staticmap?key=${AppConfig.googleAPIKey}` +
      `&center=${encodeURIComponent(address?.address || '')},${address?.city},${
        address?.postalCode
      }` +
      `&zoom=14&size=${width}x${height}&maptype=roadmap&markers=color:0x0000029%7C` +
      `${address?.address},${address?.city},${address?.postalCode}&sensor=false`;
    return mapUrl;
  },
  getMapUrl: (address: Address | null): string => {
    const mapUrl =
      `https://maps.google.com/maps?daddr=` +
      `${encodeURIComponent(address?.address || '')},${address?.city},${address?.postalCode}`;
    return mapUrl;
  },
  getIsActiveMarker: (
    activeMarker: MapMarkerResult,
    marker: MapMarkerResult,
    selectedResult: SelectedResult | undefined
  ) => {
    if (selectedResult) {
      return (
        selectedResult.id.toString() === marker.resultId &&
        selectedResult.type === marker.resultType &&
        (selectedResult.clinicName ? selectedResult.clinicName === marker.clinicName : true)
      );
    }
    return activeMarker?.markerId === marker.markerId;
  }
};

export default MapService;
