import { EconsultCaseFlag } from '@oh-vcp/components-common';
import { useMutation, useQuery } from 'react-query';
import apiService from './apiService';

const fetchEconsultFlags = async (): Promise<EconsultCaseFlag[]> => {
  const response = await apiService.get(`cases/caseflags`);
  return response.data;
};

const addCaseFlag = async (caseId: number, caseFlagCode: string): Promise<any> => {
  const response = await apiService.post(`cases/${caseId}/caseflag`, { flagCode: caseFlagCode });
  return response.data;
};

export const useEconsultFlagService = () => {
  return useQuery<EconsultCaseFlag[]>(['useEconsultFlagService'], () => fetchEconsultFlags(), {
    refetchOnWindowFocus: false
  });
};

export const useAddCaseFlag = () => {
  return useMutation({
    mutationFn: ({ caseId, caseFlagCode }: { caseId: number; caseFlagCode: string }) =>
      addCaseFlag(caseId, caseFlagCode)
  });
};
