import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { HoursEntity } from '@oh-vcp/components-common';
import TimeService from '@oh-vcp/components-ui/src/timeService';

interface HoursListingItemProps {
  hours?: HoursEntity[];
}

const HoursListingItem: FC<HoursListingItemProps> = ({ hours }) => {
  return (
    <Box>
      {hours &&
        hours.length > 0 &&
        hours.map((hour) => (
          <Typography variant="body1" key={hour.open}>
            {`${TimeService.formatUtcHour(hour.open)} - ${TimeService.formatUtcHour(hour.close)}`}
          </Typography>
        ))}
      {(!hours || hours.length === 0) && <Typography>Closed</Typography>}
    </Box>
  );
};

HoursListingItem.defaultProps = {
  hours: []
};

export default HoursListingItem;
