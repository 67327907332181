import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { CaseDetailsResponsePayloadDTO } from '@oh-vcp/components-common';
import CaseService from '@oh-vcp/components-web/src/services/caseService';

const useCaseDetails = (caseId: number | undefined | null) => {
  return useQuery<CaseDetailsResponsePayloadDTO, AxiosError>(
    ['caseDetails', caseId],
    () => CaseService.getCaseDetails(caseId!),
    {
      enabled: !!caseId,
      refetchOnWindowFocus: false
    }
  );
};

export default useCaseDetails;
