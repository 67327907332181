import { Box, styled, Typography } from '@mui/material';
import { EconsultRestrictionNoteSection } from '@oh-vcp/components-common';
import React, { FC, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface EconsultRestrictionNoteProps {
  note: string;
  properties: EconsultRestrictionNoteSection[];
}

const RestrictionNoteContainer = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.primary.custom5,
  borderStyle: 'solid',
  borderWidth: 1,
  paddingLeft: 10,
  paddingRight: 10,
  paddingBottom: 10
}));

const EconsultRestrictionNote: FC<EconsultRestrictionNoteProps> = ({ note, properties }) => {
  const [isNoteOpen, setIsNoteOpen] = useState<boolean>(false);
  const ExpandComponent = isNoteOpen ? ArrowDropUpIcon : ArrowDropDownIcon;
  return (
    <>
      <Typography variant="body2" color="primary.custom5">
        {note}
        <ExpandComponent
          sx={{ position: 'relative', top: 5, cursor: 'pointer' }}
          onClick={() => setIsNoteOpen(!isNoteOpen)}
        />
      </Typography>
      {isNoteOpen && (
        <RestrictionNoteContainer>
          {properties.map((p, i) => (
            <Box key={i}>
              <Typography variant="body1b" color="base.grey4" component="p" mt={2}>
                {p.title}
              </Typography>
              {p.properties.map((prop, j) => (
                <Typography variant="body2" key={j}>
                  {prop.name}
                  {prop.name ? ': ' : ''}
                  {prop.value}
                </Typography>
              ))}
            </Box>
          ))}
        </RestrictionNoteContainer>
      )}
    </>
  );
};

export default EconsultRestrictionNote;
