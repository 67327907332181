import { AssignerPrograms, CaseDetails, TimeOption } from '@oh-vcp/components-common';
import React, { FC, PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

interface EconsultCaseDetailsContextProps {
  caseDetails: CaseDetails | undefined;
  userRole: string | undefined;
  timeOptions: TimeOption[] | undefined;
  setCaseDetails: React.Dispatch<React.SetStateAction<CaseDetails | undefined>>;
  setTimeOptions: (options: TimeOption[] | undefined) => void;
  setUserRole: (userRole: string | undefined) => void;
  assignerPrograms: AssignerPrograms | undefined;
  setAssignerPrograms: React.Dispatch<React.SetStateAction<AssignerPrograms | undefined>>;
}

export const EconsultCaseDetailsContext = createContext<
  EconsultCaseDetailsContextProps | undefined
>(undefined);

export const useEconsultCaseDetailsContext = () => {
  const value = useContext(EconsultCaseDetailsContext);
  if (!value) {
    throw new Error(
      'no value found for useEconsultCaseDetailsContext, did you forget to wrap it in a provider?'
    );
  }
  return value;
};

export const EconsultCaseDetailsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [caseDetails, setCaseDetails] = useState<CaseDetails | undefined>(undefined);
  const [timeOptions, setTimeOptions] = useState<TimeOption[] | undefined>(undefined);
  const [userRole, setUserRole] = useState<string | undefined>(undefined);
  const [assignerPrograms, setAssignerPrograms] = useState<AssignerPrograms | undefined>(undefined);
  const value = useMemo(() => {
    return {
      caseDetails,
      setCaseDetails,
      timeOptions,
      setTimeOptions,
      userRole,
      setUserRole,
      assignerPrograms,
      setAssignerPrograms
    };
  }, [caseDetails, timeOptions, userRole, assignerPrograms]);

  return (
    <EconsultCaseDetailsContext.Provider value={value}>
      {children}
    </EconsultCaseDetailsContext.Provider>
  );
};
