import { EconsultUser } from '@oh-vcp/components-common';
import { useQuery } from 'react-query';
import apiService from './apiService';

const fetchMyConsultants = async (): Promise<EconsultUser[]> => {
  const response = await apiService.get('users/selfserve/myconsultants');
  return response?.data;
};

const useConsultantService = () => {
  return useQuery<EconsultUser[]>(['useConsultantService'], () => fetchMyConsultants(), {
    refetchOnWindowFocus: false
  });
};

export default useConsultantService;
