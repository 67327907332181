// route-wrappers.tsx
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { storageUtils, urlUtils, logUtils } from '@oh-vcp/components-common';
import { ProcessInProgress } from '@oh-vcp/components-ui';
import { useAuth } from './AuthContext';
import { refreshToken } from '../services/openidconnectService';

type RouteProps = {
  children: React.ReactNode;
};

export const PrivateRoute: React.FC<RouteProps> = ({ children }) => {
  const { t } = useTranslation();
  const { user, login, handleAndValidateToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!user) {
      const callAsyncFunc = async () => {
        try {
          logUtils.logger.info('PrivateRoute no user found');
          storageUtils.storeOriginalPath(urlUtils.getPath(window.location.href));
          if (!storageUtils.getUserToken()) {
            login(false);
          } else {
            // todo: Maybe combine the two?? not refreshing the token is resulting 403
            // todo: Maybe parse JWT and check the time validity??
            const _token = await refreshToken();
            const _user = await handleAndValidateToken(false);
            logUtils.logger.info('PrivateRoute user reset', _token, _user);
          }
          setIsLoading(false);
        } catch (error) {
          logUtils.logger.error('Error fetching user:', error);
        }
      };

      callAsyncFunc();
    } else {
      logUtils.logger.info('PrivateRoute no need to reset user');
      setIsLoading(false);
    }
  }, [user, handleAndValidateToken]);

  useEffect(() => {
    const handleWindowFocus = () => {
      if (document.hidden) {
        return;
      }
      logUtils.logger.info('handleWindowFocus');
      if (!storageUtils.getUserToken()) {
        // attempt to detect the state when no tokens in the storage, but user is exist
        // means we switch to tab that was laready authenticated
        // window.location.reload();
        login(true);
      }
    };

    window.addEventListener('visibilitychange', handleWindowFocus);
    return () => {
      window.removeEventListener('visibilitychange', handleWindowFocus);
    };
  }, []);

  if (isLoading) return <ProcessInProgress title={t('loginInProgress')} />;
  return <>{user && children}</>;
};

export default PrivateRoute;
