import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ButtonToggleItem } from '@oh-vcp/components-common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ButtonToggleGroupProps {
  items: ButtonToggleItem[];
  value: string;
  onSelected: (value: string) => void;
}

const ButtonToggleGroup: FC<ButtonToggleGroupProps> = ({ items, value, onSelected }) => {
  const { t } = useTranslation();
  const handleChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
    if (value !== null) {
      onSelected(value);
    }
  };
  return (
    <ToggleButtonGroup exclusive value={value} onChange={handleChange} color="primary">
      {items.map((item) => (
        <ToggleButton key={item.value} value={item.value}>
          {t(item.translationKey)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ButtonToggleGroup;
