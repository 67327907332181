import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface SimplePaletteColorOptions {
    custom?: string;
    custom2?: string;
    custom3?: string;
    custom4?: string;
    custom5?: string;
    grey?: string;
    white?: string;
  }
  interface PaletteColor {
    custom?: string;
    custom2?: string;
    custom3?: string;
    custom4?: string;
    custom5?: string;
    grey?: string;
    white?: string;
  }
  interface BaseColorOptions {
    grey1: string;
    grey2: string;
    grey3: string;
    grey4: string;
    grey5: string;
    grey6: string;
    grey7: string;
    grey8: string;
  }
  interface PaletteOptions {
    base?: BaseColorOptions;
  }
  interface Palette {
    base: BaseColorOptions;
  }
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    body1b: true;
  }
}

const OHTheme = createTheme({
  palette: {
    mode: 'light', // 'light' or 'dark'
    primary: {
      main: '#0D47A1', // rich blue - use for toolbar button icons, some headings
      light: '#F2FBFF', // used for footer background
      dark: '#0D47A1',
      custom: '#D7DBEC',
      custom2: '#F3F7FD', // light blue - some card backgrounds
      custom3: '#82C12E', // green - some headings
      custom4: '#F4F7FC',
      custom5: '#F4A747', // orange - note text
      grey: '#737373', // used for small captions on cards,
      white: '#FFFFFF'
    },
    error: {
      main: '#EA4335'
    },
    success: {
      main: '#118847'
    },
    base: {
      grey1: '#1A1A1A', // use for action menu icons
      grey2: '#303030',
      grey3: '#535353',
      grey4: '#888888', // disabled links
      grey5: '#CFCFCF',
      grey6: '#EEEEEE', // background
      grey7: '#F8F8F8',
      grey8: '#F8F8F8'
    }
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    button: {
      textTransform: 'none'
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 400,
      lineHeight: 1.0625
    },
    h2: {
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: 1.416
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: 1.33
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.5
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    h6: {
      fontSize: '0.8rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    body1b: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.5
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.4
    },
    body3: {
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: 1.66
    },
    body4: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.66
    }
  }
});

OHTheme.components = {
  MuiAlert: {
    styleOverrides: {
      standardError: {
        backgroundColor: '#FAEFF0',
        color: '#000000',
        borderLeft: '4px solid #C10309'
      },
      standardWarning: {
        backgroundColor: '#FEFAEB',
        color: '#000000',
        borderLeft: '4px solid #E6B341'
      },
      standardInfo: {
        backgroundColor: '#F3FBFF',
        color: '#000000',
        borderLeft: '4px solid #3E74D3'
      },
      standardSuccess: {
        backgroundColor: '#EBF5EA',
        color: '#000000',
        borderLeft: '4px solid #318945'
      }
    }
  },
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        margin: 0
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: OHTheme.palette.base.grey4,
          borderColor: OHTheme.palette.base.grey4
        }
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&:hover': {
          color: '#FFF',
          backgroundColor: OHTheme.palette.primary.main,
          '& .MuiListItemIcon-root': {
            color: '#FFF'
          }
        }
      }
    }
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        '&:hover': {
          color: '#FFF'
        }
      }
    }
  }
};
// OHTheme.typography.body2 = {
//   fontSize: '1rem',
//   [OHTheme.breakpoints.up('xl')]: {
//     fontSize: '1.4rem'
//   }
// };

// OHTheme.typography.caption = {
//   fontSize: '0.8rem',
//   [OHTheme.breakpoints.up('xl')]: {
//     fontSize: '1.2rem'
//   }
// };

// OHTheme.typography.h6 = {
//   fontSize: '0.85rem',
//   fontWeight: 700,
//   color: OHTheme.palette.primary.main
// };

// OHTheme.typography.h5 = {
//   fontSize: '0.9rem',
//   fontWeight: 700
// };

// OHTheme.typography.h4 = {
//   fontSize: '0.9rem',
//   fontWeight: 600
// };

// OHTheme.typography.h3 = {
//   fontSize: '1.2rem',
//   fontWeight: 700,
//   color: OHTheme.palette.primary.main
// };

// OHTheme.typography.body1 = {
//   fontSize: '0.85rem',
//   fontFamily: 'Open Sans, sans-serif',
//   lineHeight: 1.7
// };

export default OHTheme;
