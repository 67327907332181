import { SelectedResult } from '@oh-vcp/components-common';
import React, { FC, PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

type SearchResultsViewContextProps = {
  isMinimalView: boolean;
  setIsMinimalView: (isMinimalView: boolean) => void;
  selectedResult: SelectedResult | undefined;
  setSelectedResult: (result: SelectedResult | undefined) => void;
};

export const SearchResultsViewContext = createContext<SearchResultsViewContextProps | undefined>(
  undefined
);

export const useSearchResultsViewContext = () => {
  const value = useContext(SearchResultsViewContext);
  if (!value) {
    throw new Error(
      'no value found for SearchResultsViewContext, did you forget to wrap it in a provider?'
    );
  }
  return value;
};

export const SearchResultsViewProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isMinimalView, setIsMinimalView] = useState<boolean>(false);
  const [selectedResult, setSelectedResult] = useState<SelectedResult | undefined>();
  const value = useMemo(() => {
    return {
      isMinimalView,
      setIsMinimalView,
      selectedResult,
      setSelectedResult
    };
  }, [isMinimalView, selectedResult]);
  return (
    <SearchResultsViewContext.Provider value={value}>{children}</SearchResultsViewContext.Provider>
  );
};
