import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';
import EnTranslation from './en.json';
// import FrTranslation from './fr.json';
// import RuTranslation from './ru.json';

const resources = {
  en: {
    translation: EnTranslation
  }
  // fr: {
  //   translation: FrTranslation
  // }
  // ru: {
  //   translation: RuTranslation
  // }
};

const i18n = createInstance({
  fallbackLng: 'en',
  debug: true,

  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  resources
});

i18n.use(initReactI18next).init();

export default i18n;
