import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { apiService } from '@oh-vcp/components-web';

const fetchUserDelegates = async (userId: number | undefined): Promise<any> => {
  let url = `cases/delegators/${userId}`;
  const response = await apiService.get(url);
  return response.data;
};

const useUserDelegates = (userId: number | undefined) => {
  return useQuery<any, AxiosError>(['userDelegates', userId], () => fetchUserDelegates(userId), {
    enabled: !!userId,
    refetchOnWindowFocus: false
  });
};

export default useUserDelegates;
