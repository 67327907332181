import { Box, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { NameUtils } from '@oh-vcp/components-common';
import { LocationsEntity } from '@oh-vcp/components-common/src/models/fho/FhoEntity';
import {
  ResultLocationLabelContainer,
  MapService,
  ResultLocationHoursContainer
} from '@oh-vcp/components-ui';
import withToggle from '@oh-vcp/components-ui/src/toggleContainer/ToggleContainer';
import TimeService from '@oh-vcp/components-ui/src/timeService';
import SearchResultLocationHoursItem from '../searchResultLocationHoursItem/SearchResultLocationHoursItem';

interface SearchResultLocationItemProps {
  location: LocationsEntity;
}

const SearchResultLocationItem: React.FC<SearchResultLocationItemProps> = ({ location }) => {
  const SearchResultLocationHoursItemWithToggle = withToggle(SearchResultLocationHoursItem);
  const { address, city, postalCode, province, hours } = location;
  const isOpenToday = hours ? TimeService.isOpenToday(hours) : false;
  const closingTime = TimeService.getClosingTime(hours);

  return (
    <Box sx={{ pb: 1, textAlign: 'left' }}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={4}>
          <ResultLocationLabelContainer>
            <Link
              target="_blank"
              onClick={(e) => e.stopPropagation()}
              href={MapService.getMapUrl({
                address,
                city,
                postalCode,
                province
              })}>
              <Typography variant="body1">
                {NameUtils.makeAddressName(address, city, '', '')}
              </Typography>
            </Link>

            <ResultLocationHoursContainer>
              {hours && (
                <SearchResultLocationHoursItemWithToggle
                  isToggled={false}
                  toggleState={() => {}}
                  isOpenToday={isOpenToday}
                  hours={hours}
                  closingTime={closingTime}
                />
              )}
            </ResultLocationHoursContainer>
          </ResultLocationLabelContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchResultLocationItem;
