import { TimeOption } from '@oh-vcp/components-common';
import { apiService } from '@oh-vcp/components-web';
import { useQuery } from 'react-query';

const fetchTimeOptions = async (): Promise<TimeOption[]> => {
  var response = await apiService.get('/cases/kpi/timeoptions');
  return response.data;
};

const useTimeOptions = () => {
  return useQuery<TimeOption[]>({
    queryFn: fetchTimeOptions,
    refetchOnWindowFocus: false
  });
};

export default useTimeOptions;
