/* eslint-disable no-plusplus */
const isInt = (n: any) => {
  try {
    Number(n);
  } catch (e) {
    return false;
  }
  return Number(n) % 1 === 0;
};

const addDigits = (num: number) => {
  let number = num;
  let sum = 0;
  while (number > 0) {
    const digit = number % 10;
    sum += digit;
    number = Math.floor(number / 10);
  }
  return sum;
};

const doubleAndAddDigits = (num: number) => {
  return addDigits(num * 2);
};

const getLastDigit = (num: number) => {
  return num % 10;
};

const mod10CheckDigitValid = (ohipNum: any) => {
  const ohipArr = ohipNum.split('');
  for (let i = 0; i <= 9; i++) {
    if (i % 2 === 0) {
      ohipArr[i] = doubleAndAddDigits(Number(ohipArr[i]));
    } else {
      ohipArr[i] = Number(ohipArr[i]);
    }
  }
  let sumOfDigits = 0;
  for (let j = 0; j < ohipArr.length - 1; j++) {
    sumOfDigits += ohipArr[j];
  }
  return ohipArr[9] === 10 - getLastDigit(sumOfDigits);
};

const isOhipNumberValid = (ohipNumber: any): boolean => {
  if (!isInt(ohipNumber) || ohipNumber.length !== 10 || !mod10CheckDigitValid(ohipNumber)) {
    return false;
  }
  return true;
};

const OhipValidationService = {
  isOhipNumberValid
};

export default OhipValidationService;
