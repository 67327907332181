import React, { FC } from 'react';
import { Box, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ActionButton, ActionMenuItemLabel, PrintIcon } from '@oh-vcp/components-ui';

interface PrintActionProps {
  translationKey: string;
  actionType: 'menu' | 'toolbar';
}

const PrintAction: FC<PrintActionProps> = ({ translationKey, actionType }) => {
  const { t } = useTranslation();
  const handlePrint = (event: React.MouseEvent<any>) => {
    event.stopPropagation();
    window.print();
  };

  return (
    <Box style={{ flexGrow: 1 }}>
      {actionType === 'toolbar' ? (
        <ActionButton onClick={handlePrint}>
          <SvgIcon component={PrintIcon} sx={{ mt: 1, fill: 'primary.main' }} />
          <Typography variant="h4">{t(translationKey)}</Typography>
        </ActionButton>
      ) : (
        <ActionMenuItemLabel onClick={handlePrint}>
          <SvgIcon component={PrintIcon} sx={{ mt: 1, fill: 'base.grey1' }} />
          {t(translationKey)}
        </ActionMenuItemLabel>
      )}
    </Box>
  );
};

export default PrintAction;
