import React, { FC, HTMLAttributeAnchorTarget } from 'react';
import { Link, Typography } from '@mui/material';

interface TextLinkProps {
  label: string;
  url: string;
  id: string;
  target?: HTMLAttributeAnchorTarget;
}

const TextLink: FC<TextLinkProps> = ({ label, url, id, target }) => {
  return (
    <Link href={url} id={id} target={target}>
      <Typography sx={{ mr: 2 }} variant="body1">
        {label}
      </Typography>
    </Link>
  );
};

export default TextLink;
