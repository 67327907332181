import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProcessInProgress } from '@oh-vcp/components-ui';
import { useAuth } from './AuthContext';

const LoginCallback = () => {
  const { handleAndValidateToken } = useAuth();
  handleAndValidateToken(true);
  const { t } = useTranslation();

  return <ProcessInProgress title={t('loginInProgress')} />;
};

export default LoginCallback;
