import { Box, Link, Stack, SvgIcon, Typography, styled } from '@mui/material';

import { AppConfig, MapMarkerResult, NameUtils, Policy } from '@oh-vcp/components-common';
import {
  LocationDotLightActive,
  MapService,
  ResultHeaderIcon,
  UserLargeBlueSVGIcon,
  HandHoldingHeartIcon,
  HospitalIcon,
  BuildingUserSmSVGIcon,
  ActionItemParams
} from '@oh-vcp/components-ui';
import { InfoWindowF, Marker } from '@react-google-maps/api';
import React, { FC, useEffect, useRef, useState } from 'react';
import SearchListToolbar from '../searchListToolbar/SearchListToolbar';

interface MapMarkerWithInfoWindowProps {
  marker: MapMarkerResult;
  isActive: boolean;
  icon: string;
  activeIcon: string;
  policy?: Policy | undefined;
  actionParams?: ActionItemParams;
  hideInfoWindow?: boolean | null;
  onClick: () => void;
}

const MapMarkerWithInfoWindow: FC<MapMarkerWithInfoWindowProps> = ({
  marker,
  isActive,
  icon,
  activeIcon,
  policy,
  actionParams,
  hideInfoWindow,
  onClick
}) => {
  const markerRef = useRef<google.maps.Marker | null>(null);
  const infoWindowRef = useRef<google.maps.InfoWindow | null>(null);
  const [streetViewImage, setStreetViewImage] = useState<string | null>(null);
  const { lat, lng, displayName, address, profileUrl } = marker;
  const { city, address: street, postalCode, province } = address;
  const handleOnMarkerLoad = (newMarker: google.maps.Marker) => {
    markerRef.current = newMarker;
  };

  const InfoWindowIcon = styled(Box)(() => ({
    flexBasis: 1
  }));

  const MarkerToolbarContainer = styled(Box)(() => ({
    position: 'absolute',
    right: -11,
    bottom: 55
  }));

  useEffect(() => {
    if (isActive && markerRef.current) {
      const panorama = new google.maps.StreetViewPanorama(document.createElement('div'), {
        position: { lat, lng },
        pov: { heading: 165, pitch: 0 },
        zoom: 1
      });
      const streetViewService = new google.maps.StreetViewService();
      streetViewService.getPanorama(
        {
          location: { lat, lng },
          radius: 50
        },
        (data, status) => {
          if (status === 'OK' && data && data.location) {
            panorama.setPano(data.location.pano);
            const streetViewUrl = panorama.getPano();
            setStreetViewImage(
              `https://maps.googleapis.com/maps/api/streetview?size=200x100&pano=${streetViewUrl}&key=${AppConfig.googleAPIKey}`
            );
          }
        }
      );
    } else {
      setStreetViewImage(null);
    }
  }, [isActive, lat, lng]);

  const getMarkerIcon = (): React.ElementType => {
    switch (marker.resultType) {
      case 'hs_services':
        return BuildingUserSmSVGIcon;
      case 'site_with_systems':
        return HospitalIcon;
      case 'community_services':
        return HandHoldingHeartIcon;
      case 'people':
      default:
        return UserLargeBlueSVGIcon;
    }
  };

  return (
    <>
      <Marker
        position={{
          lat,
          lng
        }}
        icon={{
          url: isActive ? activeIcon : icon
        }}
        onClick={() => onClick()}
        onLoad={handleOnMarkerLoad}
        options={{
          zIndex: isActive ? 2 : 0
        }}
      />
      {isActive && markerRef.current && !hideInfoWindow && (
        <InfoWindowF
          anchor={markerRef.current}
          onLoad={(infoWindow) => {
            infoWindowRef.current = infoWindow;
          }}>
          <>
            {streetViewImage && (
              <img src={streetViewImage} alt="Street View" style={{ padding: 0 }} />
            )}
            <Stack direction="row" sx={{ p: 1 }}>
              {displayName && (
                <>
                  <InfoWindowIcon>
                    <ResultHeaderIcon>
                      <SvgIcon
                        component={getMarkerIcon()}
                        inheritViewBox
                        sx={{ width: '15px', height: '15px' }}
                      />
                    </ResultHeaderIcon>
                  </InfoWindowIcon>
                  <Link href={profileUrl} pl={0}>
                    <Typography variant="body1" flexGrow={1}>
                      {displayName}
                    </Typography>
                  </Link>
                </>
              )}
              {policy && actionParams && (
                <MarkerToolbarContainer>
                  <SearchListToolbar policy={policy} actionParams={actionParams} />
                </MarkerToolbarContainer>
              )}
            </Stack>
            <Stack direction="row" sx={{ px: 2, pb: 1 }} columnGap={2}>
              <InfoWindowIcon>
                <LocationDotLightActive />
              </InfoWindowIcon>
              <Link href={MapService.getMapUrl(address)} target="_blank">
                {NameUtils.makeAddressName(street, city, province, postalCode)}
              </Link>
            </Stack>
          </>
        </InfoWindowF>
      )}
    </>
  );
};

export default MapMarkerWithInfoWindow;
