import { AppConfig, UserDTO, storageUtils, urlUtils, logUtils } from '@oh-vcp/components-common';
import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getUserTokenInfo, doLogout } from '../services/openidconnectService';

type AuthContextType = {
  user: UserDTO | null;
  setUser: React.Dispatch<React.SetStateAction<UserDTO | null>>;
  login: (force: boolean) => void;
  logout: () => void;
  handleAndValidateToken: (redirect: boolean) => Promise<UserDTO | null>;
};
type UserTokenInfoType = {
  details: UserDTO;
};

type AuthProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserDTO | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const login = (force: boolean) => {
    // isTokenExist: Token in local store,
    // exist: validateToken
    // Not exist: Immediately login to MT
    //      https://api2.awsdevotn.ca/openidconnect/login?redirectUri=https://localhost:9000/login_callback
    // ?? loginCallback
    // success handler: validateToken
    logUtils.logger.info('login invoked user: ', user, force);
    if (!user || force) {
      const rootUrl = AppConfig.apiHost;
      const redirectUri = `${window.location.origin}/login_callback`;
      const appState: string | undefined = searchParams.get('appState') ?? undefined;
      const originalUri: string | undefined = searchParams.get('originalUri') ?? undefined;

      // Note: To test this, the redirect URI must be https. Start the server with: "set HTTPS=true&&npm start"
      if (!urlUtils.isHttps(redirectUri)) {
        throw new Error('Auth cannot work if redirectUri is not HTTPS');
      }
      if (!rootUrl) {
        throw new Error('Login URL not defined. Check environment variables.');
      }
      logUtils.logger.info('appState: ', appState);
      logUtils.logger.info('originalUri: ', originalUri);
      if (originalUri) {
        storageUtils.storeOriginalPath(urlUtils.getPath(decodeURIComponent(originalUri)));
      }

      const redirectUriEnc = encodeURIComponent(redirectUri);

      const loginUrl: string = `${rootUrl}openidconnect/login?redirectUri=${redirectUriEnc}`;

      logUtils.logger.info(`Redirecting to loginUrl: ${loginUrl}`);
      window.location.href = loginUrl;
    }
  };

  const handleAndValidateToken = async (redirect: boolean) => {
    logUtils.logger.info('handleAndValidateToken invoked user: ', user);

    const accessToken: string | null =
      searchParams.get('access_token') ?? storageUtils.getUserToken();
    // let expiresIn: number = parseInt(searchParams.get('expires_in') + '', 10);
    // const refreshToken: string | null =
    //   searchParams.get('refresh_token') ?? storageUtils.getRefreshToken();
    const originalPath = storageUtils.getOriginalPath();

    // validate token with the following call
    const data = await getUserTokenInfo<UserTokenInfoType>(accessToken);
    const _user = data?.details;
    if (!_user) {
      return null;
    }
    setUser(_user);
    storageUtils.storeUserToken(accessToken);
    // storageUtils.storeRefreshToken(refreshToken);
    storageUtils.clearOriginalPath();
    if (redirect) {
      originalPath ? navigate(`${originalPath}`) : navigate('/');
    }
    return _user;
  };

  const logout = () => {
    doLogout();
  };

  const contextValue = useMemo(
    () => ({ user, setUser, login, handleAndValidateToken, logout }),
    [user]
  );
  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
