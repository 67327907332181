import React, { FC, useEffect, useState } from 'react';
import Uppy, { Meta, UppyFile } from '@uppy/core';
import { DragDrop, ProgressBar } from '@uppy/react';
import '@uppy/drag-drop/dist/style.min.css';
import { CircularProgress, Typography } from '@mui/material';
import CaseService from '../services/caseService';

interface DragDropFileUploadProps {
  onUploadComplete?: (fileId: number, fileName: string) => void;
}

const DragDropFileUpload: FC<DragDropFileUploadProps> = ({ onUploadComplete }) => {
  const [completedUploads, setCompletedUploads] = useState(0);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uppy] = useState(
    () =>
      new Uppy({
        restrictions: {
          maxFileSize: 500000000, // 500MB max file size
          maxNumberOfFiles: 10,
          allowedFileTypes: [
            // Multimedia files
            '.3gpp',
            '.3gpp2',
            '.avi',
            '.flv',
            '.m4a',
            '.mkv',
            '.mov',
            '.mp3',
            '.mp4',
            '.mpg',
            '.mpeg',
            '.ogg',
            '.qt',
            '.wav',
            '.wma',
            '.wmv',

            // Document files
            '.doc',
            '.docx',
            '.pdf',
            '.ppt',
            '.pptx',
            '.pps',
            '.ppsx',
            '.rtf',
            '.txt',
            '.xls',
            '.xlsx',
            '.csv',
            '.xps',

            // Image files
            '.bmp',
            '.dcm',
            '.exif',
            '.gif',
            '.jpg',
            '.jpeg',
            '.jfif',
            '.png',
            '.raw',
            '.tiff',

            // Special medical file type
            '.sia'
          ]
        },
        autoProceed: false
      })
  );
  useEffect(() => {
    const handleFileAdded = async (file: UppyFile<Meta, Record<string, never>>) => {
      setIsLoading(true);
      const progressCallback = (progress: number) => {
        setProgressValue(progress);
      };

      const result = await CaseService.uploadFile(file.data as File, progressCallback);
      if (result && onUploadComplete) {
        setCompletedUploads((prev) => prev + 1);
        onUploadComplete(result.id, result.fileName);
        setIsLoading(false);
      }
    };

    const handleCompleted = () => {
      if (uppy.getFiles().length === completedUploads) {
        uppy.getFiles().forEach((file) => uppy.removeFile(file.id));
        setCompletedUploads(0);
        setProgressValue(0);
      }
    };

    uppy.on('file-added', handleFileAdded);
    uppy.on('upload-success', handleCompleted);

    return () => {
      uppy.off('file-added', handleFileAdded);
      uppy.off('upload-success', handleCompleted);
      uppy.cancelAll();
    };
  }, [uppy, onUploadComplete]);

  useEffect(() => {
    if (completedUploads > 0) {
      const files = uppy.getFiles();
      if (completedUploads === files.length) {
        files.forEach((file) => uppy.removeFile(file.id));
        setCompletedUploads(0);
      }
    }
  }, [completedUploads]);

  return (
    <>
      {isLoading && <CircularProgress />}
      <DragDrop uppy={uppy} width="100%" />
    </>
  );
};

export default DragDropFileUpload;
