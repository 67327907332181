import {
  Box,
  Button,
  Grid,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  TextField,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUserDelegates from '../../api/useUserDelegates';
import { useAuth } from '@oh-vcp/components-web';
import { AppConfig, EconsultUserRoles, storageUtils } from '@oh-vcp/components-common';
import moment from 'moment';

const ReportDropDown = styled(Select<string>)(({ theme }) => ({
  marginLeft: 5,
  color: theme.palette.primary.main,
  minWidth: 100
}));

const EconsultReportsView = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [reportAsType, setReportAsType] = useState<string>('REFERRER');
  const [startDate, setStartDate] = useState<string | undefined>(
    moment().subtract(7, 'days').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string | undefined>(moment().format('YYYY-MM-DD'));
  const [delegators, setDelegators] = useState<any[]>([]);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { data: delegatorsList } = useUserDelegates(user?.userid);

  useEffect(() => {
    const allDelegator = {
      userId: 0,
      firstName: 'All',
      lastName: ''
    };
    let delegatorsArray = [allDelegator];
    delegatorsArray = delegatorsArray.concat(delegatorsList);
    setDelegators(delegatorsArray);
  }, [delegatorsList]);

  const handleReportAsTypeChanged = (event: SelectChangeEvent<string>) => {
    setReportAsType(event.target.value);
  };
  const exportReport = () => {
    var delegatorId =
      !delegators || delegators.length === 0 || !delegators[0].userId ? null : delegators[0].userId;
    const token = storageUtils.getUserToken();
    let url = `${AppConfig.apiHost}cases/activity/export?api_access_token=${token}&mode=${EconsultUserRoles.referrer}&startDate=${startDate}&endDate=${endDate}`;
    if (delegatorId) {
      url += '&delegatorId=' + delegatorId;
    }
    window.location.href = url;
  };
  return (
    <Stack p={3}>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row">
            <Typography variant="body1" color="base.grey3" pt={0.5}>
              {t('Case.reports.caseReportsAs')}
            </Typography>
            <ReportDropDown
              variant="standard"
              value={reportAsType}
              onChange={handleReportAsTypeChanged}>
              <MenuItem value="REFERRER">Requester</MenuItem>
              <MenuItem value="SPECIALIST">Specialist</MenuItem>
              <MenuItem value="TRIAGE">Triage Assigner</MenuItem>
            </ReportDropDown>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="base.grey4" mt={3}>
            {t('Case.reports.specifyDateRange')}
          </Typography>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Stack direction="row" mt={3} pl={isMobile ? 0 : 3}>
            <Box>
              <Typography>{t('Case.reports.startDate')}</Typography>
              <TextField
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Stack direction="row" mt={3} pl={3}>
            <Box>
              <Typography>{t('Case.reports.endDate')}</Typography>
              <TextField type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </Box>
          </Stack>
        </Grid>
        <Grid xs={12} lg={12} pt={4}>
          <Button variant="contained" color="primary" onClick={exportReport}>
            {t('Case.reports.generateReport')}
          </Button>
        </Grid>
        <Grid xs={12} lg={8} pt={3}>
          <Typography variant="body4" component="p">
            {t('Case.reports.note')}
          </Typography>
          <Typography variant="body4" component="p">
            {t('Case.reports.ifYouHaveAccessed')}
            <Link href="mailto:OH-OTN-Privacy@ontariohealth.ca" ml={0.5}>
              <Typography variant="body4">OH-OTN-Privacy@ontariohealth.ca</Typography>
            </Link>
            <Typography variant="body4" ml={0.5}>
              {t('Case.reports.immediately')}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default EconsultReportsView;
