// src/ListView.tsx
import React, { useEffect, useRef } from 'react';
import { Button, Grid, Typography, styled } from '@mui/material';
import { SelectedResult } from '@oh-vcp/components-common';
import DataItemRenderer from '../data/renderers/DataItemRenderer';
import { GridItem } from '../styles/SharedStyles';

const CenteredGridItem = styled(Grid)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  };
});

interface ListViewProps {
  title?: string;
  items: DataItemRenderer[];
  loadNextPage: () => void;
  isLoadNextPageAvailable: boolean;
  selectedResult: SelectedResult | undefined;
  parentRef?: React.MutableRefObject<HTMLDivElement | null>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ListView: React.FC<ListViewProps> = ({
  title = '',
  items,
  loadNextPage,
  isLoadNextPageAvailable,
  selectedResult,
  parentRef
}) => {
  const viewRef = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const handleScroll = () => {
      const { innerHeight } = window;
      const isAtBottom = viewRef.current
        ? viewRef.current.getBoundingClientRect().bottom <= innerHeight
        : false;
      if (isAtBottom) {
        // TODO find out why on scroll it works only first time with button is fine
        // loadNextPage();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (selectedResult) {
      const index = items.findIndex(
        (item) => item.getId() === selectedResult.id.toString() && item.type === selectedResult.type
      );
      if (index !== -1 && itemRefs.current[index]) {
        const headerHeight = 60;
        const item = itemRefs.current[index];
        if (item && parentRef && parentRef.current) {
          const containerTop = parentRef.current.getBoundingClientRect().top;
          const itemTop = item.getBoundingClientRect().top;
          const offsetPosition = itemTop - containerTop - headerHeight;

          parentRef.current.scrollTo({
            top: parentRef.current.scrollTop + offsetPosition
          });
        }
      }
    }
  }, [selectedResult, items]);

  return (
    <Grid container ref={viewRef} spacing={2} sx={{ pt: 1 }}>
      {/* <CenteredGridItem item>
        <Button variant="contained" size="large" onClick={() => loadNextPage()}>
          <Typography>Debug More results {items.length}</Typography>
        </Button>
      </CenteredGridItem> */}
      {items.map((item, index) => (
        <Grid
          item
          key={index}
          xs={12}
          style={{ marginBottom: 5 }}
          ref={(el) => {
            itemRefs.current[index] = el;
          }}>
          <GridItem sx={{ p: 0, height: '100%' }}>
            <>{item.renderListItem()}</>
          </GridItem>
        </Grid>
      ))}
      {isLoadNextPageAvailable && (
        <CenteredGridItem item>
          <Button variant="contained" size="large" onClick={() => loadNextPage()}>
            <Typography>More results</Typography>
          </Button>
        </CenteredGridItem>
      )}
    </Grid>
  );
};

export default ListView;
