import React from 'react';
import { Stack, styled } from '@mui/material';
import { useNotifierContext } from './NotifierContext';
import DismissibleNotification from './DismissibleNotification';

const StyledStack = styled(Stack)(() => ({
  position: 'absolute',
  zIndex: 1200,
  width: '100%'
}));

const NotificationsViewer = () => {
  const { notifications, removeNotification } = useNotifierContext();
  return (
    <StyledStack rowGap={1} direction="column">
      {notifications.map((notification) => (
        <DismissibleNotification
          key={notification.id}
          notification={notification}
          onDismiss={() => removeNotification(notification.id ? notification.id : -1)}
        />
      ))}
    </StyledStack>
  );
};

export default NotificationsViewer;
