import React, { FC } from 'react';
import { Autocomplete, TextField, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NameUtils, UserDTO } from '@oh-vcp/components-common';
import { TextFieldNoOutline } from '@oh-vcp/components-ui';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';

interface EconsultSpecialistSearchProps {
  options: UserDTO[];
  onInputChange: (inputValue: string) => void;
  onSelectionChange?: (selectedValue: UserDTO | null) => void;
}

const EconsultSpecialistSearchTextField = styled(TextFieldNoOutline)({
  '& .MuiOutlinedInput-root': {
    paddingLeft: 0,
    '&.Mui-focused fieldset': {
      borderColor: 'transparent'
    }
  },
  '& .MuiOutlinedInput-input': {
    paddingLeft: '0px !important'
  }
});

const EconsultSpecialistSearch: FC<EconsultSpecialistSearchProps> = ({
  options,
  onInputChange,
  onSelectionChange
}) => {
  const { t } = useTranslation();
  const { caseDetails } = useEconsultCaseDetailsContext();
  return (
    <Autocomplete
      options={options}
      value={caseDetails?.participant?.consultant}
      getOptionLabel={(option: UserDTO | string) => {
        if (typeof option === 'string') {
          return option;
        }
        return NameUtils.makeName(
          option.lastName || '',
          option.firstName,
          option.salutation,
          option.specialty,
          option.middleName,
          null,
          option.city,
          option.lhin?.number?.toString()
        );
      }}
      freeSolo
      onChange={(event, newValue: UserDTO | string | null) => {
        if (onSelectionChange) {
          if (typeof newValue !== 'string') {
            onSelectionChange(newValue);
          }
        }
      }}
      onInputChange={(event, newInputValue) => {
        onInputChange(newInputValue);
      }}
      renderInput={(params) => (
        <EconsultSpecialistSearchTextField
          {...params}
          placeholder={t('Case.draft.searchSpecialist') as string}
        />
      )}
    />
  );
};

export default EconsultSpecialistSearch;
