import React, { FC } from 'react';
import {
  Badge,
  Button,
  MenuList,
  MenuItem,
  Paper,
  Popper,
  Grow,
  ClickAwayListener,
  useTheme,
  useMediaQuery
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ToolbarActionItem } from '@oh-vcp/components-common';
import { ActionItemParams } from '@oh-vcp/components-ui/src/types/ActionItemParams';
import { MenuListPopper, ActionButton } from '@oh-vcp/components-ui/src/StyledComponents';
import { ConditionalShow } from '@oh-vcp/components-ui';
import UpdatesAction from '../actions/UpdatesAction';
import PrintAction from '../actions/PrintAction';
import EconsultAction from '../actions/EconsultAction';
import EditAction from '../actions/EditAction';
import ApplyForProgEconPermissionAction from '../actions/ApplyForProgEconPermissionAction';

// passed in from ProfileHeaderCard.tsx
interface ProfileActionMenuProps {
  params: ActionItemParams;
  toolbarActionItems: ToolbarActionItem[];
  menuType: string | 'default';
}

const ProfileActionMenu: FC<ProfileActionMenuProps> = ({
  params,
  toolbarActionItems,
  menuType
}) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const prevOpen = React.useRef(open); // return focus to the button when we transition from !open -> open
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [ButtonComponent, PopperComponent] =
    menuType === 'action' ? [ActionButton, Popper] : [Button, MenuListPopper];
  const badgeContent = +(
    params.suggestUpdatePendingCount > 0 &&
    +!isDesktop &&
    menuType !== 'list'
  );
  params.onActionComplete = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  });

  React.useEffect(() => {
    if (isDesktop) {
      if (menuType !== 'action') {
        setOpen(false);
      }
    } else if (menuType === 'action') {
      setOpen(false);
    }
  }, [isDesktop]);

  const handleToggle = (event: Event | React.SyntheticEvent) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const menuPos = menuType === 'list' ? 'bottom-end' : 'bottom-start';

  const handleListKeyDown = (event: any) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    } else if (event.key === 'Enter') {
      // Workaround for triggering the menu actions with the keyboard while still keeping the behaviour contained in the child components
      setOpen(false);
      const { id } = event.target;
      document
        .getElementById(id)
        ?.getElementsByTagName('div')[0]
        .getElementsByTagName('div')[0]
        .click();
    }
  };

  return (
    <>
      <ButtonComponent
        ref={anchorRef}
        id={`ProfileActionMenuButton-${menuType}`}
        className="actionMenuButton"
        aria-controls={open ? 'ProfileActionMenu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        tabIndex={0}
        onClick={handleToggle}>
        <Badge color="error" variant="dot" badgeContent={badgeContent}>
          <MoreHorizIcon />
        </Badge>
      </ButtonComponent>
      <PopperComponent
        id={`tooltip-${menuType}`}
        open={open}
        anchorEl={anchorRef.current}
        placement={menuPos}
        transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="ProfileActionMenu"
                  aria-labelledby={`ProfileActionMenuButton-${menuType}`}
                  autoFocusItem={open}
                  autoFocus
                  role="menu"
                  onKeyDown={handleListKeyDown}>
                  <ConditionalShow showWhen={!!(!isDesktop && params.suggestUpdatePendingCount)}>
                    <MenuItem id="action-updates">
                      <UpdatesAction actionType="menu" params={params} />
                    </MenuItem>
                  </ConditionalShow>
                  {toolbarActionItems &&
                    toolbarActionItems.map((actionItem, index) => {
                      const { translationKey, name } = actionItem;
                      // Note: MenuItem must be immediate child of MenuList to function properly (PrintAction, EconsultAction, EditAction, ApplyforProgEconPermissionAction should not wrap a MenuItem)
                      switch (name) {
                        case 'print':
                          return (
                            <MenuItem id="action-print" key={index}>
                              <PrintAction
                                translationKey={translationKey}
                                actionType="menu"
                                key={index}
                              />
                            </MenuItem>
                          );
                        case 'econsult':
                          return (
                            <MenuItem id="action-econsult" key={index}>
                              <EconsultAction
                                params={params}
                                translationKey={translationKey}
                                actionType="menu"
                                key={index}
                              />
                            </MenuItem>
                          );
                        case 'edit':
                          return (
                            <MenuItem id="action-edit" key={index}>
                              <EditAction
                                params={params}
                                translationKey={translationKey}
                                actionType="menu"
                                key={index}
                              />
                            </MenuItem>
                          );
                        case 'econsultApplyForPermission':
                          return (
                            <MenuItem id="action-apply" key={index}>
                              <ApplyForProgEconPermissionAction
                                programId={params.programId || ''}
                                translationKey={translationKey}
                                actionType="toolbar"
                                programName={params.programName || ''}
                              />
                            </MenuItem>
                          );
                        default:
                          return null;
                      }
                    })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </PopperComponent>
    </>
  );
};

export default ProfileActionMenu;
