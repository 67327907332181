import React, { ComponentType, FC, useState } from 'react';

interface ToggleProps {
  isToggled: boolean;
}

function withToggle<P extends ToggleProps>(ToggleComponent: ComponentType<P>): FC<P> {
  const ToggleContainer: FC<P> = ({ ...props }) => {
    const { isToggled } = props;
    const [isOpen, setIsOpen] = useState(isToggled);

    const toggleState = () => {
      setIsOpen(!isOpen);
    };

    return <ToggleComponent {...(props as P)} isToggled={isOpen} toggleState={toggleState} />;
  };

  return ToggleContainer;
}

export default withToggle;
