import { AxiosError } from 'axios';
import { Notification, NotificationType } from '@oh-vcp/components-ui';
/*
    use this factory to generate all types of notification
 */
type NotificationConfig = {
  message: string;
  solution?: string;
};
const getErrorDetails = (err: AxiosError | null) => {
  return err && err.response ? JSON.stringify(err.response.data) : err?.message;
};
const createNotification = (type: NotificationType, config: NotificationConfig): Notification => {
  return {
    type,
    message: config.message,
    solution: config.solution ? config.solution : undefined
  };
};

const createError = (error: AxiosError | null, config: NotificationConfig): Notification => {
  return {
    type: 'error',
    message: config.message,
    solution: config.solution ? config.solution : undefined,
    details: getErrorDetails(error)
  };
};
export const NotificationFactory = {
  createError,
  createNotification
};
