import React, { FC } from 'react';
import { CircularProgress, Typography, Stack } from '@mui/material';

type ProcessInProgressProps = {
  title?: string;
};

const ProcessInProgress: FC<ProcessInProgressProps> = ({ title }) => {
  return (
    <Stack alignItems="center" marginTop={3}>
      <CircularProgress />
      {title && (
        <Typography variant="body1" color="textSecondary">
          {title}
        </Typography>
      )}
    </Stack>
  );
};

export default ProcessInProgress;
