import React, { FC, useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';
import { CaseAttachment, CaseNote, NoteStatusCodes } from '@oh-vcp/components-common';
import CaseService from '../services/caseService';
import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';

interface EconsultAttachmentListingProps {
  attachments: CaseAttachment[];
  onAttachmentDeleted?: (attachmentId: number) => void;
}

const EconsultAttachmentListing: FC<EconsultAttachmentListingProps> = ({
  attachments,
  onAttachmentDeleted
}) => {
  const handleAttachmentDeleted = (attachmentId: number) => {
    if (onAttachmentDeleted) {
      onAttachmentDeleted(attachmentId);
    }
  };

  return (
    <Box>
      {attachments
        ?.filter((attachment) => attachment.statusCd !== NoteStatusCodes.deleted)
        .map((attachment, index) => (
          <Box key={index}>
            {attachment.uploadedFile?.fileName && (
              <Chip
                label={attachment.uploadedFile?.fileName}
                color="primary"
                onDelete={() => handleAttachmentDeleted(attachment.attachmentId!)}
              />
            )}
          </Box>
        ))}
    </Box>
  );
};

export default EconsultAttachmentListing;
