import { logger } from '@oh-vcp/components-common/src/utils/logUtils';
import { Policy } from '@oh-vcp/components-common';
import apiService from './apiService';
import apiServiceNoCredentials from './apiServiceNoCredentials';

export async function fetchProfile<T>(url: string, id: string): Promise<T> {
  const response = await apiService.get(`${url}/${id}`);
  logger.info('fetchProfile response: ', response.data);
  return response.data;
}

export async function fetchPolicy(
  id: string,
  profileType: 'people' | 'site' | 'community' | 'fho' | 'program'
): Promise<Policy> {
  let url = '';
  switch (profileType) {
    case 'people':
      url = `profiles/people/${id}/policy`;
      break;
    case 'program':
      url = `programs/${id}/policy`;
      break;
    case 'site':
      url = `profiles/places/${id}/policy`;
      break;
    default:
      break;
  }
  const response = await apiService.get(url);
  return response?.data;
}

export async function fetchPolicies(
  ids: string[],
  profileType: 'people' | 'site' | 'fho' | 'program'
): Promise<Policy[]> {
  let url = '';
  switch (profileType) {
    case 'people':
      url = `profiles/people/policy?id=${ids.join(',')}`;
      break;
    case 'program':
      url = `programs/policy?id=${ids.join(',')}`;
      break;
    case 'site':
      url = `profiles/places/policy?id=${ids.join(',')}`;
      break;
    case 'fho':
      url = `healthServiceOrg/policy?id=${ids.join(',')}`;
      break;
    default:
      break;
  }
  const response = await apiService.get(url);
  return response?.data;
}
