export enum ConsultTab {
  Provided = 'provided',
  MoreInfo = 'more',
  Returned = 'returned',
  AddNote = 'addNote',
  Complete = 'complete',
  RequestClarification = 'requestClarification',
  Redirect = 'redirect',
  Cancel = 'cancel',
  ProvideMoreInfo = 'provideMoreInfo'
}
