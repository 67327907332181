export const EconsultStatusTypes = {
  all: 'all',
  needsAction: 'needsaction',
  waiting: 'waiting',
  provided: 'provided',
  declined: 'declined',
  completed: 'completed',
  cancelled: 'cancelled',
  draft: 'draft'
};
