const makeName = (
  lastName: string,
  firstName: string | null | undefined,
  salutation: string | null | undefined,
  specialty: string | null | undefined,
  middleName: string | null | undefined,
  credentials: string | null | undefined,
  city?: string | null | undefined,
  lhinNumber?: string | null | undefined
) => {
  let res = '';
  res = res.concat(salutation ? `${salutation} ` : '');
  res = res.concat(firstName ? `${firstName} ` : '');
  res = res.concat(middleName ? `${middleName} ` : '');
  res = res.concat(lastName ? `${lastName}` : '');
  res = res.concat(city ? `, ${city}` : '');
  res = res.concat(lhinNumber ? `, LHIN ${lhinNumber} ` : '');
  res = res.concat(specialty ? `, ${specialty}` : '');
  res = res.concat(credentials ? ` ${credentials}` : '');
  return res;
};

const makeNameInitials = (lastName?: string, firstName?: string) => {
  if (!lastName && !firstName) return undefined;

  let res = '';
  res = res.concat(firstName ? `${firstName.slice(0, 1)}` : '');
  res = res.concat(lastName ? `${lastName.slice(0, 1)}` : '');
  return res;
};

const makeAddressName = (
  address: string,
  city: string | null | undefined,
  province: string | null | undefined,
  postalCode: string | null | undefined
) => {
  let res = '';
  if (!address || typeof address === 'undefined') {
    return res;
  }
  res = res.concat(address ? `${address}` : '');
  res = res.concat(city ? `, ${city}` : '');
  res = res.concat(province ? `, ${province}` : '');
  res = res.concat(postalCode ? `, ${postalCode}` : '');
  return res;
};

const makeCommunityServiceName = (serviceName: string, parentServiceName?: string) => {
  if (serviceName && parentServiceName && serviceName.indexOf(parentServiceName) > -1) {
    let serviceNameFormatted = serviceName.replace(parentServiceName, '').trim();
    if (serviceNameFormatted.startsWith('-')) {
      serviceNameFormatted = serviceNameFormatted.substring(1).trim();
    }
    if (serviceNameFormatted.endsWith('-')) {
      serviceNameFormatted = serviceNameFormatted
        .substring(0, serviceNameFormatted.length - 2)
        .trim();
    }
    return serviceNameFormatted;
  }
  return serviceName;
};

const makeFilterName = (key: string, t: (key: string) => string) => {
  if (!key.trim()) {
    return 'Undefined';
  }
  const filterName = t(`Filter.${key}`);
  if (filterName.startsWith('Filter.')) {
    return key;
  }
  return filterName;
};

const makeGenderName = (gender?: string) => {
  if (!gender) {
    return '';
  }

  switch (gender) {
    case 'M':
      return 'Male';
    case 'F':
      return 'Female';
    default:
      return gender;
  }
};

const NameUtils = {
  makeName,
  makeNameInitials,
  makeAddressName,
  makeCommunityServiceName,
  makeFilterName,
  makeGenderName
};

export default NameUtils;
