import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { AssignerPrograms } from '@oh-vcp/components-common';
import { apiService } from '@oh-vcp/components-web';

const fetchAssignerPrograms = async (): Promise<AssignerPrograms> => {
  let url = `programs/myprogramsasassigner`;
  const response = await apiService.get(url);
  return response.data;
};

const useAssignerPrograms = () => {
  return useQuery<AssignerPrograms, AxiosError>(
    ['assignerPrograms'],
    () => fetchAssignerPrograms(),
    {
      refetchOnWindowFocus: false
    }
  );
};

export default useAssignerPrograms;
