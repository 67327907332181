import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface EconsultApplyForProgPermissionDialogProps {
  isDialogOpen: boolean;
  onCancelClicked: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onActionClicked: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  programName: string;
}

const EconsultApplyForProgPermissionDialog: FC<EconsultApplyForProgPermissionDialogProps> = ({
  isDialogOpen,
  onCancelClicked,
  onActionClicked,
  programName
}) => {
  const { t } = useTranslation();
  const renderedContent = t('EconsultApplyForProgPermissionDialog.content').replace(
    '$programName',
    programName
  );

  return (
    <Dialog open={isDialogOpen}>
      <DialogTitle variant="h2" sx={{ color: 'base.grey4' }}>
        {t('EconsultApplyForProgPermissionDialog.title')}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ color: 'base.grey4' }}>
          {renderedContent}
        </Typography>
        <Stack direction="row" columnGap={2} mt={2} justifyContent="flex-end">
          <Button
            size="large"
            variant="contained"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onActionClicked(e)}>
            {t('ActionItem.apply')}
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onCancelClicked(e)}>
            {t('ActionItem.cancel')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default EconsultApplyForProgPermissionDialog;
