import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import {
  EconsultCaseConsultActionDetails,
  EconsultCaseHeader,
  EconsultCaseNotes,
  useAuth
} from '@oh-vcp/components-web';
import {
  ConsultTab,
  CaseConsultOptions,
  NoteTypes,
  NoteActionTypes,
  EconsultUserRoles,
  NoteStatusCodes
} from '@oh-vcp/components-common';
import { useEconsultCaseDetailsContext } from '@oh-vcp/components-web/src/econsultCaseDetailsContext/EconsultCaseDetailsContext';
import useTimeOptions from '../../api/useTimeOptions';
import CaseService from '@oh-vcp/components-web/src/services/caseService';
import { useParams } from 'react-router-dom';
import { useEconsultTabContext } from '@oh-vcp/components-web/src/econsultTabContext/EconsultTabContext';

const EconsultCaseConsultDetailsView = () => {
  const [tabOptions, setTabOptions] = useState<CaseConsultOptions>({
    provideConsultEnabled: false,
    requestMoreEnabled: false,
    returnConsultEnabled: false
  });
  const [isPdfEnabled, setIsPdfEnabled] = useState<boolean>(false);
  const [isEmrEnabled, setIsEmrEnabled] = useState<boolean>(false);
  const { caseDetails, setTimeOptions, userRole, assignerPrograms } =
    useEconsultCaseDetailsContext();
  // const [selectedConsultTab, setSelectedConsultTab] = useState<ConsultTab | undefined>(undefined);
  const { selectedTab, setSelectedTab } = useEconsultTabContext();
  const { data: options } = useTimeOptions();
  const { caseId } = useParams();
  const { user } = useAuth();

  useEffect(() => {
    if (options) {
      setTimeOptions(options);
    }
  }, [options, setTimeOptions]);

  useEffect(() => {
    const isSpecialistRespondAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.consultant &&
          userRole !== EconsultUserRoles.consultantDelegate)
      ) {
        return false;
      }
      const lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }

      return (
        lastActiveNote.noteTypeCd !== NoteTypes.consultant &&
        (lastActiveNote.actionTypeCd === NoteActionTypes.send ||
          lastActiveNote.actionTypeCd === NoteActionTypes.reply ||
          lastActiveNote.actionTypeCd === NoteActionTypes.reopen ||
          lastActiveNote.actionTypeCd === NoteActionTypes.assign)
      );
    };

    const isSpecialistProvidedConsultAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.consultant &&
          userRole !== EconsultUserRoles.consultantDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }
      return (
        lastActiveNote.actionTypeCd === NoteActionTypes.writeConsult ||
        lastActiveNote.actionTypeCd === NoteActionTypes.recomplete
      );
    };

    const isReferrerReopenedCaseAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.referrer && userRole !== EconsultUserRoles.referrerDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }
      return (
        caseDetails.statusCd !== NoteStatusCodes.closed &&
        caseDetails.participant?.statusCd !== NoteStatusCodes.closed &&
        caseDetails.participant?.completedOn !== undefined &&
        CaseService.containsConsultProvidedNote(caseDetails) &&
        lastActiveNote.actionTypeCd !== NoteActionTypes.askForMoreInfo &&
        lastActiveNote.actionTypeCd !== NoteActionTypes.decline
      );
    };

    const isReferrerResolveCompletedConsultAction = function () {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.referrer && userRole !== EconsultUserRoles.referrerDelegate)
      )
        return false;
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote || caseDetails.statusCd === NoteStatusCodes.closed) {
        return false;
      }
      if (
        lastActiveNote.actionTypeCd === NoteActionTypes.writeConsult ||
        lastActiveNote.actionTypeCd === NoteActionTypes.recomplete
      ) {
        return true;
      }
      return (
        lastActiveNote.actionTypeCd === NoteActionTypes.decline &&
        CaseService.containsConsultProvidedNote(caseDetails)
      );
    };

    const isReferrerResolveDeclinedConsultAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.referrer && userRole !== EconsultUserRoles.referrerDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }
      if (
        lastActiveNote.actionTypeCd === NoteActionTypes.decline &&
        CaseService.containsConsultProvidedNote(caseDetails)
      ) {
        return false;
      }
      return (
        lastActiveNote.actionTypeCd === NoteActionTypes.decline ||
        lastActiveNote.actionTypeCd === NoteActionTypes.unassign
      );
    };

    const isReferrerUpdateSentCaseAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.referrer && userRole !== EconsultUserRoles.referrerDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }
      return (
        caseDetails.statusCd !== NoteStatusCodes.closed &&
        caseDetails.participant?.statusCd !== NoteStatusCodes.closed &&
        caseDetails.participant?.completedOn === undefined &&
        (caseDetails.participant?.program === undefined ||
          caseDetails.participant?.consultant === undefined) &&
        !CaseService.hasSpecialistResponded(caseDetails) &&
        lastActiveNote.actionTypeCd !== NoteActionTypes.decline &&
        lastActiveNote.actionTypeCd !== NoteActionTypes.unassign
      );
    };

    const isReferrerUpdateTriagedCaseAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.referrer && userRole !== EconsultUserRoles.referrerDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }

      if (
        caseDetails.statusCd === NoteStatusCodes.closed ||
        caseDetails.participant?.statusCd === NoteStatusCodes.closed ||
        caseDetails.participant?.completedOn ||
        !caseDetails.participant?.program ||
        !caseDetails.participant?.consultant
      ) {
        return false;
      }

      return lastActiveNote.actionTypeCd === NoteActionTypes.assign;
    };

    const isReferrerUpdateRepliedCaseAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.referrer && userRole !== EconsultUserRoles.referrerDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }

      if (
        caseDetails.statusCd === NoteStatusCodes.closed ||
        caseDetails.participant?.statusCd === NoteStatusCodes.closed ||
        caseDetails.participant?.completedOn ||
        !caseDetails.participant?.consultant
      ) {
        return false;
      }

      return (
        lastActiveNote.actionTypeCd === NoteActionTypes.assign ||
        (lastActiveNote.actionTypeCd === NoteActionTypes.reply &&
          !CaseService.containsConsultProvidedNote(caseDetails))
      );
    };

    const isReferrerSendMoreInfoAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.referrer && userRole !== EconsultUserRoles.referrerDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }
      return (
        lastActiveNote.actionTypeCd === NoteActionTypes.askForMoreInfo &&
        !CaseService.containsConsultProvidedNote(caseDetails)
      );
    };

    const isSpecialistAddNoteAction = () => {
      if (
        !caseDetails ||
        (userRole !== EconsultUserRoles.consultant &&
          userRole !== EconsultUserRoles.consultantDelegate)
      ) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }
      return lastActiveNote.actionTypeCd === NoteActionTypes.askForMoreInfo;
    };

    const isUserTriageAssignerInProgram = function (programId: number | undefined) {
      var program = assignerPrograms?.triageAssigner?.find((id: number) => id === programId);
      if (program) return true;
      return false;
    };

    const isCurrentUserCaseTriageAdmin = () => {
      return NoteTypes.triageAdmin === userRole;
    };

    const isCurrentUserCaseTriageAssigner = () => {
      if (!isCurrentUserCaseTriageAdmin()) {
        return false;
      }
      const programId = caseDetails?.participant?.program?.programId
        ? parseInt(caseDetails.participant.program.programId)
        : undefined;
      return isUserTriageAssignerInProgram(programId);
    };

    const isTriageRedirect = () => {
      if (!caseDetails || !isCurrentUserCaseTriageAdmin()) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }

      return lastActiveNote.actionTypeCd === NoteActionTypes.unassign;
    };

    const isTriageAssignerRedirect = () => {
      if (!caseDetails || !isCurrentUserCaseTriageAssigner()) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }

      return lastActiveNote.actionTypeCd === NoteActionTypes.unassign;
    };

    const isTriageAssignerRedirectAndCancel = () => {
      if (!caseDetails || !isCurrentUserCaseTriageAssigner()) {
        return false;
      }
      var lastActiveNote = CaseService.getLastActiveNote(caseDetails);
      if (!lastActiveNote) {
        return false;
      }

      return lastActiveNote.actionTypeCd === NoteActionTypes.send;
    };

    if (isSpecialistRespondAction()) {
      setTabOptions({
        provideConsultEnabled: true,
        requestMoreEnabled: true,
        returnConsultEnabled: true
      });
    } else if (isReferrerResolveCompletedConsultAction()) {
      setTabOptions({
        completeEnabled: true,
        requestClarificationEnabled: true,
        addNoteEnabled: true
      });
    } else if (isSpecialistProvidedConsultAction() || isReferrerReopenedCaseAction()) {
      setTabOptions({
        addNoteEnabled: true
      });
    } else if (isReferrerResolveDeclinedConsultAction()) {
      setTabOptions({
        redirectEnabled: true,
        cancelEnabled: true
      });
    } else if (isReferrerUpdateSentCaseAction()) {
      setTabOptions({
        addNoteEnabled: true,
        redirectEnabled: true,
        cancelEnabled: true
      });
    } else if (isReferrerUpdateTriagedCaseAction() || isReferrerUpdateRepliedCaseAction()) {
      setTabOptions({
        cancelEnabled: true,
        addNoteEnabled: true
      });
    } else if (isReferrerSendMoreInfoAction()) {
      setTabOptions({
        cancelEnabled: true,
        provideMoreInfoEnable: true
      });
    } else if (isSpecialistAddNoteAction()) {
      setTabOptions({
        provideConsultEnabled: true,
        addNoteEnabled: true,
        returnConsultEnabled: true
      });
    } else if (isTriageRedirect() || isTriageAssignerRedirect()) {
      setTabOptions({
        redirectEnabled: true
      });
    } else if (isTriageAssignerRedirectAndCancel()) {
      setTabOptions({
        redirectEnabled: true,
        cancelEnabled: true
      });
    } else {
      setTabOptions({});
    }
    setIsPdfEnabled(!isCurrentUserCaseTriageAdmin() || isCurrentUserCaseTriageAssigner());
    if (
      !caseDetails?.patient?.noOhip &&
      caseDetails?.patient?.ohipNumber &&
      (userRole === EconsultUserRoles.consultant ||
        userRole === EconsultUserRoles.consultantDelegate ||
        userRole === EconsultUserRoles.referrer ||
        userRole === EconsultUserRoles.referrerDelegate ||
        user?.econsult?.caseConsultOn ||
        user?.econsult?.caseCreate) &&
      caseDetails.participant &&
      caseDetails.participant.consultant
    ) {
      setIsEmrEnabled(true);
    } else {
      setIsEmrEnabled(false);
    }
  }, [caseDetails, userRole, assignerPrograms, user]);

  if (!caseDetails || caseDetails.caseId?.toString() !== caseId) {
    return null;
  }

  const handleConsultTabSelected = (tab: ConsultTab) => {
    setSelectedTab(tab);
  };

  return (
    <Stack direction="column" p={4}>
      <EconsultCaseHeader isPdfEnabled={isPdfEnabled} isEmrEnabled={isEmrEnabled} />
      <EconsultCaseConsultActionDetails
        selectedTab={selectedTab}
        options={tabOptions}
        onTabSelected={handleConsultTabSelected}
      />
      <EconsultCaseNotes />
    </Stack>
  );
};

export default EconsultCaseConsultDetailsView;
