import { ButtonToggleItem } from '../types/ButtonToggleItem';

const ConsultantNeedsActionFilterItems: ButtonToggleItem[] = [
  {
    translationKey: 'Case.inbox.all',
    value: ''
  },
  {
    translationKey: 'Case.inbox.new',
    value: 'NEW'
  },
  {
    translationKey: 'Case.inbox.followUp',
    value: 'FOLLOWUP'
  }
];

const ConsultantProvidedFilterItems: ButtonToggleItem[] = [
  {
    translationKey: 'Case.inbox.all',
    value: ''
  },
  {
    translationKey: 'Case.inbox.feedback',
    value: 'FEEDBACK'
  },
  {
    translationKey: 'Case.inbox.flagged',
    value: 'FLAGGED'
  }
];

const ReferrerNeedsActionFilterItems: ButtonToggleItem[] = [
  {
    translationKey: 'Case.inbox.all',
    value: ''
  },
  {
    translationKey: 'Case.inbox.consultProvided',
    value: 'PROVIDED'
  },
  {
    translationKey: 'Case.inbox.needsReferral',
    value: 'REFERRAL'
  },
  {
    translationKey: 'Case.inbox.needsMoreInfo',
    value: 'INFO'
  }
];

const EconsultFilters = {
  ConsultantNeedsActionFilterItems,
  ConsultantProvidedFilterItems,
  ReferrerNeedsActionFilterItems
};

export default EconsultFilters;
