import { HoursEntity } from '@oh-vcp/components-common/src/types/FhoDTO';
import moment from 'moment';

const TimeService = {
  getHoursForDayOfWeek: (
    dayOfWeek: number,
    sourceHours: HoursEntity[]
  ): HoursEntity[] | undefined => {
    return sourceHours
      .filter((locationHour) => {
        if (locationHour.day === dayOfWeek) {
          return locationHour;
        }
        return undefined;
      })
      .sort((a, b) => a.open - b.open);
  },
  formatUtcHour: (hour: number) => {
    const time = moment.utc(hour, 'hmm');
    if (time.minutes() > 0) {
      return time.format('h:mm a');
    }

    return time.format('h a');
  },
  isOpenToday: (sourceHours: HoursEntity[]) => {
    if (sourceHours) {
      const today = moment().utc();
      const hours = TimeService.getHoursForDayOfWeek(today.day(), sourceHours);
      return hours?.some((hour) => {
        const openTime = moment(hour.open, 'hmm');
        const closeTime = moment(hour.close, 'hmm');
        return today.isSameOrAfter(openTime) && today.isSameOrBefore(closeTime);
      });
    }
    return false;
  },
  getClosingTime: (sourceHours: HoursEntity[]) => {
    if (sourceHours) {
      const today = moment().utc();
      const hours = TimeService.getHoursForDayOfWeek(today.day(), sourceHours);
      if (hours && hours?.length > 0) {
        for (let i = 0; i < hours.length; i += 1) {
          if (
            today.diff(moment(hours[i].open, 'Hmm'), 'minutes') >= 0 &&
            today.diff(moment(hours[i].close, 'Hmm'), 'minutes') < 0
          ) {
            return TimeService.formatUtcHour(hours[i].close);
          }
        }
      }
    }
    return undefined;
  },
  formatDateAndTime: (dateAndTime: string): string => {
    const momentvalue = moment(dateAndTime);
    return momentvalue.format('M/DD/YY h:mm A');
  }
};

export default TimeService;
