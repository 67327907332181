import { AppConfig } from '../AppConfig';

export interface LogFn {
  (message?: any, ...optionalParams: any[]): void;
}

export interface LoggerType {
  info: LogFn;
  warn: LogFn;
  error: LogFn;
}

export type LogLevel = 'INFO' | 'WARN' | 'ERROR';

const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => {};

export class Logger implements LoggerType {
  readonly info: LogFn;
  readonly warn: LogFn;
  readonly error: LogFn;
  moduleName?: string;
  level?: LogLevel;

  log = (...args: any[]) => {
    console.log(`[${this.level}:${this.moduleName}]`, ...args);
  };
  constructor(options: { moduleName?: string; level?: LogLevel }) {
    this.moduleName = options.moduleName;
    this.level = options.level ?? AppConfig.logLevel;

    this.error = this.log;

    if (this.level === 'ERROR') {
      this.warn = NO_OP;
      this.info = NO_OP;

      return;
    }

    this.warn = this.log;

    if (this.level === 'WARN') {
      this.info = NO_OP;

      return;
    }

    this.info = this.log;
  }
}

export const logger = new Logger({ moduleName: 'LOGGER', level: AppConfig.logLevel });

const logUtils = {
  logger
};

export default logUtils;
