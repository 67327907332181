import { CardActionArea, Grid, Stack, Typography } from '@mui/material';
import { FhoEntity } from '@oh-vcp/components-common';
import {
  ActionItemParams,
  HighlightedBoxContainer,
  StyledBoxContainer,
  StyledLocationBox
} from '@oh-vcp/components-ui';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMapViewContext } from '@oh-vcp/components-common/src/contexts/MapViewContext';
import FhoListHeaderCard from '../listHeaderCards/FhoListHeaderCard';
import SearchListToolbar from '../searchListToolbar/SearchListToolbar';
import SearchResultLocationList from '../searchResultLocationList/SearchResultLocationList';
import { useSearchResultsViewContext } from '../searchResults/SearchResultsViewContext';

interface FhoListRendererProps {
  result: FhoEntity;
}

const FhoListRenderer: React.FC<FhoListRendererProps> = ({ result }) => {
  const { organization, locations, id, policy, type } = result;
  const navigate = useNavigate();
  const url = `/fho/${organization?.id}/service/${id}`;
  const [searchParams] = useSearchParams();
  const { isMinimalView, setSelectedResult, selectedResult } = useSearchResultsViewContext();
  const { setIsMarkerInfoHidden } = useMapViewContext();
  const actionParams: ActionItemParams = {
    profileType: 'fho',
    profileId: id.toString()
  };
  const keyword = searchParams.get('q');

  const handleResultClick = () => {
    if (isMinimalView) {
      setSelectedResult({
        id,
        type
      });
      setIsMarkerInfoHidden(true);
    } else {
      navigate(url);
    }
  };

  return (
    <HighlightedBoxContainer
      isHighlighted={selectedResult?.id === id && selectedResult?.type === type}>
      <Stack direction="column">
        <CardActionArea sx={{ height: '100%' }} onClick={handleResultClick}>
          <Grid container spacing={0} sx={{ pb: 2 }}>
            <Grid item xs={12}>
              {organization && (
                <Stack direction="row">
                  <Stack flexGrow={1}>
                    <FhoListHeaderCard fho={result} highlightText={keyword} />
                  </Stack>
                  <SearchListToolbar policy={policy} actionParams={actionParams} />
                </Stack>
              )}
            </Grid>
          </Grid>
        </CardActionArea>
        {locations && (
          <StyledLocationBox>
            {locations.length > 0 && <SearchResultLocationList locations={locations} />}
            {locations.length === 0 && <Typography variant="caption">No locations</Typography>}
          </StyledLocationBox>
        )}
      </Stack>
    </HighlightedBoxContainer>
  );
};

export default FhoListRenderer;
