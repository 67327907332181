import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
  styled
} from '@mui/material';
import { BaseEntity, SearchDataParser, SearchResponsePayloadDTO } from '@oh-vcp/components-common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import AutocompletePlaces, {
  PlaceValue
} from '@oh-vcp/components-ui/src/autocompletePlaces/AutocompletePlaces';
import { GridWithTopBorderOnly } from '@oh-vcp/components-ui/src/StyledComponents';
import { DataItem, SearchParams, useSearchParamContext } from '@oh-vcp/components-ui';
import SearchBarWithSuggest from '../searchBar/SearchBarWithSuggest';
import { fetchSearchSuggestions } from '../services/searchSuggestionService';
import { useSearchResultsViewContext } from '../searchResults/SearchResultsViewContext';
import { useSearchGuidedTabsContext } from '../searchGuidedTabs/SearchGuidedTabsContext';

type SearchFieldsProps = {
  onSearch: (params: SearchParams) => void;
  keyword: string;
  placeValueProps?: PlaceValue;
  isIndigenousServices: boolean;
  isFrenchLanguageServices: boolean;
  isIndigenousServicesVisible?: boolean;
  isFrenchLanguageServicesVisible?: boolean;
  SearchBarPlaceHolderI18nKey: string;
  onLocationChanged: (newLocation: PlaceValue | undefined) => void;
  onSearchFieldsChange: (params: SearchParams) => void;
};

const SearchButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '100%',
  'min-width': '100px',
  'border-radius': '0 0 4px 0',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2)
  }
}));

const StyledContainer = styled(Stack)(() => {
  return {
    'box-shadow': '0px 1px 12px rgba(39,39,39,.2)',
    'border-radius': '0 4px 4px 4px'
  };
});
const MinimalStyledGridItemWithResponsiveSeparator = styled(Grid)(({ theme }) => {
  return {
    position: 'relative',
    '&:after': {
      content: '""',
      height: '1px',
      width: '90%',
      position: 'absolute',
      // right: '0px',
      left: '50%',
      transform: 'translateX(-50%)',
      'background-color': '#cfcfcf'
    },
    '&:focus-within:after': {
      display: 'none'
    }
  };
});

const StyledGridItemWithResponsiveSeparator = styled(Grid)(({ theme }) => {
  return {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      '&:after': {
        content: '""',
        height: '50%',
        width: '1px',
        position: 'absolute',
        right: '0px',
        top: '50%',
        transform: 'translateY(-50%)',
        'background-color': '#cfcfcf'
      }
    },
    [theme.breakpoints.down('md')]: {
      '&:after': {
        content: '""',
        height: '1px',
        width: '90%',
        position: 'absolute',
        // right: '0px',
        left: '50%',
        transform: 'translateX(-50%)',
        'background-color': '#cfcfcf'
      }
    },
    '&:focus-within:after': {
      display: 'none'
    }
  };
});

const SearchFields: React.FC<SearchFieldsProps> = ({
  onSearch,
  keyword,
  placeValueProps,
  isIndigenousServices,
  isFrenchLanguageServices,
  SearchBarPlaceHolderI18nKey,
  isIndigenousServicesVisible = true,
  isFrenchLanguageServicesVisible = true,
  onLocationChanged,
  onSearchFieldsChange
}) => {
  const { t } = useTranslation();
  // state
  const [keywordState, setKeywordState] = useState<string>(keyword);
  const [isIndigenousServicesState, setIndigenousServicesState] =
    useState<boolean>(isIndigenousServices);
  const [isFrenchLanguageServicesState, setFrenchLanguageServicesState] =
    useState<boolean>(isFrenchLanguageServices);
  const [locationState, setLocationState] = useState<PlaceValue | undefined>(placeValueProps);
  const [selected, setSelected] = useState<DataItem>(null);
  const [options, setOptions] = useState<BaseEntity[]>([]);
  const [isSuggestionsFetchAborted, setIsSuggestionsFetchAborted] = useState<boolean>(false);
  const gridRef = React.useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const { isMinimalView } = useSearchResultsViewContext();
  const { setIsDrawerOpen } = useSearchGuidedTabsContext();

  // the dependancy array contains parameters that pushed from the parent, not the state ones!!!
  useEffect(() => {
    setKeywordState(keyword);
    setIndigenousServicesState(isIndigenousServices);
    setFrenchLanguageServicesState(isFrenchLanguageServices);
    setLocationState(placeValueProps);
    setOptions([]);
  }, [keyword, isIndigenousServices, isFrenchLanguageServices, placeValueProps]);

  useEffect(() => {
    let isCurrentSearch = true;
    const populateOptionsFromSuggestions = async () => {
      if (!keywordState || keywordState.length < 2) {
        setOptions([]);
        return;
      }
      const selectedGuidedOption = searchParams.get('selectedGuidedOption') || '0';
      const currentKeywordState = keywordState;
      const results: SearchResponsePayloadDTO | null = await fetchSearchSuggestions(
        encodeURIComponent(keywordState),
        selectedGuidedOption
      );
      if (
        !isSuggestionsFetchAborted &&
        isCurrentSearch &&
        currentKeywordState === keywordState &&
        results
      ) {
        const entities: BaseEntity[] = SearchDataParser.parse(results);
        setOptions(entities);
      } else {
        setOptions([]);
      }
    };
    populateOptionsFromSuggestions();
    return () => {
      isCurrentSearch = false;
    };
  }, [keywordState, isSuggestionsFetchAborted]);

  const handleChangeIndigenousServices = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIndigenousServicesState(event.target.checked);
    onSearchFieldsChange({
      selected,
      keyword: keywordState || '',
      location: locationState,
      isFrenchLanguageServices: isFrenchLanguageServicesState,
      isIndigenousServices: event.target.checked
    });
  };

  const handleChangeFrenchLanguageServices = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFrenchLanguageServicesState(event.target.checked);
    onSearchFieldsChange({
      selected,
      keyword: keywordState || '',
      location: locationState,
      isFrenchLanguageServices: event.target.checked,
      isIndigenousServices: isIndigenousServicesState
    });
  };

  const onSearchImpl = () => {
    setIsSuggestionsFetchAborted(true);
    setIsDrawerOpen(false);
    onSearch({
      selected,
      keyword: keywordState || '',
      location: locationState,
      isFrenchLanguageServices: isFrenchLanguageServicesState,
      isIndigenousServices: isIndigenousServicesState
    });
  };
  const onSearchInputChange = (searchKeyword: string) => {
    setKeywordState(searchKeyword);
    setIsSuggestionsFetchAborted(false);
  };

  const StyledGridItemWithResponsiveSeparatorComponent = isMinimalView
    ? MinimalStyledGridItemWithResponsiveSeparator
    : StyledGridItemWithResponsiveSeparator;

  return (
    <StyledContainer>
      <FormGroup sx={{ flexDirection: { xs: 'column', md: 'row' }, pl: 1 }}>
        {isIndigenousServicesVisible && (
          <FormControlLabel
            control={
              <Checkbox
                id="isIndigenousServices"
                checked={isIndigenousServicesState}
                onChange={handleChangeIndigenousServices}
              />
            }
            label={t('SearchFields.isIndigenousServices')}
          />
        )}
        {isFrenchLanguageServicesVisible && (
          <FormControlLabel
            control={<Checkbox checked={isFrenchLanguageServicesState} />}
            label={t('SearchFields.isFrenchLanguageServices')}
            onChange={handleChangeFrenchLanguageServices}
          />
        )}
      </FormGroup>
      <GridWithTopBorderOnly
        container
        ref={gridRef}
        sx={{ flexDirection: isMinimalView ? 'column' : { xs: 'column', md: 'row' } }}
        alignItems="stretch">
        <StyledGridItemWithResponsiveSeparatorComponent item flexGrow={1}>
          <SearchBarWithSuggest
            anchorEl={gridRef.current}
            id="search-bar"
            placeholder={t(SearchBarPlaceHolderI18nKey)}
            onEnter={onSearchImpl}
            onSelect={(selectedObj) => {
              setSelected(selectedObj);
            }}
            onInputChange={onSearchInputChange}
            options={options}
            value={keywordState}
          />
        </StyledGridItemWithResponsiveSeparatorComponent>
        <Grid item flexGrow={1}>
          <AutocompletePlaces
            onSelect={(newLocation) => {
              setLocationState(newLocation);
              onLocationChanged(newLocation);
            }}
            placeValue={locationState}
            placeholder={t('AutocompletePlaces.placeholder')}
          />
        </Grid>
        <Grid item>
          <SearchButton
            variant="contained"
            disabled={keywordState.length <= 1 || !locationState}
            onClick={onSearchImpl}>
            <Typography>{t('SearchFields.Search')}</Typography>
          </SearchButton>
        </Grid>
      </GridWithTopBorderOnly>
    </StyledContainer>
  );
};

export default SearchFields;
