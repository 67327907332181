import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { apiService } from '@oh-vcp/components-web';
import { ProgramSpecialty } from '@oh-vcp/components-common';

const fetchSpecialties = async (userId: number): Promise<ProgramSpecialty[]> => {
  let url = `programs/lookup/region/specialty/user/${userId}`;
  const response = await apiService.get(url);
  return response.data;
};

const useSpecialtyLookup = (userId: number | undefined | null) => {
  return useQuery<ProgramSpecialty[], AxiosError>(
    ['specialtyLookup', userId],
    () => fetchSpecialties(userId!),
    {
      enabled: !!userId,
      refetchOnWindowFocus: false
    }
  );
};

export default useSpecialtyLookup;
