import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OhipValidationService, RequiredFieldTypography } from '@oh-vcp/components-ui';

import { useEconsultCaseDetailsContext } from '../econsultCaseDetailsContext/EconsultCaseDetailsContext';
import ValidatorUtils from '../econsultBaseValidator/EconsultBaseValidator';
import { ValidationTypes } from '../econsultBaseValidator/EconsultValidationTypes';
import { ValidationResult } from '../econsultBaseValidator/ValidationResult';

const PatientFormTextField = styled(TextField)({
  width: '70%'
});

const PatientFormDateField = styled(PatientFormTextField)({
  '& .MuiInputBase-input': {
    height: 10
  }
});

const EconsultCasePatientForm = forwardRef((props, ref) => {
  const { caseDetails, setCaseDetails } = useEconsultCaseDetailsContext();
  const [isOhipValid, setIsOhipValid] = useState<boolean | undefined>(undefined);
  const [isOhipFieldDirty, setIsOhipFieldDirty] = useState<boolean>(false);
  const [isConsentShown, setIsConsentShown] = useState<boolean>(false);
  const [patientConsentDetails, setPatientConsentDetails] = useState<string>('');
  const requiredFields: Map<string, ValidationTypes> = new Map();
  requiredFields.set('firstName', ValidationTypes.Text);
  requiredFields.set('lastName', ValidationTypes.Text);
  requiredFields.set('dateOfBirth', ValidationTypes.Date);
  const [dirtyFields, setFieldDiryty] = useState<string[]>([]);
  const [invalidFields, setFieldInvalid] = useState<string[]>([]);
  const { t } = useTranslation();
  const [isPatientValid, setIsPatientValid] = useState(true);

  const isPatientFormValid = (): boolean => {
    let isPatientDetailsValid: boolean = true;
    if (!caseDetails?.patient?.firstName) {
      setFieldInvalid(ValidatorUtils.addToList(invalidFields, 'firstName'));
      isPatientDetailsValid = false;
    }
    if (!caseDetails?.patient?.lastName) {
      setFieldInvalid(ValidatorUtils.addToList(invalidFields, 'lastName'));
      isPatientDetailsValid = false;
    }
    if (!caseDetails?.patient?.dateOfBirth) {
      setFieldInvalid(ValidatorUtils.addToList(invalidFields, 'dateOfBirth'));
      isPatientDetailsValid = false;
    }
    setFieldInvalid(invalidFields);
    setIsPatientValid(isPatientDetailsValid);
    return isPatientDetailsValid;
  };
  useImperativeHandle(ref, () => ({
    validate: () => {
      return isPatientFormValid();
    }
  }));
  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    const fieldName = { name }.name;

    const res: ValidationResult = ValidatorUtils.handleValidation(
      requiredFields,
      dirtyFields,
      invalidFields,
      fieldName,
      value
    );
    setIsPatientValid(res.isValid);
    setFieldDiryty(res.dirtyFields);
    setFieldInvalid(res.invalidFields);

    const updatedCaseDetails = {
      ...caseDetails,
      patient: {
        ...caseDetails?.patient,
        [name]: value
      }
    };
    setCaseDetails(updatedCaseDetails);
  };
  const validateOhip = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isOhipFieldDirty) {
      setIsOhipFieldDirty(true);
    }
    setIsOhipValid(OhipValidationService.isOhipNumberValid(event.target.value));
    handleFieldChange(event);
  };

  const handleNoOhip = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOhipFieldDirty(false);
    const updatedCaseDetails = {
      ...caseDetails,
      patient: {
        ...caseDetails?.patient,
        noOhip: event.target.checked
      }
    };
    delete updatedCaseDetails.patient.ohipNumber;
    delete updatedCaseDetails.patient.ohipVersion;
    setCaseDetails(updatedCaseDetails);
  };
  const handleConsentChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsConsentShown(event.target.checked);
    if (caseDetails) {
      const { patientConsent, ...rest } = caseDetails;
      setCaseDetails(rest);
    }
    setPatientConsentDetails('');
  };
  const handlePatientConsentFieldChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPatientConsentDetails(event.target.value);
  };
  const handlePatientConsentFieldBlur = () => {
    setCaseDetails({
      ...caseDetails,
      patientConsent: patientConsentDetails
    });
  };

  return (
    <Grid container spacing={0} rowSpacing={2}>
      <Grid item xs={12} md={3}>
        <RequiredFieldTypography variant="body1" color="base.grey4">
          {t('Case.patient.firstName')}
        </RequiredFieldTypography>
      </Grid>
      <Grid item xs={12} md={9}>
        <PatientFormTextField
          value={caseDetails?.patient?.firstName}
          name="firstName"
          id="firstName"
          placeholder={t('Case.patient.enterFirstName') as string | undefined}
          variant="standard"
          onChange={handleFieldChange}
          // eslint-disable-next-line prettier/prettier
          error={
            !isPatientValid ||
            ValidatorUtils.isFieldNotValidAndDirty(dirtyFields, invalidFields, 'firstName')
          }
          helperText={
            // eslint-disable-next-line prettier/prettier
            !isPatientValid ||
            ValidatorUtils.isFieldNotValidAndDirty(dirtyFields, invalidFields, 'firstName')
              ? t('Case.consult.invalidFirstName')
              : ''
          }
        />
      </Grid>
      <Grid item xs={12} md={3} pl={1.5}>
        <Typography variant="body1" color="base.grey4">
          {t('Case.patient.middleName')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <PatientFormTextField
          value={caseDetails?.patient?.middleName}
          name="middleName"
          placeholder={t('Case.patient.enterMiddleName') as string | undefined}
          variant="standard"
          onChange={handleFieldChange}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <RequiredFieldTypography variant="body1" color="base.grey4">
          {t('Case.patient.lastName')}
        </RequiredFieldTypography>
      </Grid>
      <Grid item xs={12} md={9}>
        <PatientFormTextField
          value={caseDetails?.patient?.lastName}
          name="lastName"
          id="lastName"
          placeholder={t('Case.patient.enterLastName') as string | undefined}
          variant="standard"
          onChange={handleFieldChange}
          // eslint-disable-next-line prettier/prettier
          error={
            !isPatientValid ||
            ValidatorUtils.isFieldNotValidAndDirty(dirtyFields, invalidFields, 'lastName')
          }
          helperText={
            // eslint-disable-next-line prettier/prettier
            !isPatientValid ||
            ValidatorUtils.isFieldNotValidAndDirty(dirtyFields, invalidFields, 'lastName')
              ? t('Case.consult.invalidLastName')
              : ''
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <RequiredFieldTypography variant="body1" color="base.grey4" pt={1}>
          {t('Case.patient.dob')}
        </RequiredFieldTypography>
      </Grid>
      <Grid item xs={12} md={9}>
        <PatientFormDateField
          value={caseDetails?.patient?.dateOfBirth}
          type="date"
          name="dateOfBirth"
          id="dateOfBirth"
          onChange={handleFieldChange}
          // eslint-disable-next-line prettier/prettier
          error={
            !isPatientValid ||
            ValidatorUtils.isFieldNotValidAndDirty(dirtyFields, invalidFields, 'dateOfBirth')
          }
          helperText={
            // eslint-disable-next-line prettier/prettier
            !isPatientValid ||
            ValidatorUtils.isFieldNotValidAndDirty(dirtyFields, invalidFields, 'dateOfBirth')
              ? t('Case.consult.invalidDateOfBirth')
              : ''
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <RequiredFieldTypography variant="body1" color="base.grey4" pt={1}>
          {t('Case.patient.gender')}
        </RequiredFieldTypography>
      </Grid>
      <Grid item xs={12} md={9}>
        <FormControl component="fieldset">
          <RadioGroup
            value={caseDetails?.patient?.gender}
            aria-label="gender"
            name="gender"
            onChange={handleFieldChange}>
            <Stack direction="row">
              <FormControlLabel
                value="M"
                control={<Radio />}
                label="Male"
                sx={{ color: 'base.grey4' }}
              />
              <FormControlLabel
                value="F"
                control={<Radio />}
                label="Female"
                sx={{ color: 'base.grey4' }}
              />
              <FormControlLabel
                value="O"
                control={<Radio />}
                label="Other"
                sx={{ color: 'base.grey4' }}
              />
            </Stack>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <RequiredFieldTypography variant="body1" color="base.grey4" pt={1}>
          {t('Case.patient.ohip')}
        </RequiredFieldTypography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Stack direction="column">
          <Stack direction="row" spacing={2}>
            <TextField
              placeholder={t('Case.patient.enterOhip') as string | undefined}
              variant="standard"
              name="ohipNumber"
              value={caseDetails?.patient?.ohipNumber ?? ''}
              onChange={validateOhip}
              error={!isOhipValid && isOhipFieldDirty}
              helperText={!isOhipValid && isOhipFieldDirty ? t('Case.consult.invalidOhip') : ''}
            />
            <TextField
              placeholder={t('Case.patient.versionCode') as string | undefined}
              name="ohipVersion"
              value={caseDetails?.patient?.ohipVersion ?? ''}
              variant="standard"
              onChange={handleFieldChange}
            />
          </Stack>
          <FormGroup onChange={handleNoOhip}>
            <FormControlLabel
              checked={caseDetails?.patient?.noOhip}
              name="noOhip"
              control={<Checkbox />}
              label={t('Case.patient.ohipNotAvailable')}
              sx={{ color: 'base.grey4' }}
            />
          </FormGroup>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <FormGroup onChange={handleConsentChanged}>
          <FormControlLabel
            control={<Checkbox />}
            label={t('Case.patient.consentDirectives')}
            sx={{ color: 'base.grey4' }}
          />
        </FormGroup>
      </Grid>
      {isConsentShown && (
        <Grid item xs={12}>
          <TextField
            name="patientConsent"
            value={patientConsentDetails}
            onChange={handlePatientConsentFieldChanged}
            onBlur={handlePatientConsentFieldBlur}
            fullWidth
            multiline
            rows={4}
            placeholder="Please enter any consent limitations. Examples could include:
                          1.Do not forward this consult to *name of person|Organization*           
                          2.Only send referral to *name of person | Organization* "
          />
        </Grid>
      )}
    </Grid>
  );
});

export default EconsultCasePatientForm;
